/* General styles (checkout and account) */

.messages,
.item-count,
#mobile-breadcrumb {
  display: none;
}

.messages {
  background-color: $color-white;
  color: $color-red;
  border-bottom: 1px solid $color-white;
}
.page-header {
  width: 21.75rem; /*300px*/
  margin: 0 auto;
  @include breakpoint($small-medium-only) {
    width: 14rem;
  }
}
.left {
  float: left;
}
.right {
  float: right;
}
header.lines {
  width: 100%;
  border-top: 1px solid $color-light-gray;
  border-bottom: 1px solid $color-light-gray;
  padding: 0.9375rem; /*15px*/
  h2 {
    margin: 0;
  }
}
.checkout-panel-main,
.sign-in-page,
.account-section,
.samples-page {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto 2.5rem;
  @include breakpoint($small-medium-only) {
    margin-top: 33px;
  }
  .panel-group,
  .content {
    padding: 0.625rem 0.625rem 1.25rem; /*10px 10px 20px*/
  }
  .column {
    &.column--left {
      width: 63%;
      @include breakpoint($small-down) {
        width: 100%;
      }
    }
    &.column--right {
      width: 37%;
      @include breakpoint($small-down) {
        width: 100%;
      }
    }
  }
  .button {
    width: 100%;
    @include breakpoint($large-up) {
      max-width: 16rem;
    }
  }
  .viewcart-buttons-panel {
    .continue-buttons {
      a {
        display: inline-block;
      }
      @include breakpoint($small-medium-only) {
        .btn-checkout,
        .go-shopping {
          max-width: 100%;
        }
      }
    }
  }
  @include breakpoint($small-medium-only) {
    .continue-buttons,
    #continue-btn {
      text-align: center;
    }
    .panel {
      clear: both;
      &.base {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        .heading--5 {
          border-bottom: none;
        }
        .content {
          padding: 0.625rem 0 1.25rem;
        }
      }
    }
  }
}
.sign-in-page .sign-in__section .page-header {
  width: auto;
  margin: 0px;
}
/*Tooltip*/
.tooltip {
  position: relative;
  display: inline-block;
  width: 100%;
  a {
    color: #fff;
    text-decoration: underline;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltiptext-under {
    top: 125%;
  }
  .tooltiptext-over {
    bottom: 125%;
  }
  .tooltiptext-under::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
  }
  .tooltiptext-over::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  &:hover .tooltiptext-under,
  &:hover .tooltiptext-over {
    visibility: visible;
    opacity: 1;
  }
  .tooltip-bis::before {
    left: 50%;
  }
}
.tooltip--waitlist {
  margin-top: 10px;
}

#shipping-panel {
  .tooltip {
    margin: 10px 0px 20px 0px;
  }
}
