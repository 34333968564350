///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules. Don't use a mixin where you could use
/// @extend instead.

@mixin copy--body {
  font-size: 14px;
  @include breakpoint($medium-up) {
    font-size: 17px;
  }
}

// Inter
@mixin font-inter-black {
  font-family: $font-inter-black;
}

@mixin font-inter-bold {
  font-family: $font-inter-bold;
}

@mixin font-inter-light {
  font-family: $font-inter-light;
}

@mixin font-inter-extra-light {
  font-family: $font-inter-extra-light;
}

@mixin font-inter-extra-bold {
  font-family: $font-inter-extra-bold;
}

@mixin font-inter-medium {
  font-family: $font-inter-medium;
}

@mixin font-inter-regular {
  font-family: $font-inter-regular;
}

@mixin font-inter-semi-bold {
  font-family: $font-inter-semi-bold;
}

@mixin font-inter-thin {
  font-family: $font-inter-thin;
}

// Account
// Common styles
@mixin account_page_header {
  line-height: 1em;
  font-size: 1.5em;
  margin: 10px 0;
  padding-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: $border;
  @include breakpoint($medium-up) {
    line-height: 2em;
    font-size: 2em;
    position: relative;
    text-align: #{$ldirection};
    padding-bottom: 0;
    margin: 10px 0 20px 0;
  }
}

@mixin account_page_sub_header {
  @include heading--5;
  margin-bottom: 20px;
}

@mixin account_page_header_border {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: $border;
}

@mixin account-page-grid {
  @include font-inter-regular;
  @include swap-direction(margin, 0 4% 80px 0);
  text-align: center;
  float: #{$ldirection};
  position: relative;
  width: 48%;
  &:nth-child(2n) {
    margin-#{$rdirection}: 0;
  }
  @include breakpoint($landscape-up) {
    margin-#{$rdirection}: 1.2%;
    width: 32.13%;
    &:nth-child(2n) {
      margin-#{$rdirection}: 1.2%;
    }
  }
}

@mixin font--text {
  font-family: $font--text;
  font-weight: normal;
  font-size: $base-mobile-font-size;
  line-height: $base-mobile-line-height;
  @include breakpoint($medium-up) {
    font-size: $base-font-size;
    line-height: $base-line-height;
  }
}

//original selectors
//#past-purchases-panel .dot-pager, #favorites-panel .dot-pager, #samples-panel .dot-pager
@mixin dot_pager {
  font-size: 3em;
  text-align: center;
  cursor: pointer;
  line-height: 1em;
}

// New 2024 mixins
@mixin s1 {
  @include font-inter-bold;
  @include font-smoothing;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  @include breakpoint($medium-up) {
    font-size: 32px;
  }
}

// TODO: Old mixins - Remove unnecessary after updates are complete
// New styleguide has same style for heading 1 and 2
@mixin h1 {
  font-size: 25px;
  line-height: 1.2;
  letter-spacing: -0.03em;
}

@mixin h2 {
  @include h1;
}

@mixin h3 {
  font-size: mobilePx(28px);
  line-height: 1.2;
  letter-spacing: 1px;
  @include breakpoint($medium-up) {
    font-size: 28px;
  }
}

@mixin h4 {
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.02em;
  @include breakpoint($medium-up) {
    font-size: 22px;
  }
}

@mixin h5 {
  font-size: 13px;
  line-height: 1.6;
  @include breakpoint($medium-up) {
    font-size: 17px;
  }
}

@mixin h6 {
  font-size: 14px;
  line-height: 1.6;
  @include breakpoint($medium-up) {
    font-size: 22px;
  }
}

@mixin h7 {
  font-size: 11px;
  line-height: 1.13;
  @include breakpoint($medium-up) {
    line-height: 1.2;
    font-size: 50px;
    letter-spacing: 0;
  }
}

@mixin h8 {
  font-size: mobilePx(80px);
  line-height: 1.2;
  letter-spacing: 2px;
  @include breakpoint($medium-up) {
    font-size: 80px;
  }
}

@mixin heading--7 {
  @include font-inter-medium;
  @include h7;
}

@mixin copy--intro {
  @include heading--base;
  @include font-inter-light;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 1.4;
}

// Standard buttons
@mixin button {
  @include font-smoothing;
  @include font-inter-medium;
  background: $color-orange;
  color: $color-white;
  border: 1px solid $color-orange;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  border-radius: 0;
  padding: 13px 60px 9px 60px;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: background $transition-duration $transition-effect,
    color $transition-duration $transition-effect,
    border-color $transition-duration $transition-effect;
  &:hover {
    background: $color-orange;
    text-decoration: none;
    color: $color-white;
  }
  @include breakpoint($small-medium-only) {
    width: 100%;
    padding-#{$ldirection}: 0;
    padding-#{$rdirection}: 0;
  }
  @include breakpoint($medium-up) {
    padding: 13px 30px 9px 30px;
    width: 100%;
    max-width: 256px;
  }
}

@mixin button--dark {
  color: $color-white;
  background: $color-black;
  border-color: $color-black;
  &:hover {
    color: $color-white;
    background: $color-black;
    border-color: $color-black;
  }
}

@mixin button-secondary {
  @include button;
  background: $color-white;
  color: $color-black;
  border-color: $color-black;
  &:hover {
    background: $color-white;
    color: $color-black;
    border-color: $color-black;
  }
}

@mixin heading--base {
  > p {
    font-size: inherit;
  }
}

@mixin heading--1__underline {
  @include heading--base;
  @include heading--1;
}

// Heading Styles
@mixin heading--1 {
  @include heading--base;
  @include font-smoothing;
  @include font-inter-medium;
  @include h1;
  text-transform: uppercase;
}

@mixin heading--2 {
  @include heading--base;
  @include font-smoothing;
  @include font-inter-medium;
  @include h2;
}

@mixin heading--3 {
  @include heading--base;
  @include font-smoothing;
  @include font-inter-medium;
  @include h3;
  margin-bottom: 12px;
}

@mixin heading--4 {
  @include heading--base;
  @include font-smoothing;
  @include h4;
  font-weight: normal;
}

@mixin heading--5 {
  @include heading--base;
  @include font-smoothing;
  @include font-inter-medium;
  @include h5;
  @include tracking(20);
  text-transform: capitalize;
}

@mixin heading--6 {
  @include heading--base;
  @include font-smoothing;
  font-weight: normal;
}

@mixin heading--8 {
  @include font-inter-medium;
  @include h8;
  @include tracking(20);
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Style input placeholders
///
@mixin input-styled-check($icon, $suffix: 'checked', $padding: 10px) {
  // hide the actual element
  position: absolute;
  #{$ldirection}: -9999px;
  ~ label,
  ~ .label {
    font-weight: normal;
    &::before {
      margin-#{$rdirection}: $padding;
    }
  }
}

///
/// Circles
///
/// Usage:
/// @include circle(200px, #999);
///
@mixin circle($default-circle-width: 41px, $color: #ccc) {
  width: $default-circle-width;
  height: $default-circle-width;
  background: $color;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

///
/// Triangles - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
/// @include bordered-triangle(size, color, direction, border-color, [border-width], [cut])
///
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
///
/// border-color: The color of the border around the triangle
/// border-width: The width of the border around the triangle
/// cut: Removes the border along the hypotenuse
///
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;

  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

@mixin bordered-triangle($size, $color, $direction, $border-color, $border-width: 1px, $cut: true) {
  $back-size: $size + ($border-width * 2);
  position: relative;

  min-width: $size;
  min-height: $size;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
  }

  &:after {
    @include triangle($size, $color, $direction);
  }

  &:before {
    @include triangle($back-size, $border-color, $direction);
    $mid-border-width: ($border-width / 2);
    $border-width-and-mid: $border-width + $mid-border-width;

    @if ($cut == true) {
      $mid-border-width: 0;
      $border-width-and-mid: $border-width * 2;
    }

    @if ($direction == up) or ($direction == down) {
      margin-#{$ldirection}: -$border-width;

      @if ($direction == down) {
        margin-top: -$mid-border-width;
      } @else {
        margin-top: -$border-width-and-mid;
      }
    } @else if ($direction == right) or ($direction == left) {
      margin-top: -$border-width;

      @if ($direction == right) {
        margin-#{$ldirection}: -$mid-border-width;
      } @else {
        margin-#{$ldirection}: -$border-width-and-mid;
      }
    }
  }
}

///
/// Vertical centerer
/// Source: http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
/// Note: This doesn't work in old browsers (ie. IE), you have to use javascript
/// for them if it's important.
///
@mixin vertical-center {
  @include transform(translateY(-50%));
  position: relative;
  top: 50%;
}

@mixin destroy-vertical-center {
  @include transform(none);
  position: static;
  top: auto;
}

///
/// Vertical & Horizontal centerer
///
@mixin vertical-horizontal-center {
  position: relative;
  top: 50%;
  #{$ldirection}: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

// responsive container
@mixin responsive-container {
  margin: 0 auto;
  max-width: $max-width;
}

@mixin tracking($target) {
  letter-spacing: tracking($target);
}

// Product
@mixin product {
  border-spacing: 0.4em;
  height: 12em;
  border-bottom: 1px dotted $color-gray;
  display: block;
  width: 100%;
}

@mixin product-name {
  @include font-inter-medium;
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 1.2;
  border: 0;
  text-decoration: none;
  word-break: break-word;
  text-transform: uppercase;
}

@mixin product-subline {
  @include font-inter-regular;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.6;
  text-transform: none;
}

@mixin product-flag {
  left: 50%;
  max-width: 256px;
  padding: 10px 15px;
  position: absolute;
  text-align: left;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  @include breakpoint($medium-up) {
    max-width: 420px;
  }
}

// Accordian
@mixin accordion-btn {
  @include font-inter-medium;
  &:not(.no-flagships) {
    .accordion_btn__arrow-up {
      display: none;
    }
    .accordion_btn__arrow-down {
      display: inline-block;
    }
  }
  border-top: 1px solid $color-lighter-gray;
  background-color: $color-white;
  color: $color-black;
  cursor: pointer;
  width: 100%;
  text-align: #{$ldirection};
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 20px;
  padding: 11px 0 8px 0;
  @include breakpoint($medium-up) {
    font-size: 15px;
    line-height: 22px;
    padding: 16px 0 11px 0;
    &:hover {
      color: $color-orange;
      fill: $color-orange;
    }
  }
  &:after {
    float: #{$rdirection};
    position: relative;
    top: 2px;
    margin-#{$rdirection}: 10px;
    @include breakpoint($medium-up) {
      top: 4px;
    }
  }
  &.active {
    &:not(.no-flagships) {
      .accordion_btn__arrow-up {
        display: inline-block;
      }
      .accordion_btn__arrow-down {
        display: none;
      }
    }
  }
  .accordion_btn__arrow-up,
  .accordion_btn__arrow-down {
    display: none;
    float: #{$rdirection};
    position: relative;
    top: 2px;
    margin-#{$rdirection}: 10px;
    height: 12px;
    width: 12px;
  }
}

// Miscellaneous
@mixin loyalty-clear {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin loyalty-panel {
  @include loyalty-clear;

  display: block;
  padding: 10px;
}

@mixin loyalty-panel-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 0;
  margin-bottom: 10px;

  @include breakpoint($medium-up) {
    border: 1px solid #eee;
    border-width: 1px 0;
  }
}

@mixin loyalty-panel-title-remove {
  text-align: initial;
  text-transform: none;
  font-weight: normal;
  padding: 0;
  margin-bottom: 0;

  @include breakpoint($medium-up) {
    border: 0;
  }
}

// Link
@mixin link {
  @include tracking(20);
  @include font-inter-regular;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  color: $color-black;
  text-decoration: underline;
  text-transform: uppercase;
  padding-bottom: 0;
  transition: color $transition-duration $transition-effect,
    border-color $transition-duration $transition-effect;
  cursor: pointer;
  &:hover {
    color: $color-link-hover;
    border-color: $color-link-hover;
  }
}

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}


// Font helper for Inter font type 

@mixin font-inter($weight:'regular', $size:'14', $badge: false) {
  @if $weight == 'medium' {
      font-family: $font-inter-medium;
  } @else if $weight == 'regular' {
      font-family: $font-inter-regular;
  } @else if $weight == 'semi-bold' {
      font-family: $font-inter-semi-bold;
  }

  font-size: #{$size}px;
  letter-spacing: -0.02em;

  @if $size == 20 {
      line-height: if($weight == 'medium', 24.2px, 28px);
  } @else if $size == 18 {
      line-height: if($weight == 'medium', 21.78px, 19.36px);
  } @else if $size == 16 {
      line-height: if($weight == 'semi-bold', 19.36px, 24px);
  } @else if $size == 14 {
      line-height: if($weight == 'semi-bold', 20px, 20px);
  } @else if $size == 12 {
      line-height: if($weight == 'semi-bold', 16px, 16px);
  } @else if $size == 10 {
      line-height: if($badge, 14px, null);
  } 
}

@mixin font-body-inter-14 {
  @include font-inter;
}