///
/// @file components/prouducts/_spp.scss
///
/// \brief Styles for multi-product pages
///
///

.spp__container {
  padding: 0;
  .spp-cross-sells {
    margin: 0 1em;
    border-top: 1px solid $color-gray;
    @include breakpoint($medium-up) {
      margin: 0 auto;
      max-width: 1280px;
      padding: 0 50px;
    }
    .spp-cross-sells {
      &__header {
        margin: 1em 0 0 0;
        text-align: center;
        @include breakpoint($medium-up) {
          text-align: #{$ldirection};
        }
      }
      &__subheader {
        font-size: 18px;
        text-align: center;
        font-style: italic;
        @include breakpoint($medium-up) {
          text-align: #{$ldirection};
        }
      }
      .product-grid {
        text-align: #{$ldirection};
      }
    }
  }
}

// You May Also Like - Classification
.ymal-class {
  @include font-inter-medium;
  @include tracking(20);
  font-size: 12px;
  line-height: 15px;
  margin-top: 4px;
}

.product-full__recommended {
  @include breakpoint($medium-up) {
    max-width: $max-width;
    padding: 0 50px;
    margin: 0 auto;
  }
  .basic-divider {
    width: 100%;
  }
  .content-block-large__content-text {
    @include breakpoint($medium-up) {
      width: 100%;
      padding: 30px 0 4px 0;
    }
  }
}

.page-product {
  @include breakpoint($small-only) {
    .mpp-container {
      padding-top: 0;
    }
    .product-full__recommended .content-block-large__content-text {
      padding-bottom: 0;
    }
  }
}

.selectBox--yes-even-for-mobile-selectBox-dropdown-menu {
  &.selectBox-options {
    li {
      a {
        white-space: normal;
      }
    }
  }
}
