#loyalty__panel__invited {
  @include loyalty-panel;

  &.is-member {
    display: none;
  }

  @include breakpoint($medium-up) {
    float: left;
    width: 50%;
  }

  .loyalty__panel__invited__title {
    @include loyalty-panel-title;
  }
}
