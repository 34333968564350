/** Checkout Styles **/

body.checkout.checkout--index {
  .site-header__section--nav {
    @include breakpoint($large-up) {
      display: none;
    }
  }
}

section.panel {
  &.loading {
    & > * {
      opacity: 0.25;
    }

    * {
      cursor: not-allowed;
    }

    position: relative;

    &:before {
      position: absolute;
      content: 'Loading...';
      display: block;
      height: 0;
      top: 50%;
      left: 50%;
    }
  }

  .form-item__group {
    padding: 2px 0 2em;
    overflow: hidden;
  }

  header {
    a {
      text-decoration: none;

      &.link {
        text-decoration: underline;
      }
    }
  }
}

.checkout-panel-main {
  // redo text sizing for checkout
  .heading--1 {
    font-size: 20px;
    line-height: 25px;
    @include breakpoint($large-up) {
      font-size: 36px;
      line-height: 46px;
    }
  }

  .heading--2 {
    font-size: 22px;
    line-height: 27px;
    @include breakpoint($large-up) {
      font-size: 40px;
      line-height: 46px;
    }
  }

  .heading--3 {
    font-size: 14px;
    line-height: 15.5px;
    @include breakpoint($large-up) {
      font-size: 32px;
      line-height: 30px;
    }
  }

  .heading--4 {
    font-size: 13px;
    line-height: 15px;
    @include breakpoint($large-up) {
      font-size: 24px;
      line-height: 26px;
    }
  }

  .heading--5 {
    font-size: 12px;
    line-height: 15px;
    @include breakpoint($large-up) {
      font-size: 22px;
      line-height: 24px;
    }
  }

  .heading--6 {
    font-size: 12px;
    line-height: 15px;
    @include breakpoint($large-up) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  .heading--7 {
    font-size: 11px;
    line-height: 15px;
    @include breakpoint($large-up) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  .heading--8 {
    font-size: 12px;
    line-height: 15px;
    @include breakpoint($large-up) {
      font-size: 15px;
      line-height: 16px;
    }
  }

  fieldset {
    @include breakpoint($small-down) {
      max-width: none;
    }
  }

  padding-top: 2em;

  .heading--5-cart-header,
  .cart-item--column,
  .product-thumb,
  .product-info {
    float: left;
  }
  .checkout__continue--shopping {
    margin-left: 1em;
  }
  .cart-items {
    width: 100%;
    .total {
      text-align: right;
    }
    @include breakpoint($landscape-up) {
      .total {
        float: right;
      }
    }
  }
  .subtotal,
  .shipping,
  .total {
    display: inline-block;
    text-align: right;
  }
  .discount {
    display: inline-block;
    text-align: right;
  }

  .divide {
    border-top: $border;
    padding: 1.875rem 0; /*30px 0*/
  }

  .order-summary-panel {
    &--content {
      max-width: 23.75rem;
    }
  }
  .select-address {
    .selectBox {
      width: 100%;
    }
  }
  .column--left {
    margin-top: 1.125rem; /*18px*/
    @include breakpoint($large-up) {
      max-width: 51.25rem; /*820px*/
    }
    @include breakpoint($small-medium-only) {
      margin-top: 0;
    }
    .viewcart-buttons-panel .continue-buttons .paypal-checkout,
    .viewcart-buttons-panel .continue-buttons .paypal-checkout img {
      padding-top: 0.2%;
      @include breakpoint($small-medium-only) {
        margin: 0;
      }
    }
    .adyen-viewcart {
      .paypal-checkout,
      .paypal-smart-button {
        width: 100%;
        @include breakpoint($medium-up) {
          width: auto;
        }
        .zoid-outlet {
          min-width: 100% !important;
          @include breakpoint($medium-up) {
            min-width: 250px !important;
          }
        }
      }
    }
    section {
      margin-bottom: 1.875rem; /*30px*/

      @include breakpoint($small-medium-only) {
        margin-bottom: 0;
      }

      &.collapsed {
        margin-bottom: -1px;

        h2 {
          color: $color-light-gray;
        }

        @include breakpoint($small-medium-only) {
          display: none;
        }
      }
      .messages {
        display: block;
        .single-message {
          .close_link {
            display: none;
          }
        }

        &:empty {
          display: none;
        }
      }
    }
    .order-summary-panel--content {
      .value {
        float: right;
      }
    }
    .qty-text {
      display: none;
    }
    .cart-items {
      .product {
        width: 58%;
        @include breakpoint($landscape-up) {
          text-align: center;
        }
      }
      .price {
        width: 19%;
        @include breakpoint($tablet-only) {
          margin-left: 39%;
        }
        @media (width: 1024px) {
          margin-left: 0px;
        }
      }
      .qty {
        width: 7%;
        text-align: center;
      }
      .total {
        width: 16%;
      }
      @include breakpoint($medium-down) {
        .heading--1,
        .heading--2,
        .heading--3,
        .heading--4,
        .heading--5,
        .heading--6,
        .heading--7,
        .heading--8 {
          font-size: 90%;
        }

        .heading--8 {
          &.mobile_hidden {
            display: none;
          }
          @include breakpoint($small-down) {
            &.total {
              text-align: left;
              margin-bottom: 10px;
              margin-top: 10px;
              padding-left: 39%;
              width: 100%;
            }
          }
          @include breakpoint($medium-portrait-only) {
            &.total {
              margin-left: 35%;
            }
          }
        }

        .cart-header {
          display: none;
        }
        .price,
        .qty {
          float: none;
        }
        .price.pc_hidden {
          font-size: 0.59rem;
          display: inline-block;
          padding-left: 0.425rem;
          width: auto;
          @include breakpoint($tablet-only) {
            display: table-row;
          }
        }
        @include breakpoint($medium-portrait-only) {
          .sample {
            .price {
              display: block;
              margin: 0 0 40px 0;
              padding-left: 0px;
              &.mobile_hidden {
                display: none;
              }
            }
          }
        }
        .remove.pc_hidden {
          float: left;
        }
        .product {
          width: 100%;
        }
      }
      .cart-item--column.product {
        padding: 0 5%; /*0 20px*/
        .product-thumb {
          padding-right: 5%; /*20px*/
          width: 38%;
          img {
            width: 100%;
          }
          @include breakpoint($small-medium-only) {
            a {
              display: block;
            }
          }
        }
        .product-info {
          width: 62%;
          @include breakpoint($small-medium-only) {
            .info,
            #cart {
              display: inline-block;
            }
            .info {
              max-width: 12rem;
            }
          }
          .product_name a {
            text-decoration: none;
          }
        }
      }
      .kit-item {
        .product-thumb {
          min-height: 150px;
        }
        .cart-item-kit {
          &__subitem-container {
            display: inline-block;
            width: 70%;
            float: right;
            margin-top: -8em;
            @include breakpoint($small-medium-only) {
              width: 61%;
              margin-top: -13em;
              font-size: 11px;
            }
          }
          &__subitem {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            &--name {
              width: 75%;
              @include breakpoint($small-medium-only) {
                width: 80%;
              }
            }
          }
        }
      }
    }
  }
  .column--right {
    @include breakpoint($large-up) {
      max-width: 25rem; /*400px*/
    }
    section.panel {
      margin-bottom: 1.875rem; /*30px*/
      width: 100%;
      h2 {
        padding: 1rem 1.25rem 0.625rem; /*16px 20px 10px*/
        border-bottom: $border-grey;
      }
      @include breakpoint($large-up) {
        .content {
          padding: 0 1.25rem 0.625rem; /*0 20px 10px*/
        }
      }
      .form-submit {
        position: relative;
        width: 6.25rem; /*100px*/
      }
      .edit-count {
        position: absolute;
        top: 0.9375rem;
        right: 1.25rem;

        .item-number,
        .edit {
          float: right;
        }

        .item-number {
          @include breakpoint($small-medium-only) {
            display: none;
          }
        }

        .edit {
          padding-left: 1em;
        }
      }
      .order-summary-panel {
        &--content {
          text-align: right;
          width: 100%;
          @include breakpoint($small-medium-only) {
            padding: 0;
          }
          &-div {
            padding: 0.625rem 0; /*10px 0*/
            &total {
              padding-top: 1.5625rem; /*25px*/
            }
          }
        }
      }
    }
    .panel {
      border: $border-grey;
      overflow: hidden;
      @include breakpoint($small-medium-only) {
        border: none;
      }
    }
    .cart-header,
    .cart-item--column.price {
      display: none;
    }
    .divide {
      header {
        display: none;
      }
    }
    .item-count {
      display: inline-block;
    }
    .heading--6-input {
      padding-top: 0.625rem; /*10px*/
    }
    #viewcart-panel {
      border-bottom: 0;
      margin-bottom: 0;

      .cart-item.products {
        &.kit-item {
          .product-thumb {
            min-height: 120px;
          }
        }
        .cart-item-kit {
          &__subitem-container {
            display: inline-block;
            width: 70%;
            float: right;
            margin-top: -8em;
            font-size: 12px;
          }
          &__subitem {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            &--name {
              width: 70%;
            }
          }
        }
      }

      @include breakpoint($small-medium-only) {
        border: 0;
        border-bottom: 0;
        margin: 0 1em;
        width: auto;

        h2 {
          border: 1px solid $color-light-gray;
          border-width: 1px 0 2px;
        }
      }
      .edit-count {
        font-family: $font-inter-light;
      }
    }
    #order-summary-panel {
      border-top: 0;

      @include breakpoint($small-medium-only) {
        max-width: none;
        border: 0;
        padding: 0;
        margin: 0 1em;
        width: auto;
      }
      .order-summary-panel--content {
        .subtotal,
        .shipping,
        .total {
          &.label {
            width: 60%;
            margin-right: 0;
          }
        }
        .discount {
          &.label {
            width: 60%;
            margin-right: 0;
          }
        }
      }
    }
    .cart-item {
      margin: 0.625rem 1.25rem 0.625rem 0.625rem; /*10px 20px 10px 10px*/
    }
    .selectBox-dropdown {
      font-size: 0.75rem;
    }
    .cart-items {
      position: relative;
      .product {
        width: 70%;
      }
      .qty {
        width: 11%;
        line-height: 1rem;
      }
      .total {
        width: 19%;
        float: right;
      }
    }
    .product-thumb {
      width: 40%;
      img {
        width: 100%;
      }
      @include breakpoint($small-medium-only) {
        a {
          display: block;
        }
      }
    }
    .product-info {
      width: 57%;
      margin-left: 3%;
      @include breakpoint($small-medium-only) {
        .info,
        #cart {
          display: inline-block;
        }
        .info {
          max-width: 10rem;
        }
      }
    }
    .button {
      &:hover {
        color: $color-white;
        background-color: $color-orange;
        border: 1px solid $color-orange;
      }
    }
    .content {
      .label {
        clear: both;
        width: 15.625rem; /*250px*/
      }
      .value {
        width: 5.625rem; /*90px*/
      }
      .subtotal,
      .shipping,
      .total {
        display: inline-block;
        text-align: right;
        &.label {
          width: 16.625rem;
        }
      }
    }
    .subtotal,
    .shipping,
    .total {
      &.label {
        width: 16.625rem;
      }
    }
    .ship-method-group-label {
      a.link.more-info {
        border: none;
      }
      label {
        display: block;
      }
    }
  }
  .cart-header {
    width: 100%;
    height: 2.125rem; /*34px*/
    border-bottom: $border;
  }
  .cart-item {
    clear: both;
    padding: 2.5rem 0;
    @include breakpoint($small-medium-only) {
      padding: 2rem 0;
    }
  }
  .btn-checkout {
    &.continue-checkout {
      @include breakpoint($tablet-only) {
        margin: 0 0 5px 0;
        padding-bottom: 0;
        font-size: 16px;
        float: right;
        width: 48%;
      }
    }
    &.paypal-checkout {
      @include breakpoint($tablet-only) {
        float: left;
        width: 48%;
        padding-top: 0.7%;
      }
    }
    margin-left: 0.625rem; /*10px*/
    overflow: hidden;
  }
  #checkout_shipping_panel,
  #review-panel {
    .select-address {
      width: 100%;
      .form-item {
        @include breakpoint($small-medium-only) {
          width: 80%;
          display: block;
          margin: 0.5rem auto 0.5rem;
          #continue-btn {
            margin: 0 auto;
          }
        }

        @include breakpoint($medium-down) {
          width: 100%;
        }
      }
    }
    .ship_group_1 {
      margin: 1em 0;
    }
    @include breakpoint($large-up) {
      .form-item.card-message {
        width: 100%;
        padding-right: 1.5em; /*24px*/
      }
    }
  }
  #shipping-panel {
    fieldset.address-form {
      padding-top: 0.5em;
    }
    .form-item {
      &.select-menu,
      &.default-shipping {
        width: 100%;
      }
      &.default-shipping {
        display: block;
        input.hide_checkbox + label {
          display: none;
        }
      }
      &.name.first {
        clear: left;
      }
    }
    #qas,
    #manual-address-fields {
      clear: both;
    }
    .gift-options {
      margin: 0 0 2em;
      .sub-section {
        section {
          margin-top: 1em;
        }
      }
      .wrapItem {
        float: left;
        width: 24%;
      }
    }
    fieldset div.checkbox {
      padding-top: 2px;
    }

    &.finished {
      h3.heading--8 {
        font-size: 90%;
      }
    }
    &.substate-manual {
      #manual-address-fields {
        display: block !important;
        visibility: visible !important;
      }
    }
  }
  #shipping-panel,
  #registration-panel {
    header {
      position: relative;
      .edit.link {
        position: absolute;
        right: 1.25rem; /*20px*/
        top: 0.75rem; /*12px*/
        font-size: 90%;
      }
    }
  }
  #checkout_payment {
    .form-item {
      display: block;
      @include breakpoint($tablet-only) {
        width: 54%;
      }
    }
    .card_type_field {
      width: 100%;
    }
  }
  #checkout_billing_panel {
    label {
      line-height: 1;

      &:before {
        vertical-align: top;
      }
    }
    .label-content {
      display: none;
    }
    .payment_type {
      display: inline-block;
      margin-right: 1.25rem; /*20px*/
    }
  }
  .sub_line {
    padding-top: 0.1875rem;
  }
  #order-summary-panel,
  .viewcart-buttons-panel,
  #shipmethod-panel,
  #recommended-products-panel {
    max-width: 51.25rem;
  }
  .viewcart-buttons-panel {
    @include breakpoint($large-up) {
      text-align: right;

      .continue-buttons {
        margin-left: auto;

        .paypal-checkout {
          float: left;
          margin-left: 32%;
        }
      }
    }
  }
  #order-summary-panel {
    .subtotal.label {
      text-transform: none;
    }

    .subtotal,
    .shipping,
    .total {
      padding: 0.5rem 0;
      @include breakpoint($small-medium-only) {
        width: 30%;
        float: left;
      }

      &.label {
        width: 70%;

        @include breakpoint($small-medium-only) {
          width: 70%;
        }
      }

      &.total {
        padding: 2rem 0 1rem;
      }
    }
    .discount {
      &.label {
        width: 70%;
      }
    }
    @include breakpoint($small-medium-only) {
      header {
        display: none;
      }
    }

    .order-summary-panel--content {
      @include breakpoint($large-up) {
        max-width: none;
        width: 100%;

        .subtotal,
        .shipping,
        .total {
          &.label {
            width: 85%;
            margin-right: 5%;
            text-transform: uppercase;
          }
        }
        .discount {
          &.label {
            width: 85%;
            margin-right: 5%;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  #links-panel {
    .links_list li {
      padding: 0 0 0.5em 0;

      .link {
        font-size: 0.875em;
      }
    }
    @include breakpoint($small-medium-only) {
      .content {
        font-family: $font-inter-light;
        font-size: 0.675rem;
        a {
          display: inline-block;
          text-decoration: underline;
          text-transform: uppercase;
          color: $color-dark-gray;
        }
        .links_desc a {
          display: inline;
        }
      }
    }
    @include breakpoint($small-medium-only) {
      margin: 1em 1em 2.5em;
      padding: 0;
      width: auto;

      h2 {
        padding: 0;
        border: 0;
      }
      .content {
        padding: 0;
      }
    }
  }
  #offer-code-panel {
    .offer-code__input-wrapper {
      float: left;
      width: 70%;

      input[type='text'] {
        width: 90%;
      }
    }
    @include breakpoint($small-medium-only) {
      border: none;
      border-bottom: 1px solid $color-dark-gray;
      h2 {
        border: none;
      }
      #offer_code {
        .form-submit {
          width: 30%;
        }
      }
    }
    @include breakpoint($small-medium-only) {
      margin: 1em 1em 2.5em;
      border: 0;
      width: auto;
      border-bottom: 1px solid $color-black;

      h2 {
        padding: 2em 0 0;
      }
      .content {
        padding: 0;
      }

      .offer-code__input-wrapper {
        width: 69%;
        display: inline-block;
      }

      .offer-code__sub-text {
        margin-top: 1em;
      }
    }
    input[type='submit'].button--dark {
      line-height: 1.5em;
      background-color: $color-black;
      border-color: $color-black;
      width: 30%;

      @include breakpoint($small-medium-only) {
        padding: 7px 10px 2px;
      }
    }
  }
  @include breakpoint($small-medium-only) {
    .js-login-link {
      display: block;
      padding-top: 20px;
      text-align: center;
    }
    .form-item.submit {
      width: 100%;
      .form-submit {
        max-width: 100%;
      }
    }
    fieldset .form-item {
      padding-right: 0;
    }
    #recommended-products-panel {
      display: none;
    }
  }
  #sign-in-paypal-panel {
    clear: both;
  }
  #shipmethod-panel {
    clear: both;
    #checkout_shipmethod {
      padding: 0;
      .ship-method-group {
        select {
          width: 96%;
        }
      }
    }
    .ship-method-collection-group {
      label {
        float: none;
      }
    }
    .selectBox-dropdown {
      margin-top: 1.25rem; /*20px*/
      width: 96%;
    }
    .ship-method-group-label {
      label {
        padding-right: 1.25rem; /*20px*/
        padding-bottom: 1rem;
      }
      > span:last-child label {
        padding-bottom: 0;
      }
    }
    .overlay-wrapper {
      display: inline-block;
    }
    .click-and-collect.more-info span {
      /*width: 4.375rem;*/
      display: inline-block;
      margin-left: 0.9375rem; /*15px*/
      text-decoration: underline;
    }
    .ship-method-group {
      margin-top: 0.9375rem; /*15px*/
    }

    @include breakpoint($small-medium-only) {
      margin: 1em;
      width: auto;
      padding: 0;
      h2 {
        border: 0;
        padding: 0;
      }
      .content {
        padding: 0;
      }
    }
  }
  .ship-method-group-label {
    a.link {
      border: none;
    }
    @include breakpoint($small-medium-only) {
      label {
        display: block;
      }
    }
  }
  #review-panel {
    clear: both;

    .heading--payment-type {
      overflow: hidden;
      padding-bottom: 1em;

      h3 {
        float: left;
      }
    }

    .form-item.card-cvn {
      width: 54%;
      float: left;
    }

    .form-container--credit-card {
      margin-top: 1.5em;
      @include breakpoint($large-up) {
        .form-item {
          float: none;
        }
        .card-cvn {
          width: 50%;
        }
        .form-item--cvn-details {
          clear: left;
          margin: 0;
          line-height: 1;
          height: auto;
          display: none;
        }
      }
      .card-expiry-date .checkedEmpty + a,
      .card-types .checkedEmpty + a {
        border-color: #ff0000;
        color: #ff0000;
      }
    }

    .content {
      padding: 2.5em 1em 1em;
    }
    .adyen-content {
      padding-top: 0;
    }

    .select-address {
      padding-top: 1em;
      .new-address {
        #manual-address-fields {
          @include breakpoint($tablet-only) {
            .city {
              padding-bottom: 0em;
            }
          }
        }
      }
    }

    .sub-section.address {
      margin-top: 1em;
    }

    .payment-address,
    .heading--payment--terms-and-conditions {
      padding-bottom: 1em;
    }

    // @include breakpoint($large-up) {
    .payment-type__label {
      width: 50%;
      display: block;
      float: left;
      margin-bottom: 1em;
    }
    // }

    .related-media {
      margin: 1em 0;
      display: block;
      @include breakpoint($medium-down) {
        &.pp img {
          width: 44px;
        }
        &.cc img {
          width: 16px;
        }
      }
    }

    .form-item--cvn-details {
      line-height: 4em;
      margin: 0 3%;
      float: left;
      height: 2.4em;
      display: none;
    }
  }
  .form-item.card-expiry-date {
    .selectBox-label {
      width: 8.125rem;
    }
    select,
    .selectBox-dropdown {
      max-width: 47.67%;
      width: 47.67%;
      margin-left: 1px;
      @include breakpoint($small-medium-only) {
        max-width: 47%;
        width: 47%;
      }
    }
  }
  .remove_link,
  .size {
    margin: 1.153846rem 0;
    @include breakpoint($small-medium-only) {
      margin-top: 0.75rem;
      display: inline-block;
    }
  }
  .remove_link {
    @include font-inter-regular;
  }
  .cart--item__size-label {
    display: block;
  }
  @include breakpoint($small-medium-only) {
    .size {
      font-size: 0.6rem;
    }
    .remove_link {
      position: absolute;
      left: 39%;
      bottom: 0;
      @include breakpoint($tablet-only) {
        bottom: 12%;
      }
    }
    #cart {
      a {
        text-decoration: underline;
      }
    }
  }
  @include breakpoint($tablet-only) {
    .qty {
      a,
      select {
        position: absolute;
        left: 75%;
      }
    }
  }
  @include breakpoint($tablet-only) {
    .sample {
      .qty {
        position: absolute;
        left: 75%;
        line-height: 20px;
      }
    }
  }
  @include breakpoint($medium-portrait-only) {
    bottom: auto;
  }
  @media (width: 1024px) {
    .qty {
      a,
      select {
        position: relative;
        left: auto;
        bottom: auto;
      }
    }
  }
  @include breakpoint($large-down) {
    .qty-text {
      display: none;
    }
  }
  #checkout_review {
    margin-top: 2rem;
    .form-item {
      width: 100%;
      &.error {
        color: $color-red;
        a {
          color: $color-red;
        }
      }
      input[type='checkbox'] ~ .label,
      input[type='checkbox'] ~ label {
        &::before {
          margin-right: 0;
        }
      }
    }
  }
  #recommended-products-panel {
    .formatted_price,
    .price,
    .quickshop {
      display: none;
    }
  }
  #need-help-panel {
    display: none;
  }
  #confirmation-page {
    text-align: center;
    #guarantee-panel,
    #need-help-panel,
    #links-panel {
      display: none;
    }
  }
  #shopping-bag-edit {
    text-decoration: underline;
  }
  .forgot-pw-note-wrap {
    margin-bottom: 1rem;
  }
  #checkout_samples {
    .continue-buttons {
      margin-top: 2rem;
    }
    .samples {
      .details {
        .skus {
          position: absolute;
          width: 44%;
          top: 55%;
          left: 29%;
        }
      }
    }
  }
  .sign-in-panel {
    overflow: hidden;
    .column {
      width: 50%;
      float: left;
      @include breakpoint($small-down) {
        width: 100%;
        float: none;
      }
      #checkout_signin_guest_user,
      #checkout_signin {
        @include breakpoint($tablet-only) {
          width: 93%;
        }
        input {
          &[data-test-id='form_checkout_signin_new_user_submit'] {
            @include button--dark;
          }
        }
      }

    }
    .form-item {
      float: none;
      width: 73%;
      @include breakpoint($small-medium-only) {
        width: 100%;
      }
    }
    fieldset {
      overflow: visible;
    }
    .form-submit {
      width: 100%;
    }
    .new-customer {
      margin-bottom: 1em;
    }
    @include breakpoint($small-medium-only) {
      margin: 1em;
      header.lines {
        padding: 0;
        border: 0;
      }
      #new-or-returning-radios {
        padding: 1.5em 0;
      }
    }
    @include breakpoint($small-down) {
      .panel-group {
        padding: 0;
        .column.content {
          padding: 0 0 2em;
        }
      }
    }
    &.finished {
      @include breakpoint($small-medium-only) {
        margin-left: 0;
        margin-right: 0;
        header.lines {
          padding: 0.9375rem;
          border-top: 1px solid $color-light-gray;
          border-bottom: 1px solid $color-light-gray;
        }
      }
    }
    .content {
      .heading--5 {
        padding-top: 15px;
        text-transform: none;
        @include breakpoint($medium-up) {
          padding-top: 34px;
        }
      }
    }
  }
  #confirmation-panel {
    text-align: center;
    .page-header {
      width: auto;
    }
    .actions .print-buttons {
      @include breakpoint($small-medium-only) {
        display: none;
      }
      .button {
        max-width: 22rem;
      }
    }
  }
  #registration-panel {
    .content {
      .registration-form-container {
        .form-item.checkbox {
          input[type='checkbox']:checked ~ .label:before,
          input[type='checkbox'] ~ .label:before {
            content: ' ' !important;
            background: url(/media/images/checkout/eye.png);
            width: 15px;
            height: 15px;
            background-repeat: no-repeat;
            background-position: 0 3px;
            margin-top: 3px;
          }
        }
        .show-password {
          padding-bottom: 1em;
        }
      }
    }
  }
  .promo-line-sep {
    border-top: $border-grey;
  }
  .promotions-container {
    .fs {
      &.email_promotions {
        margin: 0 auto;
      }
    }
    .pc_email_promo_container {
      padding-right: 10px;
    }
  }
  #email-signup-panel {
    text-align: center;
    padding: 5em 20em 0;

    @include breakpoint($small-medium-only) {
      padding: 2em 1em 0;
    }

    .email-promotions__actions {
      margin-top: 1em;

      .button--email-promotions {
        color: $color-white;
        background: $color-black;
        border-color: $color-black;
        max-width: 22rem;
      }
    }
  }
  .error_messages {
    padding-top: 20px;
  }
  @include breakpoint($small-medium-only) {
    .cart-item {
      position: relative;
    }
  }
  .signin-to-see-cart {
    padding: 2rem;
  }
}
.checkout-panel-main,
.sign-in-page,
.samples-page {
  .selectBox-dropdown {
    min-width: 6rem;
  }
}

#shipping-address-display,
#gift-options-display {
  width: 50%;
  float: left;
}
#delivery-address-h,
#gift-options-h,
#gift-message-h {
  padding-bottom: 1em;
}
#gift-message-h {
  padding-top: 1em;
}
.form-item__label--uppercase {
  text-transform: uppercase;
}

.shipping-address-part {
  width: 100%;
}

.gift-options-part {
  margin-bottom: 20px;
  min-width: 100%;
  .panel {
    &-left {
      float: #{$ldirection};
      margin-top: 1em;
      padding: 0;
      width: 40%;
      .gift-options-title {
        padding-top: 1em;
      }
    }
    &-right {
      float: #{$rdirection};
      padding: 0;
      width: 60%;
    }
  }
}

@include breakpoint($small-medium-only) {
  select {
    max-width: 100%;
  }
}

/* Samples Page Specific styles */

.samples-page {
  @include breakpoint($small-medium-only) {
    .checkout_samples {
      text-align: center;
    }
  }
  .product-img {
    img {
      width: auto;
    }
  }
  ul {
    &.product-list {
      .product {
        .details {
          .skus-container {
            position: relative;
            width: auto;
            left: 0;
            display: block;
            .sku-container {
              @include breakpoint($medium-up) {
                padding: 6px 5px 4px 5px;
              }
              a {
                &.sample-select-button {
                  @include breakpoint($medium-up) {
                    padding: initial;
                  }
                }
              }
            }
            @include breakpoint($tablet-only) {
              top: 20%;
              position: absolute;
              width: 63%;
              left: 18%;
              display: none;
            }
            @include breakpoint($landscape-up) {
              top: 25%;
            }
            @include breakpoint($large-up) {
              top: 30%;
              position: absolute;
              width: 45%;
              left: 29%;
              display: none;
            }
            &.active {
              @include breakpoint($medium-up) {
                display: block;
              }
            }
          }
        }
        &.selected {
          .skus-container {
            @include breakpoint($medium-up) {
              display: block;
            }
          }
        }
      }
    }
  }
}
.map-container {
  .map-info-window {
    .location {
      .detail_reveal {
        .button {
          @include breakpoint($tablet-only) {
            max-width: 70%;
          }
        }
      }
    }
  }
}

.adyen-checkout-enabled {
  .panel-text {
    background: #{$color-black};
    color: #{$color-white};
    padding: 10px;
  }
  .view-address {
    position: relative;
    .change-address {
      #{$rdirection}: 0;
      background: #{$color-white};
      padding-#{$ldirection}: 10px;
      position: absolute;
      top: 13px;
    }
  }
  .gift-card-entry {
    &-container {
      margin: 10px 0;
      .gift-card {
        &-number {
          float: #{$ldirection};
          width: 60%;
          @include breakpoint($medium-down) {
            width: 40%;
          }
        }
        &-pin {
          float: #{$ldirection};
          margin-#{$ldirection}: 5%;
          width: 35%;
          @include breakpoint($medium-down) {
            width: 25%;
          }
        }
      }
      .field {
        height: 40px;
      }
      .continue-btn {
        .form-submit {
          height: 40px;
          margin-top: 10px;
          width: 100%;
          @include breakpoint($medium-down) {
            margin-top: 0;
            width: 70%;
          }
        }
      }
    }
  }
  .checkout_payment_form {
    .payment-option {
      border-bottom: 1px solid #{$color-light-gray};
      margin-top: 0;
      min-height: 50px;
      padding: 20px 0;
      @include breakpoint($medium-up) {
        min-width: 430px;
      }
      label {
        height: auto;
      }
      .payment_options-terms,
      .adyen-payment {
        display: none;
        margin-#{$ldirection}: 0;
      }
      .paypal-terms {
        .local-submits {
          display: block;
          .paypal-express-btn {
            background: #{$color-black};
            color: #{$color-white};
            padding: 5px 40px;
          }
        }
      }
      .paywith-media {
        display: inline-block;
        margin: 0 5px;
        vertical-align: top;
        &.credit-card {
          .card-img {
            margin-#{$ldirection}: 4px;
            width: 32px;
            @include breakpoint($medium-up) {
              margin-#{$ldirection}: 6px;
            }
          }
        }
        &.paypal-card {
          .paypal-image {
            height: 20px;
            margin-#{$ldirection}: 2px;
            width: 59px;
            @include breakpoint($medium-up) {
              margin-#{$ldirection}: 0;
              width: 74px;
            }
          }
        }
      }
      .field {
        &:checked {
          & ~ label {
            .adyen-payment,
            .payment_options-terms {
              display: block;
            }
          }
        }
      }
      .pay-with-text {
        text-transform: uppercase;
      }
      .payment-terms {
        width: 80%;
        @include breakpoint($medium-up) {
          font-size: 15px;
          width: 54%;
        }
        .overlay-wrapper {
          display: inline-block;
        }
      }
    }
    .local-submits {
      display: none;
    }
  }
  .chckt-sdk {
    .chckt-pm {
      border: none;
      &__header {
        background: #{$color-white};
        padding-#{$ldirection}: 0;
      }
      &__image {
        #{$rdirection}: 2px;
        margin: 0;
      }
      &__name {
        color: #{$color-black};
      }
      &__details {
        background: #{$color-white};
        padding-#{$ldirection}: 0;
        .chckt-input-field--recurring {
          background: #{$color-white};
          border: 1px solid #{$color-light-gray};
          border-radius: 0;
          height: 40px;
          padding: 12px 10px;
          @include breakpoint($medium-up) {
            height: 45px;
            padding: 8px 10px;
          }
        }
      }
    }
    .chckt-form--max-width {
      color: #{$color-black};
      @include breakpoint($medium-up) {
        min-width: 400px;
      }
      .chckt-form-label__text {
        color: #{$color-black};
        &--dark {
          padding: 0 25px;
        }
      }
    }
    .chckt-form-label {
      &--exp-date {
        min-width: 132px;
        width: 50%;
        @include breakpoint($medium-up) {
          min-width: 182px;
        }
      }
      &--cvc {
        float: #{$ldirection};
        margin-#{$ldirection}: 3%;
        width: 47%;
        @include breakpoint($medium-up) {
          min-width: 178px;
        }
      }
      &__error-text {
        color: #{$color-red};
        text-transform: none;
      }
      .chckt-checkbox {
        #{$ldirection}: 0;
        top: 0;
        @include breakpoint($medium-up) {
          top: 4px;
        }
      }
    }
    .chckt-input-field {
      border-radius: 0;
      border-color: #{$color-light-gray};
      color: #{$color-black};
      height: 40px;
      @include breakpoint($medium-up) {
        height: 45px;
      }
      &--cvc {
        max-width: 220px;
        min-width: 130px;
        width: 100%;
        @include breakpoint($medium-up) {
          min-width: 176px;
        }
      }
      &--error {
        border: 2px solid #{$color-red};
        color: #{$color-red};
      }
    }
    .chckt-button-container {
      margin-bottom: 10px;
      width: 97%;
      @include breakpoint($medium-up) {
        min-width: 400px;
        width: 51%;
      }
      .chckt-more-pm-button {
        background: #{$color-white};
        border: none;
        padding-#{$ldirection}: 0;
        &__icon {
          display: none;
        }
        &__text {
          color: #{$color-black};
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 1px;
          margin-#{$ldirection}: 0;
          text-decoration: underline;
        }
      }
      .chckt-button {
        background: #{$color-red};
        margin-top: 0;
        padding: 10px;
        &.chckt-button {
          &--disabled {
            opacity: 0.5;
            @include breakpoint($medium-up) {
              min-width: 403px;
            }
          }
          &--submit {
            @include breakpoint($medium-up) {
              min-width: 403px;
            }
          }
        }
      }
    }
  }
  .checkout__content {
    .checkout__subtitle {
      font-size: 15px;
      font-weight: normal;
      letter-spacing: 0;
    }
  }
  .msg-panel {
    background: #{$color-black};
    color: #{$color-white};
    line-height: 2;
    margin-top: 15px;
    padding: 10px 10px 0;
    .panel-text {
      color: #{$color-white};
      line-height: 1.5;
    }
  }
}

.paypal-loading {
  background-size: 23px;
  background-position: top;
  display: inline-block;
  height: 30px;
  margin-#{$rdirection}: 10px;
  width: 30px;
}

.paypal-process {
  opacity: 0.2;
}

.paypal-overlay {
  #{$ldirection}: 0;
  background: #{$color-black};
  height: 100%;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.paypal-processing {
  #{$ldirection}: 0;
  #{$rdirection}: 0;
  margin: 0 auto;
  padding: 10px;
  position: fixed;
  text-align: center;
  top: 25%;
  width: 100%;
  z-index: 9999;
  .process-content {
    background: #{$color-white};
    max-width: 335px;
    margin: 0 auto;
    position: relative;
    padding: 30px 10px;
    z-index: 999;
  }
  .paypal-logo {
    padding-top: 20px;
    width: 90px;
  }
  .fd-logo {
    padding-bottom: 25px;
  }
}
