#loyalty__panel__sample-tout {
  @include loyalty-panel;

  display: block;
  clear: both;
  width: 100%;

  img {
    float: left;
    margin-right: 20px;
  }

  .loyalty__panel__sample-tout__description {
    text-align: center;
  }

  &.is-member {
    display: none;
  }
}
