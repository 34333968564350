.content-block-sitewide-banner--enabled {
  .content-block-sitewide-banner__content-wrapper {
    .site-email-signup__wrapper {
      .site-email-signup__form {
        .tooltip {
          padding-#{$ldirection}: 5px;
          padding-top: 3px;
          @include breakpoint($landscape-up) {
            padding-#{$ldirection}: 25px;
          }
        }
      }
    }
  }
}

.sticky-footer-chat {
  #{$ldirection}: auto;
  #{$rdirection}: 0;
  bottom: 0;
  display: block;
  position: fixed;
  z-index: 999;
  @include breakpoint($medium-up) {
    #{$rdirection}: 25px;
  }
  .sticky-footer-chat__img {
    #{$ldirection}: 0;
    height: auto;
    position: relative;
    width: 80%;
  }
  body.samples &,
  body.opc__shipping &,
  body.opc__payment &,
  body.opc__review & {
    display: none;
  }
  body.site-header-menu--opened &,
  body.search-active & {
    display: none;
    @include breakpoint($large-up) {
      display: block;
    }
  }
}
