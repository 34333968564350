.special-projects-pc {
  display: none;
  @include breakpoint($large-up) {
    display: block;
  }
}

.special-projects-mobile {
  @include breakpoint($large-up) {
    display: none;
  }
}

.special-projects {
  &__content {
    position: relative;
  }
  &__section {
    width: 100%;
    &.floating {
      position: absolute;
      top: 0;
      #{$ldirection}: 50%;
      transform: translateX(-50%);
    }
    img {
      display: block;
      width: 100%;
      height: auto;
      cursor: pointer;
    }
  }

  &__back {
    padding: 50px 0 25px;
    text-align: center;
    max-width: 90%;
    margin: auto;
  }
  @include breakpoint($medium-up) {
    .news-page__copy {
      margin: 30px auto 50px;
      &:after {
        content: '';
      }
    }
  }
}

.special-projects__content .news-archive__back {
  display: none;

  .transitioned & {
    display: block;
  }
}
