.featured-grid {
  text-align: center;
  padding: 20px 0;
  @include breakpoint($medium-up) {
    padding: 55px 0 30px;
  }
}

.featured-grid__title {
  @include heading--5;
}

.featured-grid__items {
  margin: auto;
  width: 95%;
  max-width: 700px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  flex-wrap: wrap;
}

.featured-item {
  display: block;
  margin: auto;
  @include breakpoint($medium-up) {
    max-width: 30%;
    margin: 0;
  }
}

.featured-item__link {
  text-decoration: none;
  .featured-item__image {
    filter: grayscale(100%);
    transition: all 0.3s;
  }
  &:hover {
    text-decoration: none;
    color: inherit;
    .featured-item__image {
      filter: grayscale(0%);
    }
    .featured-item__title {
      opacity: 1;
    }
  }
}

.featured-item__image {
  display: block;
}

.featured-item__title {
  @include font-inter-regular;
  display: block;
  margin: 10px auto 20px;
  text-decoration: none;
  font-size: 14px;
  @include breakpoint($medium-up) {
    margin: 15px auto 30px;
    opacity: 0;
    transition: opacity 0.3s;
  }
}
