.news-archive {
  margin: auto;
  padding-top: 40px;
  &:before {
    width: 1000px;
    background: $black;
    margin: auto;
    content: '';
  }
  &__back {
    text-align: center;
    margin: 20px auto;
  }
  &__header {
    @include heading--5;
    text-decoration: none;
    font-style: normal;
    &:after {
      content: none;
    }
  }
  .news-page__articles--padded {
    padding-bottom: 118px;
  }
}
