/*** ApplePay integration ***/

/* User not logged in */

body#viewcart {
  .main {
    .column {
      &.left {
        .viewcart-buttons-panel {
          .applepay-or {
            display: none;
          }
          &.applepay {
            .content {
              margin: 0 !important;
              overflow: hidden;
              .continue-shopping {
                position: absolute;
                top: 0;
                left: 22px;
              }
            }
            .checkout-buttons-content__container {
              .continue-checkout,
              .paypal-checkout {
                display: inline-block;
              }
              .continue-checkout {
                float: right;
              }
              &.applepay-enabled {
                float: right;
                position: relative;
                width: 164px;
                height: 125px;
                margin-bottom: 12px;
                padding: 0;
                text-align: center;
                .continue-checkout,
                .paypal-checkout {
                  position: absolute;
                  display: block !important;
                  float: none;
                  width: 100%;
                  margin: 0;
                }
                .continue-checkout {
                  width: 100% !important;
                  top: 0;
                }
                .paypal-checkout {
                  bottom: 0;
                  height: auto;
                }
                .or {
                  display: none;
                }
                .applepay-or {
                  position: absolute;
                  top: 52px;
                  float: none;
                  display: block;
                  width: 100%;
                  text-align: center;
                  margin: 0 !important;
                }
              }
            }
            .applepay-integration-wrapper {
              display: table;
              table-layout: fixed;
              //float: right;
              width: 100%;
              margin-top: 20px;
              padding: 1.25em;
              border: 1px solid #b7b7b7;
              background: #ebebeb;
              clear: both;
              margin-bottom: 20px;
              &.applepay-error {
                border-color: #e61f26;
                background: #ffe2e4;
              }
              div.applepay-button-container {
                width: auto;
                padding-right: 20px;
                .divide {
                  position: relative;
                  padding-top: 0;
                  // padding-right: 45px;
                  padding-bottom: 0;
                  border: 0 none;
                  input[type='checkbox'] {
                    position: absolute;
                    top: calc(50% - 4px);
                    right: 10px;
                    width: 15px;
                    height: 15px;
                  }
                  .label-content {
                    a {
                      display: inline !important;
                      text-decoration: underline;
                    }
                    padding-bottom: 0;
                  }
                }
              }
              span.applepay-button-container {
                width: 258px;
                text-align: center;
                .apple-pay-button {
                  display: block;
                  width: 100%;
                }
              }
              .applepay-button-container {
                display: table-cell;
                vertical-align: middle;
                text-align: justify;
                .form-item {
                  margin-bottom: 0;
                  text-align: justify;
                }
                .apple-pay-button {
                  overflow: hidden;
                  text-indent: 100%;
                  white-space: nowrap;
                  -webkit-appearance: -apple-pay-button;
                  -apple-pay-button-style: black;
                }
                .apple-pay-button.button-style-buy {
                  -apple-pay-button-type: buy;
                }
                .apple-pay-button.button-style-set-up {
                  -apple-pay-button-type: set-up;
                }
              }
              @media (max-width: 767px) {
                .continue-shopping {
                  position: static;
                  float: left;
                  display: block;
                  width: auto;
                }
              }
              @media (min-width: 640px) and (max-width: 641px) {
                span.applepay-button-container {
                  display: block;
                  float: left;
                  padding-left: 0;
                }
              }
              @media (max-width: 640px) {
                .applepay-button-container {
                  display: block;
                  min-width: 100%;
                  padding-left: 0;

                  .form-item {
                    input[type='checkbox'] ~ label::before {
                      margin: 9px 0 0 0 !important;
                    }
                  }
                  .btn {
                    display: block;
                    width: 100%;
                  }
                }
              }
            }
            .js-applepay-error-container {
              float: left;
              padding-top: 10px;
              padding-left: 5px;
              color: #d74027;
            }
          }
        }
        @media (max-width: 640px) {
          .viewcart-buttons-panel {
            &.applepay {
              .content {
                float: none;
                .continue-shopping {
                  position: static;
                  padding-bottom: 10px;
                }
              }
              .checkout-buttons-content__container {
                &.applepay-enabled {
                  width: 100%;
                  height: 115px;
                  margin-bottom: 12px;
                  .continue-checkout,
                  .paypal-checkout {
                    float: none !important;
                    width: 100% !important;
                    text-align: center;
                  }
                  .continue-checkout {
                    width: 100%;
                    margin-bottom: 7px !important;
                    text-align: center;
                  }
                  .paypal-checkout {
                    img {
                      width: auto;
                    }
                  }
                  .applepay-or {
                    width: 100% !important;
                  }
                }
              }
              .continue-buttons {
                .paypal-checkout {
                  width: 100%;
                  img {
                    width: 100%;
                  }
                }
              }
              .applepay-integration-wrapper {
                display: table;
                table-layout: fixed;
                border-spacing: 10px;
                float: none;
                clear: both;
                width: 100%;
                margin: 0;
                margin-bottom: 20px;
                margin-top: 20px;
                section.divide {
                  padding-top: 10px;
                }
                input[type='checkbox'] ~ label::before {
                  margin-top: 9px !important;
                }
                div.applepay-button-container {
                  display: table-footer-group;
                  float: none;
                  width: auto;
                  text-align: left;
                  .error {
                    margin: 5px 0 !important;
                    background: transparent !important;
                  }
                  //                                    input[type="checkbox"] ~ label::before {
                  //                                        margin-top: 9px;
                  //                                    }
                }
                span.applepay-button-container {
                  display: table-header-group;
                  float: none;
                  width: auto;
                }
              }
              .js-applepay-error-container {
                padding-top: 10px;
                padding-left: 0;
                float: left;
                .single-message:first-child {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* User logged in */

body#viewcart {
  &.elc-user-state-logged-in {
    //        .page-wrapper {
    .column {
      &.left {
        .viewcart-buttons-panel {
          &.applepay {
            .continue-shopping {
              width: auto;
              margin: 0;
              padding: 5px;
            }
            //.checkout-buttons-content__container {
            .checkout-buttons-content__container {
              &.applepay-enabled {
                height: 125px;
                margin-bottom: 0;
                .or {
                  display: none;
                }
                .paypal-checkout,
                .continue-checkout {
                  position: absolute;
                  left: 0;
                  display: inline-block !important;
                  float: none; //width: auto;
                  vertical-align: top;
                }
                .continue-checkout {
                  top: 0;
                }
                .paypal-checkout {
                  bottom: 0;
                  height: auto;
                  img {
                    height: inherit;
                    margin-right: 0;
                  }
                }
              }
            }
            .applepay-integration-wrapper {
              display: block;
              width: auto;
              margin-right: 0;
              margin-top: 20px;
              padding: 0;
              border: 0 none;
              background: transparent;
              clear: both;
              div.applepay-button-container {
                display: none;
              }
              span.applepay-button-container {
                width: auto;
                height: 38px;
                .apple-pay-button {
                }
              }
            }
            @media (max-width: 1024px) {
              .continue-shopping {
                width: auto;
                top: 18px;
              }
              .checkout-buttons-content__container {
                &.applepay-enabled {
                  margin-bottom: 20px;
                  .paypal-checkout,
                  .continue-checko + ut {
                    display: block !important;
                    margin: 0 auto !important;
                  }
                }
              }
              .applepay-integration-wrapper {
                clear: both;
                margin: 0;
                span.applepay-button-container {
                  width: 258px;
                  margin: 15px auto 0;
                  display: block;
                }
              }
            }
            @media (max-width: 640px) {
              .continue-shopping {
                margin-bottom: 1em !important;
              }
              .applepay-integration-wrapper {
                display: block;
                float: right;
                clear: both;
                width: auto;
                section.divide {
                  padding-top: 10px;
                }
                .applepay-button-container {
                  .btn {
                    width: 136px;
                    height: 35px;
                  }
                }
                div.applepay-button-container {
                  display: none;
                }
                span.applepay-button-container {
                  display: block;
                  float: none;
                  width: auto;
                }
              }
            }
            @media (max-width: 480px) {
              .continue-shopping {
                float: none;
                width: 100%;
                margin-top: 30px;
                text-align: center;
              }
              .checkout-buttons-content__container {
                &.applepay-enabled {
                  float: none;
                  width: auto;
                  margin: 0 auto;
                  .paypal-checkout,
                  .continue-checkout {
                    left: calc(50% - 82px);
                    width: 164px !important;
                    margin: 0 auto 10px !important;
                  }
                }
              }
              .continue-buttons {
                .paypal-checkout {
                  margin: 10px 0;
                  img {
                  }
                }
              }

              .applepay-integration-wrapper {
                float: none;
                margin: auto;
                margin-top: 20px;
                .applepay-button-container {
                  .btn {
                    margin: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
    //}
  }
}

/*** End of ApplePay integration ***/
