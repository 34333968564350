.waitlist-form {
  z-index: 1;
  margin: 0 26px;
  padding: 12px 15px 17px;
  input[type='text'] {
    border: 0;
    border-bottom: 1px solid $color-black;
    padding: 0;
    &::placeholder {
      color: $color-black;
    }
  }
}

.waitlist-form__container,
.waitlist-form__success {
  @include product-subline;
  line-height: 1.4;
  text-align: #{$ldirection};
}

.waitlist-form__header {
  @include product-subline;
  line-height: 1.4;
  margin: 0;
}

.waitlist-form__email {
  display: block;
  width: 100%;
  min-width: 256px;
  margin: 32px 0;
  min-height: 48px;
}

.waitlist-form {
  &__email-promotion {
    @include product-subline;
    line-height: 1.4;
    margin: 20px 5px 32px;
    input[type='checkbox'] {
      ~ label {
        font-size: 8px;
        &::before {
          margin-top: 0;
        }
      }
    }
  }
  &__signup {
    @include product-subline;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: 0;
  }
  &__container {
    .waitlist-form__submit {
      @include product-subline;
      line-height: 1.4;
      font-weight: 400;
      background-color: $color-black;
      max-width: 100%;
      border-color: $color-black;
      display: inline-block;
      min-width: 256px;
      margin: 0 auto;
      &:hover {
        background-color: $color-black;
      }
    }
  }
  &__errors {
    li {
      padding: 5px 0;
    }
  }
}
