.fys-page {
  border-bottom: 0px;
  h2 {
    text-transform: uppercase;
    font-size: 0.95em;
    letter-spacing: 0.01em;
    @include font-inter-medium;
    @include breakpoint($medium-up) {
      font-size: 24px;
      margin-bottom: 8px;
    }
  }

  .video-block-large {
    &__inner {
      position: relative;
      margin-top: 35px;
      @include breakpoint($medium-up) {
        position: static;
        margin-top: 0px;
      }
    }

    &__content {
      top: 0px;
      #{$ldirection}: 0;
      transform: none;
      @include breakpoint($medium-up) {
        position: absolute;
        text-align: #{$ldirection};
        #{$ldirection}: 105px;
        top: 40.5%;
        transform: none;
        z-index: 1;
      }
      @include breakpoint($large-up) {
        #{$ldirection}: auto;
        #{$rdirection}: 50%;
        margin-#{$rdirection}: -195px;
      }
    }
    .video-block-large__image {
      @include breakpoint($medium-up) {
        position: relative;
        background-color: $color-black;
        width: 100%;
        overflow: hidden;
      }

      img {
        @include breakpoint($medium-up) {
          min-height: 525px;
          display: block;
          margin-#{$rdirection}: auto;
          margin-#{$ldirection}: auto;
          position: relative;
          z-index: 1;
          width: 1025px;
          max-width: 1025px;
        }

        @include breakpoint($large-up) {
          width: 100%;
          max-width: 1200px;
        }
      }

      &:before {
        @include breakpoint($medium-up) {
          content: ' ';
          display: block;
          position: absolute;
          z-index: 1;
          width: 50%;
          height: 100%;
          background-color: $color-floral-white;
          top: 0px;
          #{$rdirection}: 0;
          #{$ldirection}: auto;
        }
      }
    }

    .video-block-large__text {
      & > p {
        margin: 0 0 1em 0;
      }

      &--1 {
        display: none;
      }
      &--2 {
        @include font-inter-medium;
        font-size: 20px;
        color: $color-black;
        @include breakpoint($medium-up) {
          color: $color-white;
          font-size: 35px;
          line-height: 1.1;
        }
      }
      &--3 {
        color: $color-black;
        margin-bottom: 20px;
        line-height: 1.2em;
        @include breakpoint($medium-up) {
          color: $color-white;
          font-size: 16px;
          margin-top: 35px;
          padding: 0px;
          margin-bottom: 0px;
        }
      }
    }
    &__cta {
      @include breakpoint($medium-up) {
        margin-top: 30px;
      }
    }
    &__button {
      @include button;
      @include button--dark;
      border-color: $color-white;
    }
    &__button:hover {
      @include button-secondary;
    }
  }

  &__title .heading--1 {
    margin: 50px 0px;
  }

  .content-container {
    padding: 10px;

    @include breakpoint($medium-up) {
      width: 800px;
      margin: 0 auto;
      padding: 0px;
    }

    fieldset {
      max-width: none;
    }

    .fys-form__errors {
      color: $color-red;
      margin-bottom: 3em;
      display: none;
    }

    .form-header {
      text-align: center;
      font-weight: 300;
    }

    .form-header strong {
      font-weight: 300;
    }
    .fys-form-field-input,
    .cs-contact-form__input {
      float: none;
      padding: 0px;
      margin-bottom: 1em;
      &__header {
        margin-bottom: 5px;
      }

      &--salutation {
        margin-top: 50px;
        a.selectBox {
          min-width: 250px;
        }
      }

      &--country_id {
        a.selectBox {
          min-width: 250px;
          .selectBox-arrow {
            height: 99%;
          }
        }
      }

      &.fyi-question-item-textarea,
      &.fyi-question {
        float: none;
        width: 100%;
        padding: 0px;
        margin-bottom: 1em;
        textarea {
          height: calc(6em - 2px);
          @include adaptive-placeholder(
            (
              height: 6em,
              textarea: true
            )
          );
        }
      }
      &.form-item {
        select {
          &.error + a {
            border-color: $color-error;
          }
        }
      }
    }
    .email-promotion {
      margin: 15px 0 0 0;
      @include breakpoint($medium-up) {
        margin: 20px 0 0 0;
      }
      label {
        &:before {
          height: 80px;
        }
      }
    }

    .fys-form__submit {
      margin: 20px 0px;
    }
  }

  .fys-form__thanks {
    a.continue {
      @include button;
      @include button--dark;
      margin: 20px 0px;
    }
    a.continue:hover {
      @include button-secondary;
    }
  }
}
