/*
  Colorbox Core Style:
  The following CSS is consistent between example themes and should not be altered.
*/
// @setup new site - this is cleaned up css from the plugin
// alter as needed per brand
// default is from example #3 dark bg, light overlay

#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
  z-index: 9999;
  overflow: hidden;
  @if $text-direction == 'rtl' {
    #{$ldirection}: auto;
  }
}
#cboxWrapper {
  max-width: none;
  @include breakpoint($medium-up) {
    background: $color-white;
  }
}
#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}
#cboxMiddleLeft,
#cboxBottomLeft {
  clear: #{$ldirection};
}
#cboxContent {
  position: relative;
}
#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
#cboxTitle {
  margin: 0;
}
#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
  width: 100%;
  height: 100%;
}
#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}
.cboxPhoto {
  float: #{$ldirection};
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}
.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}
#colorbox,
#cboxContent,
#cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

/*
  User Style:
  Change the following styles to modify the appearance of Colorbox.
*/

#cboxOverlay {
  background: $color-black-overlay;
}
#colorbox {
  outline: 0;
}
// #cboxContent {
//   background: #000;
// }
// .cboxIframe {
//   background: #fff;
// }
#cboxError {
  padding: 50px;
  border: $border;
}
#cboxLoadedContent {
  background: $color-white;
  padding: 30px 20px 20px 20px;
  @include breakpoint($medium-up) {
    padding: 40px 35px 30px 35px;
  }
  .qs-overlay-cbox & {
    padding: 0;
  }
}
#cboxTitle {
  position: absolute;
  top: -20px;
  #{$ldirection}: 0;
  color: #ccc;
}
#cboxCurrent {
  position: absolute;
  top: -20px;
  #{$rdirection}: 0;
  color: #ccc;
}
#cboxLoadingGraphic {
  //background: url(#{$base-theme-path}/img/vendor/colorbox/loading.gif) no-repeat center center;
}
/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */

#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
}
/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */

#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
  outline: 0;
}
#cboxSlideshow {
  position: absolute;
  top: -20px;
  #{$rdirection}: 90px;
  color: #fff;
}
#cboxPrevious {
  position: absolute;
  top: 50%;
  #{$ldirection}: 5px;
  margin-top: -32px;
  //background: url(#{$base-theme-path}/img/vendor/colorbox/controls.png) no-repeat top left;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}
#cboxPrevious:hover {
  background-position: bottom #{$ldirection};
}
#cboxNext {
  position: absolute;
  top: 50%;
  #{$rdirection}: 5px;
  margin-top: -32px;
  //background: url(#{$base-theme-path}/img/vendor/colorbox/controls.png) no-repeat top right;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}
#cboxNext:hover {
  background-position: bottom #{$rdirection};
}
#cboxClose {
  @include icon('close');
  color: $color-black;
  position: absolute;
  top: 0;
  #{$rdirection}: 0;
  padding: 10px;
  line-height: normal;
  font-size: 0; // hides 'close' text generated from plugin script
  @if $text-direction == 'rtl' {
    #{$ldirection}: auto;
  }
  @include breakpoint($medium-up) {
    padding: 20px;
  }
  &:before {
    content: '';
    background: url(/sites/fredericmalle/themes/fredericmalle_base/img/icons/src/close.svg) 0px 0px
      no-repeat;
    background-size: 14px;
    height: 14px;
    width: 14px;
  }
}
