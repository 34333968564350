.news-lightbox {
  opacity: 0;
  .news-article__brief {
    text-align: center;
    padding: 15px 0 28px;
  }
  .news-article__title {
    @include heading--5;
    font-size: 14px;
    @include breakpoint($medium-up) {
      font-size: 24px;
      line-height: 1.2em;
    }
  }
  .news-article__subtitle {
    @include heading--6;
    @include breakpoint($medium-up) {
      font-size: 20px;
      line-height: 1.3em;
    }
  }
  .news-article__columns {
    @include breakpoint($medium-up) {
      display: flex;
    }
  }
  .news-article__image {
    @include breakpoint($medium-up) {
      width: 50%;
      order: 1;
      padding-top: 25px;
    }
    img {
      display: block;
      width: 100%;
    }
  }
  .news-article__body {
    margin-top: 25px;
    text-align: center;
    @include breakpoint($medium-up) {
      order: 0;
      width: 50%;
      padding-#{$rdirection}: 35px;
      text-align: #{$ldirection};
    }
  }
  .button-secondary {
    padding-#{$ldirection}: 25px;
    padding-#{$rdirection}: 25px;
    @include breakpoint($medium-up) {
      background: $color-orange;
      color: $color-white;
      border: none;
      margin-top: 45px;
      max-width: 275px;
    }
  }
}
