/* Dropdown control */
a.selectBox {
  @include font-inter-regular;
  @include tracking(20);
  border: 1px solid $color-border;
  color: $color-black;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  height: 41px;
  line-height: 41px;
  min-width: 150px;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: #{$ldirection};
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  @include breakpoint($medium-up) {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }
  .selectBox-label {
    padding: 0 15px;
    overflow: hidden;
    white-space: nowrap;
  }
  .selectBox-arrow {
    background: $color-white;
    height: 100%;
    margin-#{$ldirection}: 6px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    width: 40px;
    @if $text-direction == 'rtl' {
      border-#{$rdirection}: 0;
    }
    @include breakpoint($medium-up) {
      width: 35px;
    }
    &:after {
      top: 16px;
      position: absolute;
      #{$ldirection}: 13px;
      @include breakpoint($medium-up) {
        top: 11px;
        #{$ldirection}: 12px;
      }
    }
  }
  .selectBox-arrow-svg {
    fill: $color-black;
    height: 12px;
    position: relative;
    top: -2px;
    width: 12px;
  }
  &.selectBox-menuShowing {
    .selectBox-arrow .selectBox-arrow-svg {
      transform: rotate(180deg);
    }
  }
}

/* Dropdown menu */
.selectBox-dropdown-menu {
  position: absolute;
  z-index: 99999;
  min-height: 1em;
  max-height: 200px;
  border: 1px solid $color-border;
  background: $color-white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

/* Options */
.selectBox-options {
  @include font-inter-regular;
  @include tracking(20);
  font-size: 13px;
  text-decoration: none;
  text-align: #{$ldirection};
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
  cursor: pointer;
  overflow: auto;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 12px;
  }
  li {
    line-height: 30px;
    &:first-child {
      padding-top: 0;
    }
    a {
      display: block;
      color: $color-black;
      padding: 0 15px;
      white-space: nowrap;
      overflow: hidden;
      border: 0;
      text-decoration: none;
      transition: none;
      &:hover {
        color: $color-black;
        background: $color-lighter-gray;
      }
    }
    &.selectBox-selected a {
      background: $color-black;
      color: $color-white;
      &:hover {
        background: $color-black;
      }
    }
    &.selectBox-disabled a {
      color: $color-lighter-gray;
      &:hover {
        background: $color-white;
        cursor: default;
      }
    }
  }
}
