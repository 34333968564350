.account-section.past-purchases .past-purchases__content {
  a.back-link.pc-hidden {
    top: 22px;
  }

  .past-purchases__subheader {
    padding-left: 1em;
    padding-top: 0.625rem;
  }

  .page-purchases__title-container {
    overflow: hidden;
    margin-bottom: 1em;
  }

  .past-purchases__value,
  .past-purchases__title {
    width: 20%;
    display: block;
    box-sizing: border-box;
    float: left;
  }

  .past-purchases__title,
  .product-header {
  }

  .past-purchases__list {
    padding: 1em 0;
    position: relative;
    border-top: 1px solid $color-black;

    .view-details-button {
      position: absolute;
      right: 2em;
      top: 0.9em;

      @include breakpoint($small-only) {
        right: 0em;
        top: 1.5em;
      }

      &:active {
        outline: 0;
      }
    }
  }

  .past-purchases__created-date {
    width: 13%;
  }

  .past-purchases__trans-id {
    width: 16%;
  }

  .past-purchases__order-status {
    width: 28%;
  }

  @include breakpoint($small-down) {
    .past-purchases__created-date {
      width: 13%;

      &.past-purchases__value {
        width: 30%;
      }
    }

    .past-purchases__trans-id {
      width: 30%;

      &.past-purchases__value {
        width: 25%;
      }
    }

    .past-purchases__order-status {
      width: 13%;

      &.past-purchases__value {
        width: 30%;
      }
    }

    .past-purchases__price {
      width: 30%;

      &.past-purchases__value {
        width: 25%;
      }
    }
  }

  @include breakpoint($medium-down) {
    .past-purchases__created-date {
      width: 20%;
    }

    .past-purchases__trans-id,
    .past-purchases__price {
      width: 25%;
    }

    .past-purchases__order-status {
      width: 30%;

      &.past-purchases__title {
        width: 20%;
      }
    }

    .past-purchases__tracking {
      display: none !important;
    }
  }

  .past-purchases__item--details {
    overflow: hidden;
    display: none;

    @include breakpoint($small-down) {
      padding-top: 1em;
      border-top: 1px solid $color-gray;
    }

    .close {
      text-align: right;
    }

    .product-footer {
      .total,
      .total-quantity,
      .price {
        display: inline-block;
      }
    }

    .address {
      float: left;
      width: 50%;

      h2 {
        font-size: 140%;
      }
    }

    .add-all-to-bag {
      margin-bottom: 1.5rem;
      margin: 1em 0;
      float: right;
    }

    .order-details {
      overflow: hidden;
    }

    .past-purchases-data-item-details__item {
      clear: both;
      margin-top: 1em;

      .order-header {
        margin: 1em 0;
      }

      .product-header {
        overflow: hidden;

        .product,
        .price,
        .actions {
          float: left;
        }

        .product {
          width: 60%;
        }

        .price,
        .actions {
          width: 20%;
        }
      }

      .product-header,
      .product-item {
        margin-top: 1em;
      }

      .product-item {
        overflow: hidden;

        .product-image,
        .product-info,
        .product-info__progress,
        .product-info__spp-status {
          float: left;
          width: 80%;
        }

        .product-image {
          width: 20%;
          padding: 0 1em;
        }

        .product-info {
          padding: 1em;
        }

        .product-info__button {
          margin: 1em 0 0;
        }
      }

      .product-footer {
        overflow: hidden;

        .total {
          float: left;
          margin-left: 65%;
          width: 15%;
        }

        .price {
          float: left;
          width: 20%;
        }

        .total-quantity {
          display: none;
        }
      }
    }
  }
}

.past-purchases {
  .account-section__content {
    .tracking-link-disclaimer {
      display: none;
      max-width: 400px;
    }
    .past-purchases__tracking {
      &.tracking-link {
        position: relative;
      }
    }
    .tracking-link:hover .tracking-link-disclaimer {
      display: block;
      background-color: $color-white;
      color: $color-black;
      border: 1px solid $color-black;
      border-radius: 6px;
      position: absolute;
      bottom: 25px;
      width: 300px;
    }
  }
}

.past-purchases-page__content .product-item__details {
  padding-top: 10px;
}

.page-header {
  width: auto !important;
}

.past-purchases-page {
  @include breakpoint($small-down) {
    .account-section__nav {
      clear: both;
    }
    .account-section__content {
      border: none;
    }
  }
  @include breakpoint($medium-up) {
    .page-header {
      h2 {
        margin-bottom: 0;
      }
    }
  }
}

.order-details-page {
  .order-details__item {
    .gift_message {
      word-break: break-all;
    }
  }
}
