///
/// customer service contact form
///
.cs-page .basic-responsive {
  padding: 0;
}

.cs-contact-form {
  border-top: 1px solid $color-black;
  &__section {
    .form-item {
      select.error + a {
        border-color: $color-error;
      }
      @include breakpoint($medium-up) {
        width: 50%;
        float: #{$ldirection};
      }
      a.selectBox {
        width: 100%;
      }
    }
    .email-promotion {
      margin: 15px 0;
      @include breakpoint($medium-up) {
        margin: 0 0 15px 0;
        width: 100%;
      }
      label {
        &:before {
          height: 80px;
        }
      }
    }
    &--thanks + .cs-contact-form {
      border: 0;
    }
  }
  &__select {
    &.form-item {
      width: 100%;
    }
  }
  &__additional-questions {
    margin-top: 31px;
    .form-item {
      padding-bottom: 10px;
    }
  }
  &__input--title {
    margin-#{$rdirection}: 5px;
  }
}

.cs-contact-form__errors {
  color: $color-red;
  margin-bottom: 10px;
}

.cs-contact-form__input--comments.form-item {
  width: 100%;
  clear: both;
  padding-top: 10px;
  margin-bottom: 20px;
}

.cs-contact-form__input--registerered-member.form-item {
  overflow: hidden;
  clear: both;
  @include breakpoint($medium-up) {
    width: 100%;
    margin: 20px 0;
  }
}

.cs-contact-form__radio {
  input.error + label {
    color: $color-red;
  }
  width: 20%;
  float: #{$ldirection};
  @include breakpoint($medium-up) {
    width: 12%;
  }
  &:last-child {
    width: 30%;
  }
}

.cs-contact-form__label {
  margin-bottom: 10px;
  label {
    font-size: 12px;
    margin-top: 20px;
    display: inline-block;
    @include breakpoint($medium-up) {
      font-size: 16px;
    }
  }
}

.cs-contact-form,
.cs-contact-form__contact-information,
.cs-contact-form__additional-questions {
  padding-top: 0;
  @include breakpoint($medium-up) {
    padding-top: 0.4em;
  }
}

.cs-contact-form__submit input.button {
  width: 100%;

  @include breakpoint($medium-up) {
    width: 18em;
  }
}
