#loyalty__panel__transactions {
  @include loyalty-panel;

  .loyalty__panel__transactions__title {
    @include loyalty-panel-title;
  }

  table {
    width: 100%;
    th,
    td {
      padding: 10px;
    }
    th {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  table,
  th,
  td {
    border: 1px solid #ccc;
  }

  loyalty__panel__transactions__list__column-activity--level {
  }

  .loyalty__panel__transactions__list__column-points,
  .loyalty__panel__transactions__list__column-earned,
  .loyalty__panel__transactions__list__column-spent,
  .loyalty__panel__transactions__list__column-total {
    display: none;
  }
  @include breakpoint($medium-up) {
    .mobile {
      display: none;
    }
    .loyalty__panel__transactions__list__column-points,
    .loyalty__panel__transactions__list__column-earned,
    .loyalty__panel__transactions__list__column-spent,
    .loyalty__panel__transactions__list__column-total {
      display: table-cell;
    }
  }

  .loyalty__panel__transaction__list__paginate__separate:before {
    content: '/';
  }
}
