.news-page,
.news-archive {
  padding: 20px 0;
  width: 100%;
  margin: auto;

  &__header {
    @include heading--1;
    @include heading--1__underline;
    text-align: center;
    width: 100%;
    max-width: 960px;
    margin: auto;
  }

  &__copy {
    text-align: center;
    padding: 0 30px;
    margin: 30px auto;
  }

  &__articles {
    padding: 13px 9px 0;
  }

  &__article {
    width: 100%;
    color: $black;
    background: transparent;
    transition: all 0.5s;
    border: 1px solid $color-lighter-gray;
    padding: 8px 9px 3px;
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }

  .news-article {
    &__brief-text {
      padding: 11px 0;
    }
    &__title {
      @include heading--5;
      font-size: 16px;
    }
    &__subtitle {
      @include heading--6;
    }
    &__image {
      margin: auto;
      display: block;
      width: 100%;
    }
    &__overlay {
      display: none;
    }
  }

  &__load {
    text-align: center;
    margin-top: 25px;
    .button-secondary {
      padding-#{$ldirection}: 28px;
      padding-#{$rdirection}: 28px;
    }
  }
  &__more {
    display: none;
  }

  @include breakpoint($large-up) {
    padding-top: 75px;
    padding-bottom: 0;
    .news-page__header {
      margin-bottom: 0;
      &:after {
        display: none;
      }
    }
    .news-page__copy {
      max-width: 960px;
      font-style: normal;
      margin-top: 10px;

      p {
        line-height: 1.3em;
      }

      &:after {
        content: '';
        width: 350px;
        height: 1px;
        background: $black;
        display: block;
        margin: auto;
        margin-top: 50px;
      }
    }
    .news-page__articles {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      max-width: 1200px;
      margin: auto;
      padding-top: 55px;
      padding-bottom: 25px;
    }
    .news-page__row {
      display: flex;
      justify-content: center;
      position: relative;
    }
    .news-page__article {
      width: auto;
      display: flex;
      align-items: flex-end;
      border: none;
      padding: 0;
      &:hover {
        background: transparent;
      }
    }
    .news-article {
      height: 100%;
    }
    .news-article {
      background: transparent;
      transition: all 0.5s;
      border: 1px solid $color-lighter-gray;
      padding: 15px 15px 4px 15px;
      cursor: pointer;
      &:hover {
        .news-article__image {
          filter: grayscale(0);
        }
      }
      .news-article__image {
        filter: grayscale(100%);
        transition: all 0.5s;
        position: relative;
        overflow: hidden;
        img {
          display: block;
          max-width: none;
          // Uncomment below if images need to be flexible size
          // position: absolute;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);
          // min-width: 100%;
          // min-height: 100%;
        }
      }
    }
    .news-article__brief {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .news-article__brief-text {
      justify-self: flex-end;
      padding: 11px 0 0;
    }
  }
}

.news__row {
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  margin-top: -1px;
  .news__column {
    display: flex;
    width: 50%;
    &:first-child {
      justify-content: flex-end;
      margin-#{$rdirection}: -1px;
    }
  }
}

// IE 10 + 11 Fix for flexbox issue
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .news-page,
  .news-archive {
    .news__cell {
      display: inline-block;
      overflow: hidden;
    }
    .news-page__article {
      display: block;
    }
    .news-article__wrapper {
      flex: 1;
    }
  }
}
