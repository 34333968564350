/** Account Styles **/
.sign-in-page {
  #sign-in-paypal-panel {
    .column {
      width: 50%;
      float: left;
      input {
        width: 100%;
      }
      p {
        min-height: 1.5rem;
      }
      #forgot-pw-note {
        display: inline-block;
        margin-bottom: 1rem;
      }
    }
  }
  .sign-in__section {
    .heading--1__underline {
      margin: 0 0 20px 0;
      .section-head__link {
        margin-bottom: 15px;
      }
      &:after {
        max-width: 100%;
      }
    }
  }
  @include breakpoint($medium-up) {
    .sign-in__section {
      width: 100%;
      margin: 0 auto;
    }
  }
  @include breakpoint($small-down) {
    .form-item.submit {
      float: none;
      width: 100%;
      padding: 0;
      .button {
        margin: 0;
        max-width: 100%;
      }
    }
    fieldset .form-item {
      float: none;
      padding-right: 0;
    }
  }
}

.account-page {
  .account-section__nav {
    display: block;

    .sidebar-menu__item a {
      border-color: $color-light-gray;
      text-decoration: none;
      padding: 1.2em 0.1em 0.2em;
      line-height: 1.5;
    }

    .button--signout {
      margin: 0 1em;
      width: auto;
      display: block;
    }
  }
}

.account-section__nav {
  display: block;
}
.account-utilities {
  .account-utilities--heading {
    display: none;

    @include breakpoint($medium-up) {
      display: block;
    }
  }
}
@include breakpoint($small-down) {
  .email-signup--footer {
    .site-email-signup__form {
      .tooltip {
        margin-top: 10px;
      }
    }
  }
}
.account-section {
  &.profile-page {
    .profile-page__container--form {
      padding: 0 1em 3em;
    }
    .account-promotions {
      margin: 2em 0 0;

      &.account-promotions--email {
        padding-right: 1em;
        @include breakpoint($landscape-up) {
          width: 100%;
        }
        label {
          margin-top: 10px;
        }
      }

      &.account-promotions--sms {
        padding-left: 1em;
        float: none;
        width: 100%;
        @include breakpoint($medium-up) {
          float: left;
          width: 50%;
        }
      }

      .form-field {
        .form-field__prefix {
          float: left;
          width: 10%;
        }

        .form-field__input {
          float: left;
          width: 90%;
        }
      }

      .email-info__item--pc-email-promotions {
        overflow: hidden;
        padding-bottom: 0.5em;
      }
      .sms-info__item--mobile-phone {
        overflow: hidden;
        padding: 0.5em 0;
      }
    }
  }
  .account-section__content {
    @include breakpoint($medium-up) {
      float: right;
    }
  }

  #customer-service {
    display: none;
  }
  .profile-pic-main {
    display: none;
  }
  .order-info.accordion-content {
    padding: 0 0 1em;
  }
  .section-head__link,
  .address-book__link,
  .payment-info__link,
  .order-info__link {
    text-decoration: underline;
  }
  .account__payment-addition {
    float: left;
    padding: 16px;
    .heading--5 {
      margin: 10px 0;
    }
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  input[type='text'] {
    width: 100%;
  }
  a.selectBox,
  .selectBox {
    width: 100%;
  }
  .cart-item .cart-item__price {
    font-size: 0.75rem;
  }
  .order-products {
    .cart-item.product {
      .cart-item__desc,
      .cart-item__thumb {
        display: inline-block;
        width: 49%;
        float: left;
        .cart-item__qty {
          text-align: left;
        }
        .cart-item__qty-label {
          display: inline-block;
        }
        .cart-item__price {
          float: left;
        }
      }

      .cart-item__price,
      .cart-item__qty {
        float: left;
      }
    }
  }
  @include breakpoint($medium-up) {
    .optional-info__item {
      a.selectBox {
        width: auto;
      }
    }
    .address-book-page__content {
      float: right;
    }
  }
  .expiration_date_container {
    a.selectBox,
    .selectBox {
      width: 48%;
    }
  }
  .form_element {
    margin-top: 1rem;
  }
  @include breakpoint($small-down) {
    #customer-service,
    .account-page__content {
      padding: 0 1.5rem;
      .section-content {
        padding: 1rem;
        a {
          text-decoration: underline;
        }
      }
      section {
        margin: 1rem 0;
      }
    }
    .heading--5 {
      font-size: 1.12rem;
    }
    .heading--8 {
      padding-bottom: 0.5rem;
    }
    .section-header {
      position: relative;
      .section-head__link {
        position: absolute;
        right: 0;
        top: 0.6rem;
      }
    }
    .optional-info__item {
      .selectBox {
        min-width: 32%;
      }
    }
  }
  .account-section__nav {
    display: block;
  }
  &__content {
    .payment_book_container {
      float: left;
      width: 100%;
      .column {
        margin: 1em 0;
        h3 {
          margin-bottom: 1em;
        }
      }
    }
  }
}
#address_form_container {
  .heading--1 {
    margin: 55px 0 24px 0;
  }
  .address_form_container {
    padding: 2rem;
    input[type='text'] {
      width: 60%;
    }
  }
}
.address-overlay {
  padding: 1rem 4rem;
  #address_billing,
  #address_shipping {
    width: 100%;
    input {
      width: 100%;
    }
    .form-item {
      width: 100%;
      &.name {
        width: 49%;
        display: inline-block;
        @include breakpoint($small-down) {
          width: 100%;
        }
        &.second {
          margin-left: 0.5rem;
          @include breakpoint($small-down) {
            margin-left: 0;
          }
        }
      }
      &.title,
      &.qas-submit-container {
        margin-bottom: 0.5rem;
      }
      &.qas-submit-container {
        width: 49%;
        @include breakpoint($small-down) {
          width: 100%;
        }
        input {
          background-color: auto;
        }
      }
    }
    .address-overlay_submit {
      width: 49%;
      margin-top: 3rem;
      margin-bottom: 1.5rem;
      @include breakpoint($small-down) {
        padding: 10px;
      }
    }
    .manual-address {
      margin-top: 1rem;
      a {
        text-decoration: underline;
      }
    }
  }
  select::-ms-expand {
    display: none;
  }
}
.site-footer {
  clear: both;
}

.footer {
  .email-signup--footer {
    margin-top: 36px;
  }
  .country-chooser-container {
    margin: 0 0 1em 0;
  }
  .footer__social {
    margin: 1em 0 0 0;
  }
  .country-chooser__select {
    margin: 0;
    width: 254px;
    .selectBox-arrow {
      right: 5px;
    }
  }
}
.password-reset-page {
  .password-reset__fieldset {
    .password-reset__item {
      .password__info {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 39%;
        }
      }
    }
  }
}

#signin {
  .sign-in-page {
    .sign-in__section {
      .form-item {
        input[type='checkbox'] {
          & ~ .label,
          & ~ label {
            right: 0;
            padding: 3px 18px 5px;
            @include breakpoint($medium-up) {
              padding: 0 18px;
            }
            .label-content {
              display: initial;
            }
          }
        }
      }
    }
  }
}
@import 'account/order-history';
