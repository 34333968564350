.product-full {
  max-width: $max-width;
  margin: 0 auto;
  padding: 5px 0 20px 0;
  position: relative;
  @include breakpoint($medium-up) {
    min-height: 700px;
    padding: 35px 0 60px 0;
  }

  &__breadcrumb {
    @include font-inter-medium;
    text-transform: uppercase;
    font-size: 0.875rem;
    a {
      text-decoration: none;
      color: $color-gray;
      &.active {
        color: $color-black;
      }
    }
  }

  .product-full__category-name {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      text-align: center;
      margin-bottom: 2%;
    }
    .product-full__category-heading {
      margin: 15px 0;
      font-size: 28px;
      @include breakpoint($medium-up) {
        margin: 40px 0 79px 0;
      }
    }
  }
  .product-full__image {
    width: 100%;
    position: relative;

    @include breakpoint($medium-up) {
      float: #{$ldirection};
      width: 50%;
      max-width: 630px;
      margin-#{$ldirection}: 6.25%;
      @if $text-direction == 'rtl' {
        margin-#{$ldirection}: auto;
      }
    }

    .product-full__image-carousel {
      min-height: 31em;

      .carousel-controls {
        display: none;
      }

      @include breakpoint($medium-up) {
        min-height: 38em;
      }
      .slick-slide {
        width: 100vw;
        height: auto;
        @include breakpoint($medium-up) {
          width: 100%;
        }
      }
      .product-full__carousel__slide ~ .product-full__carousel__slide {
        display: none;
      }
      .slick-initialized {
        .product-full__carousel__slide ~ .product-full__carousel__slide {
          display: block;
        }
      }
    }

    .product-full__image-img {
      width: 100%;
      height: auto;
      object-fit: cover;
      @include breakpoint($medium-up) {
        //min-height: 38.42em;
        @include swap-direction(padding, 3% 7% 7% 11%);
        min-height: 0;
      }
    }
  }
  .product-full__details {
    width: 100%;
    margin-top: 4px;

    @include breakpoint($medium-up) {
      float: #{$ldirection};
      margin-top: 0;
      margin-#{$ldirection}: 3.90625%;
      margin-#{$rdirection}: 6.25%;
      width: 33.59375%;
      @if $text-direction == 'rtl' {
        margin-#{$ldirection}: auto;
      }
    }
    .product-full__product-name {
      padding: 0 24px;
      .product-full__name {
        @include font-inter-medium;
        font-size: 20px;
        text-transform: uppercase;
        margin: 0;
        @include breakpoint($medium-up){
          @include s1;
        }
      }
      .product-full__subline {
        @include font-inter-regular;
        font-style: normal;
        margin: 2px 0 0;
        letter-spacing: -0.02px;
        font-size: $base-font-size;
        line-height: $base-line-height;
      }
      @include breakpoint($medium-up) {
        float: none;
        padding: 0;
      }
    }
    .product-size {
      @include font-inter-regular;
      font-size: 15px;
      float: #{$ldirection};
      padding: 0 20px;
      @include breakpoint($medium-up) {
        float: none;
        padding: 0;
      }
    }
    .product-sku-price {
      @include font-inter('medium', 16);
      margin: 20px auto 0;
      padding: 0 20px 16px;
      @include breakpoint($medium-up) {
        padding: 0 0 16px;
      }
      .product-sku-price__value {
        @include font-inter('medium', 16);
        .product-sku-unit-price__value {
          color: $color-dark-gray;
          font-size: 80%;
          display: block;
          @include breakpoint($medium-up) {
            margin-#{$ldirection}: 2%;
            display: inline-block;
          }
        }
      }
    }
    .product-full__installment-price {
      clear: $rdirection;
      padding: 10px 24px 0;
      @include breakpoint($medium-up) {
        padding: 0;
      }
      .afterpay-paragraph {
        font-size: 14px;
      }
      .afterpay-logo {
        width: 85px;
      }
    }
    .product-full__options {
      padding: 10px 24px 0;
      clear: both;
      @include breakpoint($medium-up) {
        padding: 0;
      }
    }
    .product-full__short_description {
      @include font-inter('regular', 14);
      color: $color-dark-gray-3;
      margin-bottom: 24px;
      padding: 0 24px;
      @include breakpoint ($medium-up) {
        margin-bottom: 32px;
        padding: 0;
      }
    }
    .sku-menu__container {
      display: block;
      clear: both;
      width: 100%;
      float: #{$ldirection};
      @include breakpoint($medium-up) {
        width: 100%;
        float: none;
        margin: 16px 0 17px 0;
        padding-#{$ldirection}: 0;
      }
      .spp--kit & {
        width: 100%;
        float: none;
        margin: 0px 0 10px 0;
        padding-#{$ldirection}: 0;
        @include breakpoint($medium-up) {
          width: 100%;
          float: none;
          margin: 16px 0 17px 0;
          padding-#{$ldirection}: 0;
        }
      }
      .sku-menu {
        @include font-inter-regular;
        @include swap-direction(padding, 0 29px 0 0);
        font-size: 14px;
        text-align: center;
        width: 100%;
        .selectBox-arrow {
          width: 22px;
          &::after {
            #{$ldirection}: 5px;
          }
        }
        &__onesize {
          text-transform: capitalize;
        }
        .selectBox-label {
          @include font-inter-regular;
          font-size: 14px;
          letter-spacing: -.02em;
          padding: 0 4px;
          display: block;
          text-align: center;
          text-transform: capitalize;
      
          @include breakpoint($medium-up) {
            padding: 0 6px;
          }
        }
        @include breakpoint($medium-up) {
          max-width: 100%;
          letter-spacing: 0;
          height: 48px;
          line-height: 48px;
          .selectBox-label {
            padding: 0 5px;
          }
          .selectBox-arrow {
            width: 30px;
            &:after {
              top: 21px;
              #{$ldirection}: 9px;
            }
          }
        }
        @include breakpoint($large-up) {
          font-size: 14px;
        }
      }
      .sku-menu__onesize {
        @include font-inter-regular;
        font-size: 15px;
      }
    }
    .product-qty-select {
      float: #{$rdirection};
      width: 14%;
      @include breakpoint($medium-up) {
        width: 24%;
        float: #{$ldirection};
        margin-#{$rdirection}: 4%;
        @if $text-direction == 'rtl' {
          margin-#{$ldirection}: auto;
        }
      }

      .spp--kit & {
        display: none;
      }

      &:after {
        display: block;
        clear: both;
        content: '';
      }

      .product-qty-select__selectbox {
        min-width: 100%;
        width: 100%;
        padding: 0 6px;
        background-position: 125% 48%;
        @if $text-direction == 'rtl' {
          background-position-x: 10px;
        }
        @include breakpoint($medium-up) {
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          padding: 0;
        }
        .selectBox-arrow {
          width: 45px;
          &:after {
            top: 50%;
            #{$ldirection}: 16px;
            transform: translateY(-35%);
          }
        }
      }
    }
    .buttons {
      clear: both;
      padding: 15px 24px;
      @include breakpoint($medium-up) {
        clear: none;
        padding: 0;
        float: #{$ldirection};
        width: 100%;
        margin: 0 0 22px 0;
      }
      .spp--kit & {
        @include breakpoint($medium-up) {
          width: 100%;
        }
      }
      .button {
        @include button--dark;
        display: block;
        font-size: 14px;
        letter-spacing: -.02em;
        text-transform: capitalize;
        width: 100%;
        &--disabled {
          background: $color-light-gray;
          border-color: $color-light-gray;
          color: $color-white;
          &:hover {
            background: $color-light-gray;
            border-color: $color-light-gray;
            color: $color-white;
          }
        }
        @include breakpoint($medium-up) {
          height: 40px;
          line-height: 40px;
          max-width: none;
          padding: 0;
          text-transform: capitalize;
        }
      }
    }
    .product-full__promotion {
      padding: 0 20px;
      margin-top: 20px;
      @include breakpoint($medium-up) {
        padding: 0;
      }
    }
    .product-offer__text {
      @include font-inter('regular', 14);
      p {
        font-weight: normal;
      }
    }
    .product__inventory-status {
      padding: 0 20px;
      @include breakpoint($medium-up) {
        clear: both;
        padding: 0;
      }
    }
    .product-offer {
      padding-#{$ldirection}: 20px;
      @include breakpoint($medium-up) {
        clear: both;
        padding: 0;
      }
    }
    .product-full__accordion {
      margin: 20px 0;
      padding-bottom: 1px;
      @include breakpoint($medium-up) {
        margin-top: 36px;
      }
      .product-full__long_description {
        @include font-inter('regular', 14);
        overflow: hidden;
        transition: height 0.5s ease-out;
        &::after {
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.5) 10%, $color-white 100%);
          bottom: 40px;
          content: '';
          height: 40px;
          opacity: 1;
          pointer-events: none;
          position: absolute;
          right: 0;
          transition: opacity .3s ease;
          width: 100%;
        }        
        &.clear-gradient::after {
          display: none;
        }
      }
      .product-full__long_description.expanded {
        padding-bottom: 20px;
        transition: height .5s ease;
        &::after {
          opacity: 0;
          transition: opacity 0.3s ease;
        }
      }
      .accordions {
        .accordion {
          .accordion_btn {
            @include font-inter-semi-bold;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.02em;
            padding-#{$ldirection}: 24px;
            padding-#{$rdirection}: 24px;
            text-transform: uppercase;
            margin: 0;
            @include breakpoint($medium-up) {
              @include font-inter-bold;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: initial;
              padding-#{$ldirection}: 0;
              padding-#{$rdirection}: 5px;
            }
          }
          // Accordion Copy  - Hidden by default
          .accordion_panel {
            @include font-inter('regular', 14);
            padding: 10px 20px 52px;
            position: relative;
            @include breakpoint($medium-up) {
              padding-#{$ldirection}: 0;
              padding-#{$rdirection}: 0;
            }
            .more,
            .less {
              position: absolute;
              bottom: 25px;
              left: 20px;
              z-index: 10;
              @include breakpoint($medium-up) {
                left: 0;
              }
            }
            .less {
              display: none;
            }
          }
        }
      }
    }
  }
  // product-full__details
  .product-full__ingredients-label {
    cursor: pointer;
  }
  &:after {
    display: block;
    clear: both;
    content: '';
  }

  &__accordion-expand-cta {
    cursor: pointer;
    margin: 0 7px;
  }
}

.product-full--shaded {
  .product-full__details {
    .product-qty-select {
      width: 100%;
      float: none;
      @include breakpoint($medium-up) {
        float: #{$ldirection};
        width: 24%;
      }
    }
  }
}

.spp-cross-sells {
  @include responsive-container;
  max-width: $max-width-large;
  padding: 0 20px 30px;
  @include breakpoint($medium-up) {
    padding: 0 50px 50px;
  }

  &__header {
    @include font-inter-medium;
    letter-spacing: 1px;
    line-height: 1.2;
    padding: 0 20px;
    font-size: 24px;
    @if $text-direction == 'rtl' {
      text-align: center;
    }
    @include breakpoint($medium-up) {
      font-size: 28px;
      padding: 30px 0 4px 0;
    }
  }
  &__products {
    display: none;
  }
  &__carousel {
    position: relative;

    .slick-slide {
      padding: 0;
    }
  }
  .carousel-controls {
    .slick-arrow {
      color: $color-black;
      top: 30px;
      position: absolute;
    }
  }
  .carousel-dots {
    margin-top: 10px;
  }
}

.product-full__recommended {
  .content-block-large__content-text {
    @include breakpoint($medium-up) {
      padding: 30px 30px 4px 30px;
    }
  }
}

.product-full__container__hide-product-offer ul.accordions > li:first-child .accordion_btn {
  border: 0px;
}

.product-full__container__hide-product-offer .product-full__details .product-full__accordion {
  @include breakpoint($medium-up) {
    margin-top: 0px;
  }
}
#afterpay-modal-close {
  width: 20px;
}
.afterpay.order-summary__content {
  clear: both;
  .afterpay-learnmore,
  img {
    vertical-align: middle;
  }
  .afterpay-learnmore {
    line-height: 0;
  }
}
.order-summary-content {
  .afterpay-messages-container {
    clear: both;
    text-align: $rdirection;
  }
}
