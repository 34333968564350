.loyalty__panel__join {
  @include loyalty-panel;

  display: block;

  &.is-member {
    display: none;
  }

  @include breakpoint($medium-up) {
    float: right;
    width: 50%;
  }

  &.index-1 {
    #loyalty__page__about & {
      .loyalty__panel__join__title {
        display: none;
      }
    }
  }

  @include breakpoint($medium-up) {
  }
}
