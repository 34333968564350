.offer-banner-formatter {
  position: absolute;
  width: 100%;
  height: $offer-banner-height--mobile;
  overflow-y: auto;
  #{$ldirection}: 0;
  #{$rdirection}: 0;
  @include breakpoint($landscape-up) {
    height: $offer-banner-height;
  }
  p {
    height: inherit;
    overflow-y: auto;
  }
  .link {
    display: block;
  }
  &__background {
    position: absolute;
    top: 0;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    opacity: 0.85;
    background-color: $color-medium-gray;
  }
  &__item {
    min-height: 28px;
    &.slick-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 7px 40px;
    }
  }
  &__slider {
    .slick-track {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $offer-banner-height--mobile;
      @include breakpoint($landscape-up) {
        height: $offer-banner-height;
      }
    }
    .slick-prev:before,
    .slick-next:before {
      display: none;
    }
    .slick-next {
      width: 40px;
      #{$rdirection}: 0;
    }
    .slick-prev {
      width: 40px;
      #{$ldirection}: 0;
    }
  }
  .offer-banner-item {
    &__content {
      color: $black;
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      font-family: $font-inter-regular;
      font-weight: 500;
    }
    &__body {
      p {
        margin: 0;
      }
    }
    &__title {
      padding-top: 8px;
    }
    &__cta .link {
      font-size: 12px;
      font-family: $font-inter-regular;
      font-weight: 500;
      line-height: 12px;
    }
  }
}

.content-block-offer-banner--enabled {
  &.toolbar-drawer {
    padding-top: 0;
  }
  .site-content {
    padding-top: $header-height + $offer-banner-height--mobile;
    @include breakpoint($medium-up) {
      padding-top: $medium-up-header-height + $offer-banner-height;
    }
    @include breakpoint($landscape-up) {
      padding-top: $landscape-up-header-height + $offer-banner-height;
    }
  }
}

.content-block-offer-banner--enabled.transparent-header {
  &.toolbar-drawer {
    padding-top: initial;
  }
  .site-content {
    padding-top: initial;
  }
}

