/**
 * Generic typeahead
 */
.typeahead-wrapper {
  background: $color-white;
  font-size: 1.5em;
  @include breakpoint($medium-up) {
    font-size: 1em;
  }

  .typeahead-suggestions__item {
    padding: 4px 0;
  }
  .search-suggestions__item {
    display: block;
    padding: 15px;
    font-size: 17px;
    a {
      font-size: 0.989em;
      @include breakpoint($medium-up) {
        font-size: 17px;
      }
    }
    @include breakpoint($medium-up) {
      padding: 25px 20px;
      text-align: center;
    }
  }
  .product-results {
    padding: 15px 15px 0 15px;
    @include breakpoint($medium-up) {
      @include pie-clearfix;
      padding: 20px 20px 0 20px;
      max-width: 1240px;
      margin: 0 auto;
    }
    > div {
      display: none;
      visibility: hidden;
      // hide all but the first 4
      &:nth-child(-n + 4) {
        display: block;
        visibility: visible;
      }
    }
  }
}
.esearch-nav {
  @include swap-direction(padding, 16px 48px 16px 0);
}

.esearch-product--typeahead {
  .product-brief__container {
    padding: 0;
  }
  @include breakpoint($medium-up) {
    float: #{$ldirection};
    width: 23%;
    margin-#{$rdirection}: 1.53657%;
    &.last {
      margin-#{$rdirection}: 0;
    }
  }
  .esearch-product--typeahead--mobile {
    text-align: #{$ldirection};
    margin: 0 0 15px 0;

    .esearch-product__headline {
      //font-family: $font--subheading-alt;
      margin: 0 0 3px 0;
      padding-#{$ldirection}: 30px;
      @include breakpoint($medium-up) {
        font-size: 22px;
        line-height: 22px;
        padding-#{$ldirection}: 0;
      }
      a {
        text-decoration: none;
        text-transform: uppercase;
      }
    }
    .product-brief {
      &__details {
        display: flex;
      }
      &__image {
        display: flex;
        flex-shrink: 0;
        width: 120px;
      }
    }
  }
}

/**
 * Search form in page content - special positioning
 * @todo audit this
 */
.site-content {
  .search-form {
    position: relative;
    .typeahead-wrapper {
      position: absolute;
      z-index: 999;
      width: 100%;
      background-color: $color-white;
      //border: $color-black solid 2px;
      //padding: 1em;
    }
  }
}
