.basic-grid-formatter--content-block {
  .basic-grid__label {
    @include accordion-btn;
    display: block;
    padding: 13px 10px 13px 10px;
    @include breakpoint($medium-up) {
      display: none;
      padding: 11px 10px 8px 10px;
    }
  }

  .basic-grid__content {
    display: none;
    padding: 11px 10px 8px 10px;
    @include breakpoint($medium-up) {
      display: block;
      padding: 0;
    }
  }

  // Mobile specific fixes
  @include breakpoint($small-down) {
    .content-block-large__content-text {
      text-align: center;

      .content-block-large__content-text__header {
        display: none;
      }
      a {
        display: none;
      }
    }

    .basic-grid__read-more {
      max-width: 240px;
      margin: 0 auto 10px auto;
    }
  }
}
