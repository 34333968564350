///////////////////////////////
/// VIDEO LARGE
/// bg image dictates container height
/// uses generic font and size classes
///////////////////////////////
.video-block-large {
  position: relative;
  overflow: hidden;
  &__inner {
    position: absolute;

    &--mobile {
      height: 100%;
      top: 0;
      .video-block-large__content {
        @include vertical-horizontal-center;
      }
    }

    @include breakpoint($medium-up) {
      position: absolute;
      z-index: 1;
      // content widths
      &.video-block-large__inner-width--20 {
        width: 20%;
      }
      &.video-block-large__inner-width--30 {
        width: 30%;
      }
      &.video-block-large__inner-width--40 {
        width: 40%;
      }
      &.video-block-large__inner-width--50 {
        width: 50%;
      }
      &.video-block-large__inner-width--60 {
        width: 60%;
      }
      &.video-block-large__inner-width--70 {
        width: 70%;
      }
      &.video-block-large__inner-width--80 {
        width: 80%;
      }
      &.video-block-large__inner-width--90 {
        width: 90%;
      }

      // horizontal position
      &-hpos--center {
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
      &-hpos--left {
        #{$ldirection}: 0;
        // margin details
        &.video-block-large__inner-margin--50 {
          #{$ldirection}: 50%;
        }
        &.video-block-large__inner-margin--40 {
          #{$ldirection}: 40%;
        }
        &.video-block-large__inner-margin--30 {
          #{$ldirection}: 30%;
        }
        &.video-block-large__inner-margin--20 {
          #{$ldirection}: 20%;
        }
        &.video-block-large__inner-margin--10 {
          #{$ldirection}: 10%;
        }
      }
      &-hpos--right {
        #{$rdirection}: 0;
        // margin details
        &.video-block-large__inner-margin--50 {
          #{$rdirection}: 50%;
        }
        &.video-block-large__inner-margin--40 {
          #{$rdirection}: 40%;
        }
        &.video-block-large__inner-margin--30 {
          #{$rdirection}: 30%;
        }
        &.video-block-large__inner-margin--20 {
          #{$rdirection}: 20%;
        }
        &.video-block-large__inner-margin--10 {
          #{$rdirection}: 10%;
        }
      }
      // vertical position
      &-vpos--middle {
        height: 100%;
        top: 0;
        .video-block-large__content {
          @include vertical-horizontal-center;
        }
      }
      &-vpos--top {
        top: 10%;
      }
      &-vpos--bottom {
        bottom: 10%;
        top: auto;
      }
    }
  }

  &__content {
    text-align: center;
    padding: 10px;
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
  &__text {
    margin-bottom: 5px;
    > p {
      margin: 0;
      line-height: inherit;
    }
    &--3 {
      @include breakpoint($medium-up) {
        font-size: 19px;
      }
    }
    &--logo {
      margin-bottom: 15px;
      img {
        display: inline; // override .slick-slide img
      }
    }
  }
  &__cta {
    margin: 10px 0 0 0;
    @include breakpoint($medium-up) {
      margin-top: 23px;
    }
    .button {
      @include breakpoint($medium-down) {
        width: 100%;
      }
    }
    &--footer {
      margin: 10px 0 20px 0;
      text-align: center;
      @include breakpoint($medium-up) {
        margin: 20px 0 50px 0;
      }
    }
  }
  &__button {
    @include link;
    &.button {
      border-width: 1px;
      color: $color-white;
      transition: background $transition-duration $transition-effect, border $transition-duration $transition-effect;
      &.button--inverted {
        color: $color-black;
      }
      &.button--large {
        font-size: 19px;
      }
    }
  }
  &__image,
  &__video {
    position: relative;
    // transparent overlay
    &:before {
      content: '';
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      width: 100%;
      height: 100%;
      background: $color-transparent--dark;
      z-index: 1;
      .video-block-large--hide-overlay & {
        display: none;
      }
    }
    img,
    video {
      display: block;
      width: 100%;
    }
  }
  &__video {
    video {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    img {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
}

// Modifier with videoplayer - show play icon
.video-block-large--has-videoplayer {
  cursor: pointer;
  .video-block-large__image,
  .video-block-large__video {
    position: relative;
    cursor: pointer;
    // play icon on mobile
    .icon--play {
      @include vertical-horizontal-center;
      position: absolute;
      height: 60px;
      width: 60px;
      z-index: 1;
      fill: $color-white;
      @include breakpoint($medium-up) {
        height: 70px;
        width: 70px;
      }
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
  .video-block-large__play-icon {
    transition: transform $transition-duration $transition-effect;
    backface-visibility: hidden;
    margin: 0 0 10px 0;
    .icon--play {
      @include breakpoint($medium-up) {
        height: 70px;
        width: 70px;
        fill: $color-white;
      }
    }
  }
  &:hover {
    .video-block-large__play-icon {
      @include transform(scale(1.2));
    }
  }
  .video-youtube {
    text-align: center;
  }
  &.video-block-large--play-black {
    .icon--play {
      @include breakpoint($medium-up) {
        fill: $color-black;
      }
    }
  }
  &.video-block-large--play-gray {
    .icon--play {
      @include breakpoint($medium-up) {
        fill: $color-gray;
      }
    }
  }
}
