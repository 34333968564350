@mixin button {
  @include font-smoothing;
  @include font-inter-medium;
  background: $color-orange;
  color: $color-white;
  border: 1px solid $color-orange;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  border-radius: 0;
  padding: 13px 60px 9px 60px;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: background $transition-duration $transition-effect,
    color $transition-duration $transition-effect,
    border-color $transition-duration $transition-effect;
  &:hover {
    background: $color-orange;
    text-decoration: none;
    color: $color-white;
  }
  @include breakpoint($small-medium-only) {
    width: 100%;
    padding-#{$ldirection}: 0;
    padding-#{$rdirection}: 0;
  }
  @include breakpoint($medium-up) {
    padding: 13px 30px 9px 30px;
    width: 100%;
    max-width: 256px;
  }
}

%button {
  @include button;
  &--dark {
    color: $color-white;
    background: $color-black;
    border-color: $color-black;
    &:hover {
      color: $color-white;
      background: $color-black;
      border-color: $color-black;
    }
  }
  &--large {
    @include breakpoint($medium-up) {
      font-size: 12px;
    }
  }
}

%button-secondary {
  @include button;
  background: $color-white;
  color: $color-black;
  border-color: $color-black;
  &:hover {
    background: $color-white;
    color: $color-black;
    border-color: $color-black;
  }
}

// Standard Button
.button {
  @include button;
  &--dark {
    @extend %button--dark;
  }
  &--large {
    @extend %button--large;
  }
  &--transparent {
    background: transparent;
  }
  &--underline {
    display: inline-block;
    border: none;
    border-bottom: 1px solid $color-black;
    padding: 0;
    font-size: 14px;
    color: $color-black;
  }
  &--disabled {
    background: $color-light-gray;
    color: $color-white;
    border-color: $color-light-gray;
    cursor: default;
    &:hover {
      background: $color-light-gray;
      color: $color-white;
      border-color: $color-light-gray;
    }
  }
}

// Secondary Button with outline style
.button-secondary {
  @extend %button-secondary;
}

// Span 100% full width
.button,
.button-secondary {
  &--wide {
    width: 100%;
    max-width: 100%;
  }
}

///
/// Form Buttons
///
button,
input[type='submit'],
input[type='reset'] {
  @include appearance(none);
  @include button;
  border-radius: 0;
  border-width: 1px;
  vertical-align: middle;
  padding: 13px 60px 10px 60px;
  @include breakpoint($medium-up) {
    padding: 7px 30px 5px 30px;
  }
}

///
/// Links
///
@mixin link {
  @include tracking(20);
  @include font-inter-regular;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  color: $color-black;
  text-decoration: underline;
  text-transform: uppercase;
  padding-bottom: 0;
  transition: color $transition-duration $transition-effect,
    border-color $transition-duration $transition-effect;
  cursor: pointer;
  &:hover {
    color: $color-link-hover;
    border-color: $color-link-hover;
  }
}
%link {
  @include link;
}
.link {
  @include link;
  &--inverted {
    @include font-smoothing;
    color: $color-white;
    border-color: $color-white;
    &:hover {
      color: $color-white;
    }
  }
}

// close button with x icon inside an href
// assumes relatively positioned parent
.button--close,
a.button--close,
input[type='submit'].button--close {
  background: transparent;
  position: absolute;
  z-index: 2;
  #{$rdirection}: 1em;
  top: 1em;
  text-decoration: none;
  width: 1.4em;
  height: 1.4em;
  border: $border;
  .icon {
    color: $black;
    height: 1.4em;
  }
}
