.about__page__hero {
  .content-block-large__inner {
    @include breakpoint($medium-up) {
      padding-bottom: 10px;
    }
  }
}

.about__page__bio {
  .content-block-large__content {
    .content-block-large__content-text {
      padding: 0 30px 0 30px;
    }
  }
}

.about__page__video {
  .content-block-large__inner {
    @include breakpoint($medium-up) {
      padding-bottom: 0;
    }
  }
}

.about__page__stores__header {
  @include h3;
}

.section-about {
  .about__page {
    &__hero,
    &__perfumers,
    &__perfumers__header {
      max-width: 1200px;
      margin: 0 auto;
      width: 95%;
    }
  }
}

.about {
  &__product-content {
    .content-block-large {
      &__content-text__header {
        font-style: normal;
        font-size: 20px;
        margin-bottom: 0px;
        line-height: 30px;
      }
    }
  }
  &__perfumers-content {
    .content-block-large {
      &__content-text__header {
        line-height: 30px;
      }
    }
  }
  &__content-font {
    font-family: $font-japnesh-reimin;
  }
}

.trust_mark_header {
  &__link {
    @include breakpoint($medium-up) {
      background-image: url(/media/export/cms/jp_about_page/stamp.png);
      background-repeat: no-repeat;
      background-position: 25px 25px;
    }
    .content-block-large {
      &__content-text__header {
        font-family: $font-japnesh-reimin;
      }
    }
  }
}
.about__jp_product_header {
  font-family: $font-inter-regular;
}
