.perfume-filter {
  display: inline-block;
  margin: 10px 0px 10px 0px;
  width: 186px;
  min-width: 186px;
  vertical-align: top;
  label {
    text-transform: capitalize;
  }
  span {
    width: 85%;
    font-family: $font-inter-regular;
    font-size: 12px;
  }
  .label ::before {
    float: #{$ldirection};
    line-height: inherit;
    margin-top: -3px;
    margin-#{$ldirection}: 0;
    margin-#{$rdirection}: 10px;
  }
}

.filter-list-mpp .perfume-filter input[type='checkbox'],
.filter-list .perfume-filter input[type='checkbox'] {
  #{$ldirection}: auto;
  opacity: 0;
}
.no-results {
  @include swap-direction(margin, 12px 0 0 16px);
  text-transform: uppercase;
}

.filter-text {
  @include swap-direction(margin, 16px 10px 0 0);
  text-transform: initial;
  font-family: $font-inter-regular;
  font-size: 12px;
  font-weight: 300;
}

.perfume-filter-section {
  @include swap-direction(margin, 0 0 0 15px);
  color: inherit;
}
.browse-all,
.all-fpo {
  @include swap-direction(margin, 25px 10px 25px 16px);
  font-family: $font-inter-regular;
  font-size: 12px;
  font-weight: 300;
  text-decoration: underline;
  line-height: 0;
  a {
    color: inherit;
  }
}

.category-text {
  text-transform: none;
  font-size: 12px;
  font-weight: 300;
  margin: 12px 0 0 16px;
  font-family: $font-inter-regular;
  a {
    color: $color-white;
  }
}
.mpp {
  .category-text {
    a {
      color: $color-black;
    }
  }
}
.perfume-menu-wrapper {
  -webkit-animation: mymove 3s;
  -webkit-animation-fill-mode: forwards;
  animation: mymove 3s;
  animation-fill-mode: forwards;
  .menu__item {
    min-height: 86px;
  }
}

@-webkit-keyframes mymove {
  from {
    top: 0px;
  }

  to {
    top: 200px;
  }
}
@keyframes mymove {
  from {
    top: 0px;
  }

  to {
    top: 200px;
  }
}
@media only screen and (max-height: 670px) {
  .gnav-perfume-dropdown {
    overflow: scroll !important;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
}
@media only screen and (max-width: 768px) {
  .perfume-filter-section .filter-text,
  #filter-list,
  .browse-all,
  .all-fpo,
  .filter-seperator {
    display: none;
  }
  .perfume-menu-wrapper .menu__item {
    min-height: auto;
  }
  .mpp-filter-seperator {
    margin-bottom: 20px !important;
  }
  .product-quickshop-wrapper {
    display: inline !important;
  }
  .perfume-filer-mpp .filter-text {
    @include swap-direction(margin, 20px 10px 16px 0);
  }
}
.product-grid .perfume-fragrance:nth-child(4n + 1) {
  clear: #{$rdirection};
}
.perfume_filter_hide .perfume-filter-wrapper {
  display: none;
}
@media only screen and (min-width: 768px) {
  .perfume-filer-mpp .filter-text {
    margin-top: 31px !important;
    margin-bottom: 18px;
  }
  .mpp-container .mpp__category-name {
    margin-bottom: 0%;
  }
}
@media only screen and (max-width: 380px) {
  .perfume-filter {
    width: 50%;
    min-width: inherit;
  }
  .perfume-filter span {
    float: none;
    display: table-row;
  }
  .mpp-filter-seperator {
    margin-bottom: 20px !important;
  }
}
@media only screen and (min-width: 1024px) {
  .js-fragrance-type {
    height: 10px;
  }
  .perfume-menu-wrapper {
    display: none;
  }
  .mpp-perfume-message {
    @include swap-direction(margin, 0 0 0 15px);
  }
  .mpp-perfume-message {
    .category-text,
    .no-results {
      margin: 12px 0 0 0px;
    }
    .all-fpo,
    .browse-all {
      @include swap-direction(margin, 25px 10px 25px 0);
    }
  }
}
@media only screen and (min-width: 1025px) {
  .perfume-filter-section.perfume-filer-mpp,
  .mpp-perfume-message {
    margin: 0 auto;
    width: 87.5%;
  }
}
@media only screen and (min-width: 768px), (min-width: 320px) {
  .product-quickshop-wrapper {
    display: inline !important;
  }
}
.mpp-container .perfume-filter-section .filter-text,
.mpp-container .browse-all,
.mpp-container .all-fpo {
  display: block;
}
.mpp-filter-seperator {
  height: 45px;
  margin-bottom: 8px;
  border-bottom: 1px solid rgb(220, 220, 220);
  margin: 0 auto;
  width: 80%;
}
.filter-seperator {
  height: 8px;
  margin-bottom: 8px;
}
.perfume-filer-mpp {
  .filter-text,
  .perfume-filter span {
    font-size: 16px;
  }
}
.gnav-dropdown-formatter {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
  padding: 50px 0;
  margin: auto;
  max-width: 1320px;
  &__content {
    display: flex;
    flex-flow: row wrap;
    &-item {
      margin: 0;
      display: block;
      width: 15%;
      &:nth-child(3n + 2) {
        border-#{$ldirection}: 1px solid $color-white;
        border-#{$rdirection}: 1px solid $color-white;
        width: 50%;
        padding: 0 3%;
      }
    }
    &-perfume {
      width: 32%;
      margin-#{$ldirection}: 3%;
      @if $text-direction == 'rtl' {
        margin-#{$rdirection}: auto;
      }
    }
  }
}
