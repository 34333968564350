// Colorbox override
.overlay--video {
  @include transform(translateY(-100%));
  @include transition(transform 1.4s);
  &.active {
    @include transform(translateY(0));
  }
  #cboxContent {
    background-color: $black;
    margin-top: 0;
  }
  #cboxClose {
    min-width: auto;
    #{$rdirection}: 10px;
    @include breakpoint($portrait-up) {
      #{$rdirection}: 19px;
    }
    &:before {
      color: $color-white;
      font-size: 22px;
      font-weight: 700;
      @include breakpoint($landscape-up) {
        font-size: 32px;
      }
    }
  }
  iframe {
    display: block;
  }
  video {
    @include transform(translateY(-50%));
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    top: 50%;
  }
}
