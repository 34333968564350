.cart-item {
  @include pie-clearfix;
  display: block;
  text-align: #{$ldirection};
  color: $color-black;
  border-bottom: $border-grey;
  &:hover {
    color: $color-black;
  }
  // applies to shopping bag in gnav
  .cart-block & {
    font-size: 11px;
    line-height: 15px;
    @include breakpoint($medium-up) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  // Layout:
  .cart-item__img,
  .cart-item__info,
  .cart-item__cart-details,
  .cart-item__qty,
  .cart-item__price {
    float: #{$ldirection};
    display: block;
  }

  .cart-item__img {
    width: 25%;
    margin-#{$rdirection}: 2%;
    @if $text-direction == 'rtl' {
      margin-#{$ldirection}: auto;
    }
  }

  .cart-item__info {
    width: 40%;
    margin-#{$rdirection}: 2%;
    @if $text-direction == 'rtl' {
      margin-#{$ldirection}: auto;
    }
  }

  .cart-item__cart-details {
    width: 31%;
    text-align: #{$rdirection};
    margin-top: 1em;
  }

  // Styles:
  .cart-item__info__header {
    margin-top: 1em;
  }

  .cart-item__name,
  .cart-item__sku-name {
    @include tracking(20);
    @include font-inter-medium;
    text-transform: uppercase;
  }

  .cart-item__sku-label,
  .cart-item__shade,
  .cart-item__personalization-details {
    @include tracking(20);
    margin: 8px 0 0 0;
  }

  .cart-item__title {
    font-style: italic;
  }

  .cart-item__qty {
    text-transform: uppercase;
    text-align: #{$ldirection};
  }

  .cart-item__price {
    @include tracking(20);
    @include font-inter-medium;
    color: $color-black;
  }

  .cart-item__qty,
  .cart-item__price {
    float: #{$ldirection};
    width: 50%;
  }

  &.cart-item-kit {
    a.cart-item {
      padding-#{$ldirection}: 0;
    }
    .cart-item__price {
      float: #{$rdirection};
    }

    .cart-item-kit__subitem-container {
      display: inline-block;
      padding-top: 1em;
      width: 73%;

      .cart-item-kit__subitem {
        padding: 1px 0;
        font-size: 90%;
        @include font-inter-regular;
        .cart-subitem__name,
        .cart-subitem__details {
          text-transform: uppercase;
          display: inline-block;
        }
        .cart-subitem__name {
          width: 70%;
        }
        .cart-subitem__details {
          width: 28%;
          text-align: #{$rdirection};
          float: #{$rdirection};
        }
      }
    }
  } //.cart-item-kit
}
