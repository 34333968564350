///
/// customer service internal page
///
.cs-page {
  overflow: hidden;
  border-bottom: 0;
  padding-bottom: 50px;
  max-width: $max-width-large;
  margin: auto;
  @include breakpoint($medium-up) {
    border-bottom: 0;
    padding-bottom: 50px;
  }
  @include breakpoint($tablet-only) {
    width: 100%;
  }
}

.cs-page__title {
  text-align: center;

  .heading--1 {
    margin: 3.5% auto 64px;
    @include breakpoint($medium-up) {
      text-align: center;
    }
  }
}
.cs-section_header {
  font-size: 25px;
  text-align: #{$ldirection};
  padding-bottom: 10px;
}

.cs-page__content {
  @include breakpoint($medium-up) {
    @include swap-direction(padding, 16px 60px 0 90px);
    float: #{$rdirection};
    width: 75%;
  }
  @include breakpoint($tablet-only) {
    padding-#{$ldirection}: 20px;
  }
  h2 {
    @include font-inter-medium;
    text-transform: uppercase;
    font-size: 0.95em;
    letter-spacing: 0.01em;
    @include breakpoint($medium-up) {
      margin-bottom: 8px;
    }
  }
}

.cs-page__navigation {
  @include tracking(35);
  @include font-inter-medium;
  display: none;
  margin: 0;
  border: 0;
  padding-top: 10px;
  letter-spacing: 0.035em;
  line-height: 2.5em;
  @include breakpoint($medium-up) {
    @include swap-direction(padding, 10px 0 10px 40px);
    float: #{$ldirection};
    width: 25%;
    display: block;
  }

  ul,
  li {
    list-style: none;
    line-height: 1.4em;
    padding: 0.35em 0;
    @include breakpoint($medium-up) {
      padding-top: 0;
    }
  }

  .cs-menu__heading,
  .cs-menu__static,
  .cs-menu__parent {
    margin-bottom: 24px;
    padding-bottom: 0;
  }

  h3 {
    @include font-smoothing;
    @include font-inter-regular;
    margin: 0;
    font-size: 22px;
    letter-spacing: 0.5px;
    line-height: 1.6;
    font-weight: normal;
    a {
      font-size: inherit;
    }
  }

  a {
    font-family: inherit;
    border: 0;
    text-decoration: none;
    border-bottom: 1px solid $color-black;
    font-size: 0.85em;
    letter-spacing: 0.03em;
    @include tracking(30);
  }

  .cs-menu__children {
    a {
      @include font-smoothing;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 1.6;
      font-weight: normal;
    }
  }
}

.cs-page__footer {
  position: relative;
}

///
/// CS expanding menu
///
h3.cs-menu__heading {
  margin-top: 3px;
}

.cs-menu__children {
  line-height: 2em;
}

.cs-menu__static a {
  text-decoration: none;
  border: 0;
}

.cs-section {
  margin: 0 0 25px 0;
  h3 {
    margin: 0 0 7px 0;
    @include font-inter-medium;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }
  p {
    font-size: 16px;
  }
  ul {
    @include swap-direction(margin, 0 0 20px 20px);
  }
  ul li {
    list-style-type: disc;
  }
}

.faq_section {
  border-top: 1px solid $color-light-gray;
  margin: 0 auto;
}

// Customer service / FAQs
.cs-section {
  &.faq-active {
    .faq__question {
      &:after {
        content: '';
        background: url(/sites/fredericmalle/themes/fredericmalle_base/img/icons/src/caret--up.svg)
          0px 0px/12px no-repeat;
        height: 12px;
        width: 12px;
      }
    }
  }
  .faq__question {
    @include font-inter-regular;
    @include font-smoothing;
    cursor: pointer;
    position: relative;
    font-size: 17px;
    letter-spacing: 0.5px;
    line-height: 1.6;
    font-weight: normal;
    margin: 15px 0 9px 0;
    text-transform: uppercase;
    word-spacing: 4px;
    &:after {
      float: #{$rdirection};
      margin-top: 5px;
      content: '';
      background: url(/sites/fredericmalle/themes/fredericmalle_base/img/icons/src/caret--down.svg)
        0px 0px/12px no-repeat;
      height: 12px;
      width: 12px;
    }
  }
  // shared pseudo styles
  &:after,
  .faq-active &:after {
    content: '';
    position: absolute;
    top: 50%;
  }
  .faq-active & {
    font-weight: bold;
    .faq__answer {
      display: block;
    }
  }
}
.faq__answer {
  display: none;
  .faq-active & {
    display: block;
    font-size: 17px;
    text-align: justify;
  }

  p {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.5;
  }
}
// Email Unsubscribe
.email-unsubscribe,
.unsubscribe_thanks {
  text-align: center;
  padding: 20px;
  h1.unsubscribe-header {
    margin: 0 auto 35px;
    width: 230px;
    font-size: 24px;
    line-height: 1.5em;
    border-bottom: 1px solid #000;
    @include breakpoint($medium-up) {
      width: 350px;
      font-size: 37px;
      border-width: 2px;
    }
  }
}
.email-unsubscribe {
  .adpl input[type='text'] {
    width: 100%;
    @include breakpoint($medium-up) {
      width: 50%;
    }
  }
}
.unsubscribe_thanks {
  .social-links {
    margin: 2em auto;
    display: inline-block;
    li {
      font-size: 30px;
      margin: 0 20px;
      float: #{$ldirection};
      a {
        text-decoration: none;
      }
    }
  }
}
.account-wrapper {
  margin-top: -8%;
}
.account-requirement {
  text-align: center;
  margin-top: 50px;
  .title {
    font-size: 23px;
    padding-bottom: 27px;
    font-weight: 400;
    @include breakpoint($medium-up) {
      font-size: 40px;
      padding-bottom: 35px;
    }
  }
  .disclaimer {
    display: block;
    font-size: 13px;
    margin-bottom: 14px;
  }
  .button {
    display: block;
    margin: 8px auto;
    max-width: 261px;
  }
  .sign-in-afterpay {
    display: block;
    margin-top: 20px;
    font-size: 13px;
    a {
      display: block;
      margin-top: 4px;
      @include breakpoint($medium-up) {
        display: inline-block;
        margin-top: 0;
      }
    }
  }
}
.afterpay {
  &-faq {
    font-size: 25px;
    margin-bottom: 32px;
    margin-top: 37px;
    text-align: center;
    @include breakpoint($medium-up) {
      font-size: 40px;
    }
    .description {
      font-size: 19px;
      margin-top: 13px;
    }
    &-title {
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 4px;
      margin-top: 10px;
      text-align: center;
      @include breakpoint($medium-up) {
        font-size: 40px;
        margin: 25px 0;
      }
    }
    &-content {
      margin-top: 10px;
      text-align: center;
      p {
        margin-bottom: 1px;
        @include breakpoint($medium-up) {
          margin: auto;
          width: 355px;
        }
      }
    }
    &-header {
      font-weight: 500;
      margin-top: 5px;
      text-align: center;
    }
    &-section {
      .cs-page {
        padding-bottom: 0;
        &__content {
          float: none;
          width: 100%;
        }
        &__navigation {
          display: none;
        }
      }
      .collapsible-block {
        padding-bottom: 0;
        &__title {
          font-size: 17px;
          letter-spacing: 0.5px;
          padding-#{$rdirection}: 20px;
        }
        &--normal {
          .icon {
            &::after {
              background: url('/sites/fredericmalle/themes/fredericmalle_base/img/icons/src/caret--down.svg')
                0 0/12px no-repeat;
              content: '' !important;
              float: $rdirection;
              height: 12px;
              margin-top: 5px;
              position: absolute;
              width: 12px;
            }
          }
          &--alt {
            .icon {
              &::after {
                background: url('/sites/fredericmalle/themes/fredericmalle_base/img/icons/src/caret--up.svg')
                  0 0/12px no-repeat;
              }
            }
          }
        }
      }
    }
  }
  &-service {
    text-align: center;
    @include breakpoint($medium-up) {
      padding-#{$ldirection}: 7%;
    }
  }
  &-works {
    margin-top: 15px;
    @include breakpoint($medium-up) {
      display: inline-block;
      margin-#{$rdirection}: 60px;
    }
    .image-wrapper {
      text-align: center;
    }
  }
  &-questions {
    margin-#{$ldirection}: 15px;
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: 37px;
    }
  }
}
