///////////////////////////////
/// HERO LARGE
/// bg image dictates container height
/// uses generic font and size classes
///////////////////////////////
.hero-large {
  cursor: pointer;
  height: auto;
  position: relative;
  width: 100%;
  &.hero-large--color-light {
    background-color: $color-black;
  }
  &__inner {
    height: 100%;
    position: absolute;
    width: 100%;
    @include breakpoint($landscape-up) {
      z-index: 3;
      top: 0;
      bottom: auto;
      // content widths
      &.hero-large__inner-width--20 {
        width: 20%;
      }
      &.hero-large__inner-width--30 {
        width: 30%;
      }
      &.hero-large__inner-width--40 {
        width: 40%;
      }
      &.hero-large__inner-width--50 {
        width: 50%;
      }
      &.hero-large__inner-width--60 {
        width: 60%;
      }
      &.hero-large__inner-width--70 {
        width: 70%;
      }
      &.hero-large__inner-width--80 {
        width: 80%;
      }
      &.hero-large__inner-width--90 {
        width: 90%;
      }

      // horizontal position
      &-hpos--center {
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
      &-hpos--left {
        #{$ldirection}: 0;
        // margin details
        &.hero-large__inner-margin--50 {
          #{$ldirection}: 50%;
        }
        &.hero-large__inner-margin--40 {
          #{$ldirection}: 40%;
        }
        &.hero-large__inner-margin--30 {
          #{$ldirection}: 30%;
        }
        &.hero-large__inner-margin--20 {
          #{$ldirection}: 20%;
        }
        &.hero-large__inner-margin--10 {
          #{$ldirection}: 10%;
        }
      }
      &-hpos--right {
        #{$rdirection}: 0;
        // margin details
        &.hero-large__inner-margin--50 {
          #{$rdirection}: 50%;
        }
        &.hero-large__inner-margin--40 {
          #{$rdirection}: 40%;
        }
        &.hero-large__inner-margin--30 {
          #{$rdirection}: 30%;
        }
        &.hero-large__inner-margin--20 {
          #{$rdirection}: 20%;
        }
        &.hero-large__inner-margin--10 {
          #{$rdirection}: 10%;
        }
      }
    }
  }

  &__content {
    padding: 1em;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
    &-padding-15 {
      padding: 15px;
    }
    &-padding-30 {
      padding: 30px;
    }
    &-padding-45 {
      padding: 45px;
    }
    .font--small {
      top: 3px;
    }
    .font--medium {
      top: 6px;
    }
    .font--large {
      top: 8px;
      @include breakpoint($medium-up) {
        top: 17px;
      }
    }
  }
  &__text {
    line-height: 1;
    .hero-large--color-light & {
      color: $color-white;
    }
    p {
      margin: 0;
    }
    &-vpos--top {
      grid-column-start: 1;
      grid-row-end: 2;
      align-self: start;
    }
    &-vpos--middle {
      grid-column-start: 1;
      grid-row-end: 3;
    }
    &-vpos--bottom {
      grid-column-start: 1;
      grid-row-end: 4;
      align-self: end;
      position: relative;
    }
  }
  &__cta {
  }
  &__image,
  &__image img {
    width: 100%;
  }
  &__image img {
    float: #{$ldirection};
  }
}
