$basic-grid-gutter: 12px;
.basic-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include breakpoint($medium-up) {
    @include swap-direction(padding, 0 12px 74px 0);
  }
  &__item {
    width: 100%;
    .basic-grid--small-cols-2 &,
    .basic-grid--small-cols-3 &,
    .basic-grid--small-cols-4 & {
      @include swap-direction(padding, 0 0 $basic-grid-gutter $basic-grid-gutter);
    }
    &--padding-15 {
      padding: 15px;
    }
    &--padding-30 {
      padding: 30px;
    }
    .basic-grid--small-cols-2 & {
      width: 50%;
    }
    .basic-grid--small-cols-3 & {
      width: 33.333%;
    }
    .basic-grid--small-cols-4 & {
      width: 25%;
    }
    @include breakpoint($medium-up) {
      @include swap-direction(padding, 0 0 $basic-grid-gutter $basic-grid-gutter);
      width: 33.333%;
      .basic-grid--medium-cols-1 & {
        float: none;
        width: auto;
        padding: 0 0 $basic-grid-gutter;
      }
      .basic-grid--medium-cols-2 & {
        width: 50%;
      }
      .basic-grid--medium-cols-3 & {
        width: 33.333%;
      }
      .basic-grid--medium-cols-4 & {
        width: 25%;
      }
      .basic-grid--medium-cols-5 & {
        width: 20%;
      }
      .basic-grid--medium-cols-6 & {
        width: 16.666%;
      }
    }
    @include breakpoint($large-up) {
      @include swap-direction(padding, 0 0 $basic-grid-gutter $basic-grid-gutter);
      width: 33.333%;
      .basic-grid--large-cols-1 & {
        float: none;
        width: auto;
        padding: 0 0 $basic-grid-gutter;
      }
      .basic-grid--large-cols-2 & {
        width: 50%;
      }
      .basic-grid--large-cols-3 & {
        width: 33.333%;
      }
      .basic-grid--large-cols-4 & {
        width: 25%;
      }
      .basic-grid--large-cols-5 & {
        width: 20%;
      }
      .basic-grid--large-cols-6 & {
        width: 16.666%;
      }
    }
    @include breakpoint($medium-up) {
      &--padding-15 {
        padding: 15px;
      }
      &--padding-30 {
        padding: 30px;
      }
    }
  }
  &__header {
    margin: 0 0 20px 0;
  }
  &__subheader {
    margin: 0 0 20px 0;
  }
}
