///
/// @file components/prouducts/_mpp.scss
///
/// \brief Styles for multi-product pages
///
///

.mpp-container {
  margin: 0;
  padding: 0;
  @include breakpoint($medium-up) {
    padding: 0;
  }

  .mpp__category-name {
    display: block;
    text-align: center;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      margin-bottom: 2%;
    }

    h1 {
      display: inline-block;
      margin: 0;
      padding: 3.2% 5% 2.8%;
    }
  }

  .product-full__breadcrumb {
    padding: 20px 0;
  }
}

.mpp-filter__container {
  margin: 10px;
}

.mpp-header-tout {
  display: none;

  @include breakpoint($medium-up) {
    display: block;
    padding: 0 0 48px; // default vertical spacing
    position: relative;

    &:before {
      border-bottom: 1px solid #000;
      position: absolute;
      #{$ldirection}: 50%;
      bottom: 0;
      content: ' ';
      width: 80%;
      transform: translateX(-50%);
      z-index: 100;
    }
  }

  .mpp-header-tout__alignment-center {
    margin: 0 auto;
  }
  .mpp-header-tout__alignment-right {
    float: #{$rdirection};
  }
  // content widths
  .mpp-header-tout__width-50 {
    width: 50%;
  }
  .mpp-header-tout__width-60 {
    width: 60%;
  }
  .mpp-header-tout__width-70 {
    width: 70%;
  }
  .mpp-header-tout__width-80 {
    width: 80%;
  }
  .mpp-header-tout__width-90 {
    width: 90%;
  }

  // extra vertical framing space
  .mpp-header-tout__padding-vertical-10 {
    padding: 10px 0;
  }
  .mpp-header-tout__padding-vertical-20 {
    padding: 20px 0;
  }
  .mpp-header-tout__padding-vertical-30 {
    padding: 30px 0;
  }
  .mpp-header-tout__padding-vertical-40 {
    padding: 40px 0;
  }
  .mpp-header-tout__padding-vertical-50 {
    padding: 50px 0;
  }
} //.mpp-header-tout

// mpp header tout: inline version
.mpp-header-tout--inline {
  overflow: hidden;
  padding-bottom: 0;

  &:before {
    display: none;
  }

  .hero-large__image picture img {
    width: auto;
    height: 100%;
    max-width: none;
  }

  .hero-large__inner {
    position: absolute;
    z-index: 3;
    top: 12px;
    bottom: auto;
  }
  .hero-large__content {
    padding: 0;
  }
}
.product-grid--has-header-tout {
  .product-grid__item--tout {
    display: none;
    @include breakpoint($medium-up) {
      display: inline-block;
    }
    &.mpp-header-tout__alignment-left {
      float: #{$ldirection};
    }
    &.mpp-header-tout__alignment-right {
      float: #{$rdirection};
    }
  }

  &.product-grid--medium-cols-4 {
    .product-grid__item.product-grid__item--tout {
      @include breakpoint($medium-up) {
        &.product-grid__item--colspan-2,
        &.product-grid__item--colspan-3,
        &.product-grid__item--colspan-4 {
          //margin-top: 38px;
        }
      }
    }
  }
}

.mpp__header--body-wash {
  .content-block-large__content-text {
    padding-top: 20px;
    @include breakpoint($medium-up) {
      padding-top: 75px;
    }
  }
}
