.product-brief {
  .product-brief__subline {
    display: block;
  }
  .product-brief__sku-shade-swatch {
    display: inline-block;
    margin-#{$rdirection}: 0.5em;
    padding: 0.75em;
  }
  &__price {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 16px 8px;
  }
}

.product-brief__container {
  text-align: center;
  position: relative;
  .product-brief__description-container {
    margin: 0 auto;
    width: 95.2%;
    @include breakpoint($landscape-up) {
      width: 85%;
    }
  }
  .product-flag__container {
    font-family: $font-inter-regular;
    text-align: center;
    font-size: 12px;
    color: $color-orange;
    margin-top: 5px;
    height: 25px;
  }
  .product-brief__name {
    a {
      @include product-name;
    }
  }

  .product-brief__subline {
    @include product-subline;
  }

  .product__image-link {
    display: inline-block;
    border: none;
    position: relative;

    .product__image {
      max-width: 256px;
      max-height: 315px;
      display: block;
      width: 100%;
      .spp-cross-sells__carousel & {
        max-width: 268px;
        max-height: 327px;
      }

      &.product__image--alt {
        position: absolute;
        top: 0;
        padding: 2em 0;
        background: $color-white;
        display: none;
      }
    }

    &.product__image-link--alt {
      .product__image.product__image--alt {
        display: block;
      }
    }
  }

  .product-brief__sku-size {
    display: none; /* Hide Sizes */
  }

  .product-brief__quickshop-launch-wrapper {
    display: none; /* Hidden on Mobile */

    @include breakpoint($medium-up) {
      display: block;
      position: absolute;
      top: 50%;
      width: 100%;
      pointer-events: none;
      font-family: $font-inter-medium;
    }

    .product-brief__quickshop-launch {
      display: none; /* Hidden on Mobile */

      &.qs-active {
        display: none; /* Hidden on Mobile */

        @include breakpoint($medium-up) {
          display: inline-block;
          position: relative;
          text-align: center;
          border: 1px solid $color-gray;
          padding: 3% 5%;
          margin: auto 0;
          background-color: $color-white;
          color: $color-black;
          text-transform: uppercase;
          pointer-events: visible;
          text-decoration: none;
        }
      }
    }
  }
}

.product__inventory-status {
  font-size: 13px;
}
