@include breakpoint($medium-up) {
  .gnav-util__content--cart {
    width: 37em;
  }
}

@mixin cart_block_footer_button {
  padding-#{$ldirection}: 0;
  padding-#{$rdirection}: 0;
  @include breakpoint($medium-up) {
    @include swap-direction(margin, 1px 0 0 1px);
    min-width: 0;
  }
}

.cart-block {
  padding: 20px 10px;
  width: 100%;
  border-bottom: 1px solid $color-black;
  @include font-inter-regular;

  @include breakpoint($medium-up) {
    border: 1px solid $color-black;
    padding-bottom: 15px;
  }

  &__offer {
    color: $color-black;
    font-size: 16px;
    margin-top: 10px;
  }

  .cart-block__close {
    position: absolute;
    top: 0;
    #{$rdirection}: 0;
    cursor: pointer;
    padding: 0.25em;
  }

  .cart-block__header {
    @include pie-clearfix;
    border-bottom: solid 1px $color-black;
    padding: 0 10px 15px 10px;
    margin: 0 0 10px 0;
    @include breakpoint($large-up) {
      border-color: $color-black;
    }

    .cart-block__header__item-count {
      float: #{$rdirection};
    }

    .cart-block__header__edit-link {
      @include font-inter-medium;
      float: #{$rdirection};
      margin-#{$rdirection}: 3.5em;
      text-decoration: underline;
      text-transform: uppercase;
      font-size: 1.4em;
      display: none;

      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
      }
    }
  }

  .cart-block__title {
    float: #{$ldirection};
    margin: 0;
    padding: 0;
    font-size: 18px;
    text-transform: uppercase;
    @include font-inter-medium;

    .cart-block__title__text {
      display: none;
    }
    .cart-block__title__text--empty {
      display: inline-block;
      .cart-block--nonempty &,
      .cart-block--added & {
        display: none;
      }
    }

    .cart-block__title__text--nonempty {
      .cart-block--nonempty & {
        display: inline-block;
      }
    }

    .cart-block__title__text--added {
      .cart-block--added & {
        display: inline-block;
      }
    }
  }

  .cart-block__item-count {
    color: $color-dark-gray;
    font-size: 1.25em;
    display: none;
  }

  .cart-block--singular .cart-block__item-count--singular {
    display: block;
  }

  .cart-block--plural .cart-block__item-count--plural {
    display: block;
  }

  .cart-block__items--new {
    display: none;

    // Commented out for now as this is duplicating content on UK and US - FREDMALLE-224. DH
    // .cart-block--added & {
    //   display: block;
    // }
  }

  .cart-block__items--all {
    max-height: 200px;
    overflow: auto;
    display: none;
    .cart-block--added &,
    .cart-block--nonempty & {
      display: block;
      .cart-item {
        border-bottom: none;
        padding: 0 10px 5px;
        &.cart-item__subheading,
        &.cart-item__sku-label,
        &.cart-item__title,
        &.cart-item__qty,
        &.cart-item__price {
          font-size: 12px;
          line-height: 1.4;
          @include breakpoint($medium-up) {
            font-size: 14px;
          }
        }
        &.cart-item__title {
          line-height: 1.2;
          margin-top: 2px;
        }
        &.cart-item__sku-label {
          font-size: 11px;
          margin-top: 5px;
          @include breakpoint($medium-up) {
            margin-top: 10px;
          }
        }
        &.cart-item__info {
          margin-top: 3px;
          padding-#{$rdirection}: 10px;
        }
        &.cart-item__cart-details {
          margin-top: 3px;
        }
      }

      .cart-item-kit {
        a.cart-item {
          padding: 0;
        }
      }
    }
  }

  .cart-block__items__view-bag {
    display: block;
    padding: 1em 0 2em;
  }

  .cart-block__footer {
    margin-top: 1.5em;
    padding: 0 10px;
    .cart-block--nonempty & {
      overflow: hidden;
      border-top: 1px solid $color-black;
    }
    .cart-block--added & {
      margin-top: 0;
      border-top: 1px solid $color-black;
    }

    .cart-block__footer__summary {
      @include pie-clearfix;
      display: none;
      margin: 0 0 1em;
      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
      }
    }

    .cart-block__footer__item-count {
      float: #{$ldirection};
      // Intentionally supressing item count in footer, see ORNF-122
      display: none;

      .cart-block--plural & {
        display: none;
      }
    }

    .cart-block__footer__subtotal {
      @include tracking(20);
      @include font-inter-medium;
      font-size: 14px;
      color: $color-black;
      display: block;
      margin: 20px 0 0 0;
      overflow: hidden;
      @include breakpoint($medium-up) {
        margin-top: 15px;
      }
      .cart-block__footer__subtotal__label {
        float: #{$ldirection};
        width: 72%;
        text-align: #{$rdirection};
        text-transform: uppercase;
      }
      .cart-block__footer__subtotal__value {
        float: #{$rdirection};
      }
      @include breakpoint($large-up) {
        margin: 9px 0 0;
        padding-top: 0.5em;
      }
      .cart-block--added & {
        display: none;
      }
    }

    .cart-block__footer__button {
      width: 100%;
      margin-top: 1em;
      @include breakpoint($medium-up) {
        width: 81%;
        margin-#{$ldirection}: 31px;
      }
    }

    .cart-block__footer__button--account {
      .cart-block--added &,
      .cart-block--nonempty & {
        float: #{$ldirection};
        width: 48%;
        margin-top: 1em;
        @include cart_block_footer_button();
      }
    }

    .cart-block__footer__button--checkout {
      display: none;
      margin-top: 1em;
      @include button--dark;
      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
      }
      .cart-block--added &,
      .cart-block--nonempty & {
        float: #{$rdirection};
        width: 49%;
        @include cart_block_footer_button();
      }
    }

    .cart-block__footer__button--continue-shopping {
      .cart-block--nonempty &,
      .cart-block--added & {
        display: none;
      }
    }
  }

  .cart-block__subfooter-content {
    margin: 10px 0 0 0;
    color: $color-black;
    text-align: center;
    font-size: 14px;
    @include font-smoothing;
    p {
      margin: 0;
    }
    @include breakpoint($medium-up) {
      font-size: 16px;
    }
  }
}

.gnav-util--cart--empty {
  .cart-block {
    .cart-block__title__text--empty {
      color: $color-black;
      text-transform: uppercase;
    }
    .cart-block__footer {
      text-align: center;
    }
  }
}
