.basic-noderef--formatted {
  position: relative;

  &.top-left {
    .basic-noderef__image {
      top: 0;
      #{$ldirection}: 0;
    }
  }
  &.center-left {
    .basic-noderef__image {
      top: 50%;
      transform: translateY(-50%);
      #{$ldirection}: 0;
    }
  }
  &.bottom-left {
    .basic-noderef__image {
      bottom: 0;
      #{$ldirection}: 0;
    }
  }

  &.top-right {
    .basic-noderef__image {
      top: 0;
      #{$rdirection}: 0;
    }
  }
  &.center-right {
    .basic-noderef__image {
      top: 50%;
      transform: translateY(-50%);
      #{$rdirection}: 0;
    }
  }
  &.bottom-right {
    .basic-noderef__image {
      bottom: 0;
      #{$rdirection}: 0;
    }
  }

  .basic-noderef__image {
    position: absolute;
    z-index: 3;
    max-width: 20%;
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      max-width: 20%;
    }
    @include breakpoint($medium-up) {
      max-width: 25%;
    }
  }
}

.section-perfumer {
  .basic-noderef__image {
    position: absolute;
    z-index: 3;
    max-width: 20%;
    display: none;
    @include breakpoint(1400px) {
      max-width: 10%;
      display: block;
    }
    @include breakpoint(1600px) {
      max-width: 12%;
      display: block;
    }
    @include breakpoint(1900px) {
      max-width: 25%;
    }
  }
}
