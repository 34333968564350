/* Click and Collect */

$color-medium-gray: #888;

.viewcart-buttons-panel .content .continue-buttons {
  .paypal-checkout {
    .pp_disabled {
      display: none;
    }
    &.disabled {
      cursor: default;
      color: $color-medium-gray;
      .pp_disabled {
        display: block;
        background-color: white;
        width: 180px;
        height: 50px;
        top: -57px;
        position: relative;
        opacity: 0.8;
      }
    }
  }
  .paypal_message {
    width: 100%;
    text-align: center;
  }
}

#checkout_shipmethod {
  padding-left: 155px;
  padding-right: 10.5%;
}

.local-collection-map {
  height: 100%;
  iframe {
    border: 0;
    width: 100%;
    height: 97%;
  }
}

#shipmethod-panel .ship-method-group {
  width: 100%;
}

.bottom-viewcart-buttons .content .viewcart-buttons-panel .continue-buttons {
  text-align: center;
  width: 100%;
}

.checkout__sidebar #shipmethod-panel {
  .ship-method-group-label {
    margin-left: 0px;
  }
  #checkout_shipmethod {
    padding: 0px;
  }
}

#shipmethod-panel {
  .ship-method-group-label {
    input {
      float: right;
      height: auto;
      margin-right: 5px;
      margin-left: 15px;
    }
    label {
      line-height: 1.2em;
    }
  }
  .ship-method-collection-group label {
    float: right;
    margin-top: 10px;
    clear: both;
  }
  .ship-method-group-label .shipclearfix {
    clear: both;
    padding: 2px;
  }
  .ship-method-home-group .date-list .form-item label {
    display: block;
  }
}

/* Click and Collect - v2 */
/* inline styles */

.map-container .address-map-location-panel .locations .messages {
  background-color: white;
  border: 0;
}

.store-collection-button,
.local-collection-button {
  width: 206px;
  cursor: pointer;
}

.map-container {
  .map {
    height: 300px;
  }
  .address-map-search-panel {
    font-size: 1.4em;
    * {
      box-sizing: border-box;
    }
    .search-box {
      overflow: hidden;
      width: 30em;
      label {
        display: block;
        font-size: inherit;
        margin: 0 0 1em;
        font-weight: bold;
      }
    }
    .search-box-field {
      width: 100%;
      font-size: inherit;
      margin: 0;
      height: 45px;
      -webkit-appearance: textfield;
      padding: 0;
    }
    .search-box-submit {
      width: 32%;
      font-size: inherit;
      margin-right: 2px;
      float: left;
      clear: none;
    }
  }
  .search-box-geolocate {
    width: 32%;
    font-size: inherit;
    margin-right: 2px;
    float: left;
    clear: none;
  }
  .address-map-search-panel {
    .search-box-cancel {
      width: 32%;
      font-size: inherit;
      margin-right: 2px;
      float: left;
      clear: none;
      i:before {
        padding-right: 0;
      }
    }
    .search-box-submit {
      margin-left: 0;
    }
    .status-bar-wrapper {
      width: 80%;
      margin-left: 10%;
      .status-bar .message {
        padding: 0.5em;
        border: 1px solid #aaa;
        box-shadow: 0px 1px 4px #333;
        &:first-child {
          border-radius: 5px 5px 0 0;
        }
        &:last-child {
          border-radius: 0 0 5px 5px;
        }
        .close {
          float: right;
          a {
            text-decoration: none;
            color: inherit;
          }
        }
        &.error {
          background: #fed2024;
          color: white;
        }
        &.warning {
          background: #f6e914;
          color: black;
        }
        &.success {
          background: white;
          border-color: black;
          color: black;
        }
        &.information {
          background: black;
          color: white;
        }
        .message-text {
          padding-right: 1em;
        }
      }
    }
  }
  .gm-style {
    font-family: 'tstar_mono_roundregular', Helvetica, Arial, sans-serif;
  }
  .address-map-directions-panel,
  .address-map-location-panel {
    display: none;
  }
  .address-map-directions-panel .locations,
  .address-map-location-panel .locations {
    height: 100%;
    overflow-y: scroll;
    background-color: white;
    cursor: pointer;
    padding-top: 10px;
  }
  .address-map-directions-panel .location {
    overflow: hidden;
    padding: 0.5em 0.5em 1em;
    margin: 0;
    border-bottom: 1px solid #ccc;
    color: black;
    -webkit-transition: padding 0.5s, background 0.5s;
    transition: padding 0.5s, background 0.5s;
  }
  .address-map-location-panel {
    .location {
      overflow: hidden;
      padding: 0.5em 0.5em 1em;
      margin: 0;
      border-bottom: 1px solid #ccc;
      color: black;
      -webkit-transition: padding 0.5s, background 0.5s;
      transition: padding 0.5s, background 0.5s;
      .detail_reveal {
        display: none;
        &.selected {
          display: block;
        }
        .select-access-point {
          width: 40%;
          float: left;
        }
        .get-directions {
          width: 50%;
          float: right;
        }
      }
      &:hover,
      &.selected {
        padding: 2em 0.5em;
        background-color: #c7c7c7;
      }
      &:first-child {
        padding-top: 60px;
      }
    }
    .map .location {
      min-width: 200px;
    }
  }
  .address-map-directions-panel .location p,
  .address-map-location-panel .location p {
    margin: 0 0 0.5em;
  }
  .address-map-directions-panel .location .image,
  .address-map-location-panel .location .image {
    float: left;
    width: 100px;
    margin: 0 1em 0 0;
    display: none;
  }
  .address-map-directions-panel .location .image img,
  .address-map-location-panel .location .image img {
    width: 100%;
  }
  .address-map-directions-panel .location .distance,
  .address-map-location-panel .location .distance {
    float: right;
    color: #999;
  }
  .address-map-directions-panel .location .distance .uom,
  .address-map-location-panel .location .distance .uom {
    text-transform: lowercase;
  }
  .address-map-directions-panel .location .location_description,
  .address-map-location-panel .location .location_description {
    font-style: italic;
  }
  .address-map-directions-panel .location .title h2,
  .address-map-location-panel .location .title h2 {
    font-weight: bold;
    font-size: 14px;
    border: 0;
    padding: 0.5em 0;
    color: black;
  }
  .address-map-directions-panel .location div.button,
  .address-map-location-panel .location div.button {
    clear: both;
    margin-top: 1em;
  }
  .location .button {
    a {
      background-color: inherit;
      font-family: 'tstar_mono_roundregular', Helvetica, Arial, sans-serif;
      width: 4em;
      height: 1em;
      line-height: 1em;
      color: inherit;
      font-size: 11px;
      text-decoration: underline;
    }
    .get-directions {
      width: 13em;
      margin-left: 0.5em;
    }
  }
  .address-map-location-panel .location .title {
    font-weight: bold;
  }
  .map-info-window {
    background: black;
    color: white;
    padding: 10px;
    .title span {
      color: white;
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }
    .location {
      width: 268px;
      .title {
        padding-bottom: 5px;
      }
      .detail_reveal .button {
        max-width: none;

        &.select-access-point {
          margin-bottom: 1em;
        }
      }
    }
  }
  .directions {
    background: white;
    color: black;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 100%;

    .directions-content {
      padding: 1em;
    }

    .button-wrap {
      margin: 1em 1em 0;
    }
  }
  .map-info-window .location .image {
    display: none;
  }
}

/* InfoWindow popup */

.adp-placemark {
  width: 100%;
  background-color: white;
  td {
    padding: 3px;
    color: black;
  }
}

.adp-substep,
.adp-summary,
.adp-text {
  color: black;
  max-width: 200px;
}

.map-container .adp-step,
.adp-text {
  width: 90% !important;
}

.adp-directions {
  width: 100%;
}

/* overlay styles */

.click_and_collect_map {
  width: 100%;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  .close-container {
    display: none;
  }
  .overlay-content {
    height: 100%;
    width: 100%;
    position: relative;
    .map-container {
      height: 100%;
      position: relative;
      overflow: hidden;
      .address-map-search-panel {
        position: absolute;
        top: 0px;
        z-index: 1;
        margin-left: 19.5em;
        margin-top: 1em;
        border-top: 0;
        .status-bar-wrapper {
          position: fixed;
          bottom: 20px;
          left: 450px;
          width: auto;
          white-space: nowrap;
          margin-left: 0;
        }
        .search-box-field {
          float: left;
          width: 26em;
          padding-left: 1.3em;
          -webkit-appearance: none;
          -webkit-border-radius: 0;
          border-radius: 0;
        }
        .search-box-submit {
          height: 2em;
          width: 3.6em;
          line-height: 2em;
          color: white;
          float: left;
        }
      }
      .search-box-toggle {
        width: 4em;
        height: 4em;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        position: relative;
        background-color: white;
        color: black;
        padding: 9px;
        font-size: 14px;
        border: solid 1px;
        cursor: pointer;
        right: 1.7em;
        margin-bottom: 15px;
        z-index: 1;
      }
      .search-box-toggle-tooltip {
        position: absolute;
        width: 23.9em;
        height: 5.9em;
        color: white;
        background-color: black;
        font-size: 13px;
        bottom: 4.7em;
        right: 1em;
        z-index: 1;
        padding: 11px;
        padding-right: 60px;
      }
      .collection-tooltip,
      .store-tooltip {
        display: none;
      }
    }
  }
}

.local-collection .overlay-content .map-container .search-box-toggle .toggle-img {
  background: url('/media/export/cms/cc/fm_icon_off.png') no-repeat 0px 15px;
  background-size: 38px 7px;
  height: 2em;
  width: 3.1em;
}

.store-collection .overlay-content .map-container .search-box-toggle .toggle-img {
  background: url('/media/export/cms/cc/ups_icon.png') no-repeat 1px 2px;
  height: 2.5em;
  width: 3.1em;
}

.click_and_collect_map .overlay-content .map-container {
  .address-map-search-panel .search-box-submit {
    background-color: black;
    text-align: center;
    cursor: pointer;
  }
  .search-box-cancel {
    background-color: black;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 0.62em;
    right: 0.7em;
    padding-top: 0.1em;
    margin: 0;
    z-index: 1;
    width: 2.5em;
    height: 1.66em;
    color: white;
    font-size: 27px;
    line-height: 1.66em;
    .icon--remove:before {
      padding: 0;
    }
  }
  .search-box-geolocate {
    position: relative;
    width: 1.7em;
    height: 1.7em;
    font-size: 32.9px;
    right: 0.7em;
    z-index: 1;
    overflow: hidden;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    color: white;
    text-align: center;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    background: black;
    cursor: pointer;
    line-height: 1.7em;
    .icon--geolocation {
      font-size: 24px;
    }
  }
  .address-map-search-panel .search-box-submit .loading span:before,
  .search-box-geolocate .loading i:before,
  .search-box-cancel .loading i:before {
    content: '';
  }
  .address-map-search-panel .status-bar-wrapper .status-bar .message {
    border-top: 0;
    &:first-child {
      border-radius: 0;
    }
  }
  .address-map-directions-panel,
  .address-map-location-panel {
    top: 0px;
    bottom: 0px;
    display: block;
    position: absolute;
    width: 28em;
    padding-right: 24px;
    z-index: 2;
  }
  .address-map-directions-panel header,
  .address-map-location-panel header {
    background: white;
    padding-top: 15px;
    height: 5em;
    z-index: 2;
  }
}

html.mac .click_and_collect_map .overlay-content .map-container {
  .address-map-directions-panel header,
  .address-map-location-panel header {
    height: 40px !important;
  }
}

.click_and_collect_map .overlay-content .map-container {
  .address-map-directions-panel header {
    height: 45px;
  }
  .address-map-location-panel header h2,
  .address-map-directions-panel header h2 {
    color: black;
    font-size: 2em;
    padding-left: 0.5em;
  }
  .address-map-location-panel a.close {
    display: block;
    position: absolute;
    right: 6px;
    top: 16px;
    text-decoration: none;
    z-index: 3;
    margin: 0;
    background: none;
    width: 13px;
    height: auto;
    text-indent: 0px;
  }
  .address-map-directions-panel a.close {
    position: absolute;
    z-index: 3;
    margin: 0;
    width: auto;
    right: 37px;
    top: 10px;
    padding: 5px 8px 2px;
  }
  .address-map-location-panel {
    a.close span:before {
      display: block;
      font-family: 'icons';
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '';
      font-size: 16px;
      color: black;
      text-decoration: none;
    }
    &.active a.close span:before {
      content: '';
    }
  }
  .address-map-directions-panel {
    z-index: 3;

    header div a.close span:before {
      display: block;
      font-family: 'Arial';
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '';
      font-size: 16px;
      color: black;
      text-decoration: none;
    }
    left: 0;
  }
  .address-map-location-panel.active {
    left: 0;
  }
  .map {
    height: 100%;
    width: 100%;
  }
  .map-side-tools {
    bottom: 15%;
    right: 0.4em;
    position: absolute;
  }
}

@media (max-width: 1023px) {
  .map-container .address-map-search-panel .search-box-field {
    width: 40%;
    font-size: inherit;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-field {
    width: 21em;
  }
  .map-container .map-info-window .location {
    .address {
      padding-bottom: 5px;
    }
    .detail_reveal .button {
      max-width: 100%;
    }
  }
}

@media (max-width: 850px) {
  .click_and_collect_map .overlay-content .map-container {
    .address-map-search-panel .search-box-wrapper {
      width: 100%;
      float: none;
      clear: both;
      padding-left: 10px;
    }
    .search-box-cancel {
      right: 0px;
      font-size: 21px;
      top: 0px;
      height: 45px;
    }
    .address-map-search-panel {
      margin-top: 2em;
    }
  }
  .map-container .location .button {
    a {
      font-size: 10px;
    }
    .get-directions {
      margin-left: 0px;
    }
  }
  .click_and_collect_map .overlay-content .map-container {
    .map-info-window {
      max-width: 205px;
    }
    .address-map-search-panel {
      margin-left: 0;
    }
  }
  .address-map-location-panel header h2 {
    margin-top: -5px;
  }
  .click_and_collect_map .overlay-content {
    .map-container {
      .address-map-directions-panel {
        width: 100%;
      }
      .address-map-location-panel {
        width: 100%;
        a.close {
          right: 35px;
        }
      }
      .address-map-directions-panel a.close {
        right: 35px;
      }
      .address-map-location-panel header {
        width: 100%;
      }
      .address-map-directions-panel {
        header {
          width: 100%;
        }
        top: 0;
        max-height: 100%;
        overflow-y: scroll;
      }
    }
    .search-box-cancel span:before {
      padding-top: 0;
    }
    .map-container .address-map-location-panel {
      top: auto;
      left: 0pc;
      bottom: 0px;
      width: 100%;
      height: 30px;
      margin: 0;
      padding-bottom: 0;
      overflow: hidden;
      &.active {
        top: 0px;
        height: auto;
        z-index: 3;
      }
      a.close span:before {
        font-family: 'icons';
        content: '';
        color: black;
      }
      &.active a.close span:before {
        content: '';
      }
      header {
        padding-top: 10px;
      }
      .location:first-child {
        padding-top: 50px;
      }
    }
  }
  .map-container .address-map-location-panel .location {
    .title .distance {
      display: block;
      .uom {
        color: #888888;
      }
    }
    .distance {
      display: none;
    }
  }
  .click_and_collect_map .overlay-content .map-container {
    .address-map-directions-panel,
    .address-map-location-panel {
      padding-right: 0px;
    }
  }
}

@media (max-width: 767px) {
  #checkout_shipmethod {
    padding: 0px;
  }
  .map-container .address-map-search-panel {
    .search-box {
      width: auto;
    }
    .search-box-field {
      width: 30%;
    }
  }
  .click_and_collect_map .overlay-content .map-container {
    .map-info-window .location {
      .distance p,
      .address p {
        line-height: 0.5em !important;
      }
      .title {
        padding-top: 2px;
      }
      .location_description,
      .opening_hours,
      .image {
        display: none;
      }
      .location_more_info {
        color: white !important;
        text-decoration: underline;
        cursor: pointer;
        line-height: 2em !important;
        display: block;
      }
    }
    .address-map-search-panel .status-bar-wrapper {
      position: fixed;
      bottom: 50px;
      width: 100%;
      margin: 0;
      left: 0;
      text-align: center;
    }
  }
  #shipmethod-panel .ship-method-group-label {
    label {
      line-height: 1.5em;
    }
    margin-left: 0px;
  }
  body#viewcart .viewcart-buttons-panel .continue-buttons a.continue-checkout {
    margin: 10px 0;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-field {
    width: 19.3em;
  }
  .search-box-wrapper .search-box .search-box-submit {
    height: 200px;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel {
    .search-box-wrapper {
      width: 100%;
      float: none;
      clear: both;
      padding-left: 0px;
    }
    margin-top: 0em;
  }
  .map-container {
    .map-info-window .location {
      width: auto;
      padding: 0;
    }
    .location .title span {
      line-height: 2em;
    }
  }
  .adp-placemark {
    td {
      padding-left: 22px;
    }
  }

  .adp-legal {
    text-align: right;
  }
}

@media (max-width: 480px) {
  .click_and_collect_map .overlay-content .map-container {
    .address-map-search-panel {
      margin-left: 0;
      border-radius: 0;
      width: 100%;
    }
    .address-map-directions-panel {
      top: 0;
      max-height: 100%;
      overflow-y: scroll;
    }
    .address-map-location-panel {
      &.hover {
        left: 0pc;
      }
      a.close {
        top: 2px;
        right: 25px;
        border: 0;
      }
    }
    .address-map-search-panel {
      .search-box-wrapper {
        width: 100%;
        background-color: white;
        border-bottom: 1px black solid;
      }
      .search-box-field {
        width: 89%;
        border: 0;
      }
      .search-box-submit {
        background-color: white;
        color: black;
        width: 10%;
      }
    }
    .search-box-cancel {
      z-index: 2;
    }
  }
}
