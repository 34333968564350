.password {
  position: relative;
  &__info {
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      margin-top: 6px;
      border: 1px solid #{$color-dark-gray};
      &::before {
        content: '';
        position: absolute;
        top: 16%;
        right: 94%;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #{$color-black} transparent;
      }
      &-profile {
        margin-top: 10px;
      }
    }
    .password__rules {
      column-count: 2;
      @include breakpoint($medium-up) {
        column-count: auto;
      }
      li {
        padding: 3px 0;
        @include breakpoint($medium-up) {
          padding: 3px 0 3px 15px;
        }
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #{$color-black};
        transition: 0.2s;
        white-space: nowrap;
        line-height: 1.5;
        &.pass {
          color: #{$color-green};
          &::before {
            color: #{$color-green};
            content: $tick-mark;
            text-shadow: 0 0 8px #{$color-black};
          }
        }
        &::before {
          margin: 0 6px 0 0;
          content: $cross-mark;
          display: inline-block;
          color: #{$color-red};
          font-size: 1em;
          line-height: 0;
          transition: 0.2s;
        }
      }
    }
  }
}
