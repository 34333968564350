.gnav-links__content {
  width: 100%;
  .gnav-links__title {
    margin-bottom: 0.8em;
    font-size: 13px;
  }
  .gnav-links__section {
    margin: 0;
    padding: 0;
    max-height: 280px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .gnav-links {
      &__link {
        margin-#{$rdirection}: 10px;
        line-height: 1.9;
        flex: 1 1 30px;
        a {
          color: inherit;
          text-decoration: none;
          text-transform: initial;
          font-size: 12px;
        }
        &-shop__all {
          a {
            font-weight: bold;
          }
          &:nth-child(5n + 5) {
            margin-top: 10%;
          }
          &:nth-child(25n + 22) {
            margin-top: 10%;
          }
        }
      }
    }
  }
}
