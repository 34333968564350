.subscribe-popup {
  width: 100%;
  margin: 0 auto;
  @include breakpoint($medium-up) {
    width: 60%;
  }
  .form-item {
    margin-bottom: 15px;
    input {
      width: 100%;
    }
    label {
      display: none;
    }
    &.email-promotions {
      label {
        display: block;
      }
    }
  }
  &__header {
    font-size: 32px;
    line-height: 1;
    margin-bottom: 30px;
    text-align: center;
    @include breakpoint($medium-up) {
      font-size: 36px;
    }
    &:after {
      width: 80%;
      margin: 20px auto 0;
      @include breakpoint($medium-up) {
        width: 75%;
      }
    }
  }
  &__header,
  &__thanks h2 {
    &:after {
      content: '';
      display: block;
      height: 1px;
      background: $color-black;
    }
  }
  &__subheader {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 25px;
  }
  &__submit {
    text-align: center;
  }
  &__errors {
    color: $color-red;
  }
  &__thanks {
    text-align: center;
    h2 {
      font-size: 32px;
      font-style: italic;
      line-height: 1;
      @include breakpoint($medium-up) {
        font-size: 36px;
      }
      &:after {
        width: 90%;
        margin: 20px auto 0;
        @include breakpoint($medium-up) {
          width: 50%;
        }
      }
    }
  }
  .error {
    color: $color-red;
    option {
      color: $color-black;
    }
  }
  &__title {
    select {
      width: 25%;
      @include breakpoint($medium-up) {
        width: 20%;
      }
    }
  }
  &__country {
    select {
      width: 70%;
      @include breakpoint($medium-up) {
        width: 50%;
      }
    }
  }
}
