.adv_cal_cap {
  .adv-cal-cap-container {
    .adv-cal-cap-wrapper {
      .cards {
        .tile {
          &.card:not(.tout) {
            border: none;
          }
          &.card {
            &.active,
            &.future {
              box-shadow: none;
              border-top-#{$ldirection}-radius: 5em;
              border-top-#{$rdirection}-radius: 5em;
            }
          }
        }
      }
    }
  }
}
