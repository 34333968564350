#sign-in-confirmation-panel {
  .checkout-panel {
    &__header {
      border: none;
      padding-top: 30px;
      .heading--1 {
        &__underline {
          &:after {
            max-width: 600px;
          }
        }
      }
    }
    &__content {
      text-align: center;
      .messages {
        display: block;
        font-size: 16px;
      }
      .form-item {
        margin-bottom: 1em;
      }
      .forgot-pw-note-wrap {
        margin: 1em 0 2em;
        .link {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}
.checkout-panel-main {
  #registration-panel {
    .content {
      #completed_checkout_registration {
        .password {
          &__info {
            display: inline-block;
            padding-right: 15px;
            margin-left: 30px;
            &::before {
              top: 15%;
              right: 54%;
            }
          }
        }
        .show-password {
          .label {
            &::before {
              display: block;
              height: 0;
            }
          }
        }
      }
    }
    .messages {
      display: block;
      font-size: 16px;
    }
  }
}

.checkout--confirmation-page {
  padding-top: 120px;
  @include breakpoint($medium-up) {
    padding-top: 30px;
  }
  .checkout-registration {
    .form-item {
      input {
        margin-top: 5px;
      }
    }
  }
  .divide {
    input[type='checkbox'] ~ .label,
    input[type='checkbox'] ~ label {
      &::before {
        margin-right: 0;
      }
    }
  }
}
