.perfumers__formatter {
  margin-bottom: 30px;
  @include breakpoint($medium-up) {
    margin-bottom: 80px;
  }
  .perfumer__title {
    .content-block-large__inner.extra-padding {
      @include breakpoint($medium-up) {
        padding: 50px 0 10px;
      }
    }
  }
  .perfumer__author {
    position: relative;
    z-index: 0;
    .content-block-large__inner.extra-padding {
      @include breakpoint($medium-up) {
        padding: 50px 0 10px;
      }
    }
  }
  .perfumer__product {
    .content-block-large__inner.extra-padding {
      @include breakpoint($medium-up) {
        padding: 50px 0 20px;
      }
    }
  }
}
