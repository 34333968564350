///////////////////////////////
/// CONTENT BLOCK LARGE
/// bg image dictates container height
/// uses generic font and size classes
///////////////////////////////
.content-block-large {
  display: flex;
  position: relative;
  width: 100%;
  background-color: $color-white;
  z-index: 0;

  &__arabic-font {
    font-family: $font-arabic-axtbassima !important;
  }
  &__Korean-regular {
    font-family: $font-korean-regular !important;
  }
  &__Korean-medium {
    font-family: $font-korean-medium !important;
  }
  &__chinese-regular {
    font-family: $font-chinese-regular !important;
  }
  &__chinese-medium {
    font-family: $font-chinese-medium !important;
  }
  .content-block-large__carat {
    display: none;
    position: absolute;
    width: 100%;
    height: 30px;
  }

  &.no-image {
    &.text-above {
      padding: 0 20px 30px;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 0;
        padding-#{$rdirection}: 0;
      }
    }
    &.text-below {
      padding-top: 15px;
      @include breakpoint($medium-up) {
        padding-top: 30px;
      }
    }
    .content-block-large__content-image {
      flex: 0 0 auto;
      padding-top: 0;
    }
  }

  &.has-video {
    .content-block-large__video {
      width: 100%;
      display: none;
    }
    video {
      width: 100%;
    }

    &.video-active.video-background {
      .content-block-large__video {
        display: block;
      }
      .content-block-large__inner {
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &.has-carat {
    .content-block-large__carat {
      display: block;
      bottom: 0;
      overflow: hidden;
      &-left,
      &-right {
        content: '';
        background: $color-white;
        position: absolute;
        bottom: 0;
        width: 50%;
        z-index: 3;
        padding-bottom: 13px;
        @include breakpoint($medium-up) {
          padding-bottom: 30px;
        }
      }
      &-left {
        #{$rdirection}: 50%;
        transform-origin: 100% 100%;
        transform: skewX(53deg);
      }
      &-right {
        #{$ldirection}: 50%;
        transform-origin: 0 100%;
        transform: skewX(-53deg);
      }
    }
  }

  &.has-carat--top {
    &.no-image {
      padding-top: 50px;
    }
    .content-block-large__carat {
      top: 0;
      &-left,
      &-right {
        top: 0;
        bottom: auto;
      }
    }
  }

  &.has-carat--bottom {
    &.no-image:not(.video-background) {
      padding-bottom: 30px;
    }
  }

  &.has-video {
    .content-block-large__video {
      width: 100%;
      display: none;
    }
    video {
      width: 100%;
    }

    &.video-active {
    }
    &.video-active.video-background {
      .content-block-large__video {
        display: block;
      }
      .content-block-large__inner {
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        width: 100%;
      }
    }
  }

  &.play-icon-color {
    .icon--play {
      fill: $color-light-gray;
    }
  }

  &.top-border,
  &.bottom-border {
    &:before {
      content: ' ';
      width: 350px;
      max-width: 50%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.top-border {
    padding-top: 50px;
    margin-top: 50px;
    @include breakpoint($medium-down) {
      padding-top: 30px;
      margin-top: 30px;
    }
    &:before {
      border-top: 1px solid $color-black;
      top: 0;
    }
  }
  &.bottom-border {
    padding-bottom: 0;
    @include breakpoint($medium-down) {
      margin-bottom: 30px;
    }
    margin-bottom: 50px;
    &:before {
      border-bottom: 1px solid $color-black;
      bottom: 0;
    }
    .content-block-large__content-text {
      padding-top: 36px;
      padding-bottom: 34px;
      @include breakpoint($medium-down) {
        padding-bottom: 30px;
      }
    }
  }
  &.column-border {
    &.text-image-column {
      .content-block-large__content-image {
        @include breakpoint($medium-up) {
          border-#{$ldirection}: 1px solid $color-white;
        }
      }
    }
    &.image-text-column {
      .content-block-large__content-image {
        @include breakpoint($medium-up) {
          border-#{$rdirection}: 1px solid $color-white;
        }
      }
    }

    .content-block-large__content-image,
    .content-block-large__content-text {
      padding: 0 10px;
      margin-bottom: 10px;
      @include breakpoint($medium-up) {
        padding: 0 30px;
        margin-bottom: 20px;
      }
    }
  }
  &.column-spacing {
    &.text-image-column {
      .content-block-large__content-text {
        @include breakpoint($medium-up) {
          padding-#{$rdirection}: 25px;
        }
      }
      .content-block-large__content-image {
        @include breakpoint($medium-up) {
          padding-#{$ldirection}: 25px;
        }
      }
    }
    &.image-text-column {
      .content-block-large__content-image {
        @include breakpoint($medium-up) {
          padding-#{$rdirection}: 25px;
        }
      }
      .content-block-large__content-text {
        @include breakpoint($medium-up) {
          padding-#{$ldirection}: 25px;
        }
      }
    }
  }
  &.text-above {
    .content-block-large__content-text {
      order: 0;
      flex: 0 0 auto;
      width: 90%;
    }
    .content-block-large__content-image {
      order: 1;
      flex: 0 0 auto;
    }
  }
  &.text-below {
    .content-block-large__content-text {
      order: 1;
      flex: 0 0 auto;
    }
    .content-block-large__content-image {
      order: 0;
      flex: 0 0 auto;
    }
  }
  &.text-image-column {
    .content-block-large__content {
      padding-#{$ldirection}: 10px;
      padding-#{$rdirection}: 10px;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 0;
        padding-#{$rdirection}: 0;
        flex-direction: row;
      }
    }
    .content-block-large__content-text {
      order: 0;
      @include breakpoint($medium-up) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    .content-block-large__content-image {
      order: 1;
      @include breakpoint($medium-up) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  &.image-text-column {
    .content-block-large__content {
      @include breakpoint($medium-up) {
        flex-direction: row;
      }
    }
    .content-block-large__content-text {
      order: 1;
      flex: 0 0;
      @include breakpoint($medium-up) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    .content-block-large__content-image {
      order: 0;
      flex: 0 0;
      @include breakpoint($medium-up) {
        flex: 0 0 50%;
        max-width: 50%;
        text-align: #{$rdirection};
      }
    }
  }
  &.image-bg-right {
    .content-block-large__content-image {
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: 0;
    }
    .content-block-large__content-text {
      position: absolute;
      top: 50%;
      #{$ldirection}: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &.image-bg-left {
    .content-block-large__content-image {
      margin-#{$ldirection}: 0;
      margin-#{$rdirection}: auto;
    }
    .content-block-large__content-text {
      position: absolute;
      top: 50%;
      #{$ldirection}: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__content-image {
    position: relative;
  }

  &__content-image-main {
    position: relative;
    .hover-lazyload {
      opacity: 0;
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      width: 100%;
      height: auto;
      min-height: 100%;
      transition: 0.3s;
    }
    &:hover {
      .hover-lazyload {
        &.lazyloaded {
          opacity: 1;
        }
      }
    }
  }

  .icon {
    fill: $color-white;
    height: 91px;
    width: 91px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  &__inner {
    width: 100%;
    position: relative;
  }
  &__video-above-image {
    position: absolute;
    top: 50%;
    #{$ldirection}: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    cursor: pointer;
  }
  picture img {
    width: 100%;
    z-index: 1;
  }

  &__content-image-carousel {
    .slick-prev,
    .slick-next {
      fill: $color-white;
      width: 25px;
      height: 25px;
      top: 50%;
    }
    .slick-prev {
      #{$ldirection}: 15px;
    }
    .slick-next {
      #{$rdirection}: 15px;
    }
  }

  &.mobile-flex-enable &__content {
    display: flex;
  }

  &__content {
    width: 100%;
    flex-direction: column;
    align-items: center;
    @if $text-direction == 'rtl' {
      text-align: center;
    }
    @include breakpoint($medium-up) {
      display: flex;
    }
  }
  &__content-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    img {
      max-width: 100%;
      display: block;
      margin: auto;
      @include breakpoint($medium-up) {
        width: 100%;
      }
    }
  }
  &__content-text {
    width: 100%;
    padding: 20px;
    @include breakpoint($medium-up) {
      padding: 30px;
    }
    &.hide-text-content {
      display: none;
    }
    p {
      margin: auto;
      line-height: inherit;
    }
  }
  &__content-text__subheader--sansserif,
  &__content-text--sansserif {
    @include font-inter-regular;
  }
  &__content-text__subheader--margin {
    margin-top: 25px;
  }
  &__content-button {
    max-width: none;
    display: inline-block;
    width: auto;
    margin-top: 28px;
    @include breakpoint($medium-up) {
      margin-top: 56px;
    }
  }

  .copy--quote-sans {
    padding-top: 30px;
    @include breakpoint($medium-up) {
      padding-top: 70px;
    }
  }

  &:not(.no-image) .content-block-large__content {
    height: 100%;
    position: absolute;
    top: 0;
  }

  .align-left {
    text-align: #{$ldirection};
  }
  .align-center {
    text-align: center;
  }
  .align-right {
    text-align: #{$rdirection};
  }
  .justify-start {
    justify-content: flex-start;
    @include breakpoint($medium-up) {
      padding-bottom: 0px;
    }
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .max-width {
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    width: 100%;
    @include breakpoint($medium-up) {
      width: 85%;
    }
  }
  @include breakpoint($medium-up) {
    .max-width-30 {
      max-width: 30%;
    }
    .max-width-40 {
      max-width: 40%;
    }
    .max-width-50 {
      max-width: 50%;
    }
    .max-width-60 {
      max-width: 60%;
    }
    .max-width-70 {
      max-width: 70%;
    }
    .max-width-80 {
      max-width: 80%;
    }
    .max-width-90 {
      max-width: 90%;
    }
  }
  .extra-padding {
    padding: 10px 10px 30px 10px;
    @include breakpoint($medium-up) {
      padding: 50px 0 50px;
    }
  }

  .extra-inner-padding {
    padding: 20px 0;
    @include breakpoint($medium-up) {
      padding: 140px;
    }
  }

  &__read-more {
    padding: 20px 0;
    text-align: center;
    @include breakpoint($medium-up) {
      text-align: #{$ldirection};
    }

    a {
      color: inherit;
      @include font-inter-medium;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      .icon {
        fill: $color-white;
        height: 13px;
        width: 13px;
        vertical-align: bottom;
        display: inline-block;
        margin-#{$rdirection}: -13px;
        margin-#{$ldirection}: 13px;
        margin-bottom: 0;
        @include breakpoint($medium-up) {
          height: 16px;
          width: 16px;
          vertical-align: top;
          margin-top: 5px;
        }
      }
    }
  }
  @include breakpoint($medium-up) {
    .content-block-padding-15 {
      padding-#{$ldirection}: 15px;
      padding-#{$rdirection}: 15px;
    }
    .content-block-padding-20 {
      padding-#{$ldirection}: 20px;
      padding-#{$rdirection}: 20px;
    }
    .content-block-padding-25 {
      padding-#{$ldirection}: 25px;
      padding-#{$rdirection}: 25px;
    }
    .content-block-padding-30 {
      padding-#{$ldirection}: 30px;
      padding-#{$rdirection}: 30px;
    }
    &__inner {
      .content-image-text-padding-15 {
        padding-#{$ldirection}: 15px;
      }
      .content-image-text-padding-20 {
        padding-#{$ldirection}: 20px;
      }
      .content-image-text-padding-25 {
        padding-#{$ldirection}: 25px;
      }
      .content-image-text-padding-30 {
        padding-#{$ldirection}: 30px;
      }
    }
  }
  .content-block-large__content-text__line {
    p {
      font-size: inherit;
    }
  }
}

// Hide overlay node reference content
.content-block-large__overlay-content {
  display: none;
}
// Restyle email form for Colorbox Overlay
#colorbox.overlay-content {
  .site-email-signup__form {
    background-color: white;
    color: black;
    padding: 0;
    position: relative;
  }
  .site-email-signup__content {
    display: none;
  }
  .site-email-signup .site-email-signup__fields {
    width: 100%;
    margin: 0;
  }
  .site-email-signup input[type='text'].site-email-signup__field + label:before,
  .site-email-signup input[type='email'].site-email-signup__field + label:before {
    color: black;
  }
  .site-email-signup input[type='text'],
  .site-email-signup input[type='email'] {
    display: block;
    max-width: 256px;
    margin: 0 auto;
    color: black;
  }
  .site-email-signup input[type='text'] + label,
  .site-email-signup input[type='email'] + label {
    margin-top: calc(-2.7em - 1px);
    text-align: center;
  }
  .site-email-signup {
    .button {
      max-width: 256px;
      margin: 1.5em auto 0;
      display: block;
      &:hover {
        border-color: $color-orange;
      }
    }
  }
  // Override default yellow background webkit
  .site-email-signup {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus input:-webkit-autofill {
      border: none;
      -webkit-text-fill-color: black;
      -webkit-box-shadow: none;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

.content-block-large--columns {
  &.content-block-large {
    .content-block-large__content-image {
      order: 0;
      @include breakpoint($medium-up) {
        order: 1;
      }
    }
    .content-block-large__content-text {
      order: 1;
      @include breakpoint($medium-up) {
        display: flex;
        justify-content: space-between;
        order: 0;
        padding: 0 10px;
      }
    }
    .content-block-large__content-text__column {
      padding-bottom: 30px;
      @include breakpoint($medium-up) {
        flex: 0 0 49%;
      }
    }

    &.image-left {
      @include breakpoint($medium-up) {
        .content-block-large__content-image {
          order: 0;
        }
        .content-block-large__content-text {
          order: 1;
        }
      }
    }
  }
}
