$gnav-signup-max-width: 380px;

.site-email-signup__wrapper {
  @include breakpoint($medium-up) {
    width: 34em;
    position: relative;
    height: 3em;
  }
}

.site-email-signup__form {
  @include breakpoint($medium-up) {
    position: absolute;
    bottom: 0px;
    #{$ldirection}: 0;
    width: 100%;
    background: $color-black;
    padding: 0.9em 2em 0;
  }
  .country-chooser__select {
    .selectBox-arrow {
      border-#{$ldirection}: 0;
      #{$ldirection}: 120%;
    }
    .selectBox-label {
      padding: 0;
      @include breakpoint($tablet-only) {
        margin-#{$rdirection}: 60%;
      }
    }
  }
  .tooltip {
    &:hover {
      .tooltiptext-over {
        display: block;
      }
    }
    .tooltiptext-over {
      display: none;
    }
  }
}

.site-email-signup {
  .site-email-signup__container {
    max-width: $gnav-signup-max-width;
    border-bottom: 1px solid $color-light-gray;
    padding: 0;
    margin: 1em auto auto;
    @include breakpoint($medium-up) {
      margin-top: 0;
    }
  }
  .tooltip {
    padding-top: 10px;
  }
  .site-email-signup__content {
    display: none;
  }
  .site-footer & {
    .site-email-signup__block {
      display: inline-block;
      width: 67%;
      height: 3em;
      @include breakpoint($medium-up) {
        height: auto;
        width: 75%;
      }
    }
    .site-email-signup__title {
      color: $color-white;
      cursor: pointer;
      padding-#{$rdirection}: 1.5em;
      background: transparent
        url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAAWUlEQVQI113P0Q0AIQgD0DoELIqMqY7EpUY89aMxgWcA9N5DRALATCnletmjgbvHizOstdaCBiwkVtULsbZQIEecOFGt9VzjH8XGGGMiM9t7ruA6hIAfHhQfxCxjnd8YVhsAAAAASUVORK5CYII=)
        #{$rdirection} center no-repeat;
      display: inline;
    }
    .site-email-signup__content {
      .site-email-signup__title {
        background-image: url('#{$base-theme-path}img/icons/src/X.svg');
        background-repeat: no-repeat;
        display: block;
      }
    }
  }
  .site-email-country-dropdown a {
    margin-#{$rdirection}: 28%;
  }
  .site-email-country-dropdown select {
    margin-#{$rdirection}: 31%;
  }
  .site-email-signup__copy {
    @include tracking(32);
    font-size: 1.2em;
    margin-top: 5%;
  }
  .site-email-signup__messages {
    text-align: #{$ldirection};
    margin-bottom: 25px;
    line-height: 1.5;
  }
  .site-email-signup__message {
    .site-email-signup--error {
      color: $color-red;
    }
    .site-email-signup--success {
      color: $color-green;
    }
  }
  .site-email-signup__fields {
    @include pie-clearfix;
    width: 100%;
    margin: 0 auto;
  }
  .site-email-signup__field {
    display: block;
    width: 280px;
    margin: auto;
    margin-bottom: 1em;
    padding-bottom: 0;
    height: 3em;
  }
  .site-footer & {
    input[type='text'].site-email-signup__field,
    input[type='email'].site-email-signup__field,
    input[type='tel'].site-email-signup__field {
      @include tracking(40);
      @include input-placeholder {
        font-size: inherit;
        color: $white;
      }
      &:focus {
        background-color: $color-black;
        color: $color-white;
        border: none;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px $black inset;
        -webkit-text-fill-color: $white !important;
        border: none;
      }
      width: 100%;
      background: transparent;
      color: $white;
      margin: 0 0 1px;
      border: 1px solid transparent;
      font-size: inherit;
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 0;
      &.error {
        padding: 0 10px;
        & + label {
          margin-bottom: 10px;
          padding-#{$ldirection}: 10px;
          line-height: 2.6em;
        }
      }
      & + label {
        font-size: 1.7em;
      }
      & + label:before {
        color: $color-white;
        text-transform: capitalize;
        font-size: 0.75em;
        margin-#{$ldirection}: 0;
        padding-top: 3%;
        padding-#{$ldirection}: 0;
        background: transparent;
        &.js-label-mode + label:before,
        &:focus + label:before {
          background: transparent;
          -webkit-transform: translateY(-1.5em) translateY(-0.5px) scale(0.8, 0.8);
          transform: translateY(-1.5em) translateY(-0.5px) scale(0.8, 0.8);
        }
      }
    }
    input[type='submit'].site-email-signup__submit {
      background: transparent;
      display: inline;
      margin: 0;
      float: #{$rdirection};
      padding: 5px;
      border: 0;
      width: auto;
      &:hover {
        color: $color-orange;
      }
    }
  }
  .site-email-signup__terms-conditions {
    text-align: justify;
    padding-top: 10px;
    @include breakpoint($medium-down) {
      max-width: 380px;
      margin: 0 auto;
    }
    overflow: hidden;
    .site-email-signup__checkbox,
    .site-email-signup__more {
      margin-bottom: 20px;
    }
    input[type='checkbox'] {
      @include input-styled-check('checkbox');
      ~ label,
      ~ .label {
        background: url('/sites/fredericmalle/themes/fredericmalle_base/img/icons/src/checkbox--white.svg')
          0 2px no-repeat;
        background-size: 16px;
        font-size: 12px;
      }
      &:checked {
        ~ label,
        ~ .label {
          background: url('/sites/fredericmalle/themes/fredericmalle_base/img/icons/src/checkbox--white.svg')
            0 2px no-repeat;
          background-size: 16px;
          font-size: 12px;
        }
      }
    }
  }
  .site-email-signup__terms-conditions--active {
    margin: 1em 0;
    max-height: none;
    transition: max-height 0.4s, margin 0.4s;
  }
  .site-email-signup__success {
    position: relative;
    text-align: center;
    padding: 2em 0;
    .site-email-signup-header {
      text-transform: uppercase;
    }
    .site-email-signup-offer {
      line-height: 1.5em;
      .site-email-signup__code {
        text-transform: uppercase;
      }
    }
    .site-email-signup-terms {
      margin-top: 1em;
      margin-bottom: 2em;
    }
    .site-email-signup-cta {
      margin-bottom: 2em;
      .button--medium {
        min-width: 172px;
      }
    }
  }
  &__success-cta {
    text-align: center;
    a {
      margin-top: 20px;
      @include breakpoint($medium-up) {
        margin-top: 0;
      }
    }
  }
  .site-email-signup__message--error {
    color: $color-red;
  }
  .site-email-signup__terms-conditions--error {
    color: $color-red;
    padding-bottom: 2%;
  }
}

.email-signup--footer {
  .site-email-signup {
    .site-email-signup__content {
      display: block;
    }
  }
}

.site-email-signup__success {
  &-header {
    text-align: center;
    margin-bottom: 15px;
  }
  .site-email-signup__success-offer {
    margin-#{$ldirection}: 32%;
  }
}

.site-email-signup__content .site-email-country-dropdown {
  margin-top: -10px;
  @include breakpoint($medium-up) {
    margin-top: 0;
  }
  &.error {
    border: 1px solid $color-red;
    display: inline-block;
    padding: 0 35px 0 10px;
    margin: 10px 0 20px;
  }
  .country-selector {
    background: transparent;
    color: $color-white;
    width: 100%;
    border: 0;
    margin-#{$rdirection}: 0;
    height: 32px;
    padding: 0;
    @include breakpoint($medium-up) {
      min-width: fit-content;
      max-width: fit-content;
    }
    .selectBox-arrow {
      background: transparent;
      border: 0;
      #{$rdirection}: -35px;
      #{$rdirection}: -35px;
      &-svg {
        fill: $color-white;
        top: 0;
      }
    }
    .selectBox-label {
      padding: 0;
      min-width: fit-content;
      max-width: fit-content;
    }
  }
}
.selectBox-options {
  min-width: fit-content;
}

.email-signup-popup#colorbox {
  @include breakpoint($portrait-up) {
    height: 205px !important;
    margin-top: 30%;
    width: 685px !important;
    margin-#{$ldirection}: 6% !important;
    .site-email-signup__success {
      margin-#{$rdirection}: 10%;
    }
    button {
      #{$rdirection}: 16% !important;
    }
  }
  @include breakpoint($large-up) {
    margin-top: 0;
  }
}

#cboxContent {
  .site-email-signup__wrapper {
    width: auto;
    height: auto;
    .site-email-signup {
      border-bottom: none;
      &__content {
        display: block;
        margin-bottom: 0;
        text-align: center;
      }
      &__title {
        @include heading--1;
        @include heading--1__underline;
        color: $color-black;
        text-align: center;
        margin-bottom: 30px;
      }
      &__copy {
        text-align: center;
        p {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 30px;
          @include breakpoint($medium-up) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
      .site-email-country-dropdown {
        display: none;
      }
    }
  }
}
