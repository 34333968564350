.selectBox--stores-selectBox-dropdown-menu {
  .selectBox-optgroup {
    margin-top: 5px;
    padding-#{$ldirection}: 10px;
    color: $color-light-gray;
  }
  li a {
    padding-#{$ldirection}: 25px;
  }
}
.stores {
  .stores-mobile {
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  .stores-pc {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }

  .stores__dropdown {
    text-align: center;
    padding: 20px 0 30px;
    display: none; // Show after creating list via JS

    a.selectBox,
    select.selectBox {
      min-width: 230px;
    }
  }

  .accordions--countries {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
    .accordion--country {
      &:last-child {
        @include breakpoint($landscape-up) {
          border-bottom: none;
        }
      }
      > .accordion_btn {
        display: none;
        @include breakpoint($landscape-up) {
          display: block;
          padding: 10px 0 5px;
          font-size: 14px;
          text-transform: none;
          color: #8c8c8c;
          fill: #8c8c8c;
          transition: all 0.3s;
          &.no-flagships {
            border-bottom: none;
            &:after {
              display: none;
            }
            & + .accordion_panel {
              display: none !important;
            }
          }
        }
        &.active {
          @include breakpoint($landscape-up) {
            color: $color-black;
            fill: $color-black;
            border-bottom: 1px solid $color-lighter-gray;
            &.no-flagships {
              border-bottom: none;
              & + .accordion_panel {
                display: none !important; // Dont display accordions with no flagships but leave them clickable
              }
            }
          }
        }
      }
      > .accordion_panel {
        display: block;
        padding: 0;
        @include breakpoint($landscape-up) {
          display: none;
          padding-top: 10px;
        }
      }
    }

    .accordion--city {
      &:last-child {
        border-bottom: none;
      }
      > .accordion_btn {
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
      > .accordion_panel {
        padding-#{$ldirection}: 30px;
        padding-#{$rdirection}: 30px;
        @include breakpoint($landscape-up) {
          display: block;
          padding: 0;
        }
      }
    }
  }
  .stores__flagship {
    display: none;
    padding: 30px 15px 15px 15px;
    background: $color-black;
    color: $color-white;
    .stores__flagship-header {
      text-align: center;
      @include heading--5;
    }
    .stores__location.flagship .stores__location-name {
      color: $color-white;
    }
    .stores__location-image {
      display: block;
      margin: auto;
      margin-bottom: 20px;
      margin-top: 8px;
    }
    a {
      color: $color-white;
      font-weight: normal;
    }
    .slick-dots li.slick-active button:before {
      background: $color-white;
    }
  }
  .stores__location-wrapper {
    padding: 0 15px;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  .stores__regions {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    width: 95%;
    max-width: 900px;
    margin: auto;
    justify-content: space-between;

    a {
      @include heading--5;
      display: inline-block;
      text-decoration: none;
      font-size: 16px;
      transition: all 0.3s;
      &.active {
        color: $color-orange;
      }
    }
  }
  .stores__countries {
    padding: 40px 0 35px;
    @include breakpoint($landscape-up) {
      display: flex;
      width: 95%;
      max-width: 1200px;
      margin: auto;
    }
  }
  .stores__countries-list {
    flex: 0 0 300px;
  }
  .stores__countries-section {
    margin-top: -1px;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  .stores__content {
    flex: 1 0;
    @include breakpoint($landscape-up) {
      padding-#{$ldirection}: 3%;
    }
    .stores__stockists {
      columns: 3;
    }
    .accordion--city {
      display: inline-block; // Avoid column break
      width: 100%;
      &:last-child {
        border: none;
      }
    }
    .accordion_btn,
    .accordion_panel {
      display: block;
      border: none;
      &:after {
        content: none;
        display: none;
      }
    }
    .accordion_btn {
      color: $color-orange;
      padding-#{$ldirection}: 0;
      padding-top: 0;
    }
    .stores__location {
      cursor: auto;
      display: block;
      &.flagship {
        display: none;
      }
    }
  }
  .stores__location {
    padding: 10px 0;
    margin-bottom: 10px;
    &.flagship {
      .stores__location-name {
        color: $color-orange;
        @include breakpoint($landscape-up) {
          color: $color-black;
        }
      }
      .stores__location-name {
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
    }
    @include breakpoint($landscape-up) {
      margin-bottom: 0;
      cursor: pointer;
      border-bottom: 1px solid $color-white;
      transition: all 0.3s;
      display: none;

      &.active {
        border-bottom-color: $color-black;
        padding-top: 15px;
        margin-bottom: 35px;
        padding-bottom: 8px;
      }
      &.flagship {
        display: block;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .stores__location-image {
    display: none;
  }
  .stores__location-info {
    display: flex;
    justify-content: space-around;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  .stores__location-address,
  .stores__location-hours {
    flex: 0 0 50%;
  }
  .stores__location-address {
    @include heading--7;
    @include breakpoint($landscape-up) {
      font-size: 16px;
      line-height: 1.3;
    }
  }
  .stores__location-hours {
    padding-#{$ldirection}: 10px;
    font-size: 11px;
    @include breakpoint($landscape-up) {
      padding-#{$ldirection}: 0;
      margin-top: 13px;
      margin-bottom: 10px;
      font-size: 16px;
      p {
        line-height: 1.3;
      }
    }
  }
  .stores__location-directions {
    @include font-inter-medium;
    margin-top: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14px;
    @include breakpoint($landscape-up) {
      margin-top: 0;
      a {
        font-size: 11px;
        text-decoration: none;
        color: $color-gray;
      }
    }
  }
  .stores__content-name {
    @include heading--7;
    color: $color-orange;
    font-size: 16px;
  }
  .stores__content-content {
    line-height: 1.2;
    margin-top: 8px;
    margin-bottom: 30px;
  }
  .stores__content-images__featured {
    width: 100%;
  }
  .stores__content-images__alt {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;
    .stores__content-images__cell {
      width: 50%;
    }

    .stores__content-image--2 {
      padding-#{$ldirection}: 30px;
    }
  }
  .view-stockists {
    @include font-inter-medium;
    display: inline-block;
    color: $color-orange;
    text-decoration: underline;
    margin-bottom: 35px;
  }
  .page__title {
    margin-top: 65px;
    @include breakpoint($landscape-up) {
      margin-top: 40px;
    }
  }
}
