///
/// @file template_api/_gnav-util.scss
///
/// \brief Styles for gnav utility links and expandable content wrappers
///
///
///

.gnav-util-formatter__item {
  display: inline-block;
  float: #{$ldirection};
  padding: 0 30px;
  width: 30px;
}

.gnav-util--hamburger {
  @include breakpoint($landscape-up) {
    display: none;
  }
}

.gnav-util--account-icon {
  display: none;
  @include breakpoint($landscape-up) {
    display: inline-block;
  }
}

.gnav-util--search,
.gnav-util--account-icon {
  margin-#{$rdirection}: 0.5em;
  @include breakpoint($iphone5-only) {
    margin-#{$rdirection}: 0;
  }
}

.gnav-util__link {
  display: inline-block;
  padding: 0 1em;
}

.gnav-util__icon {
  color: inherit;
  line-height: $header-height;
  position: relative;
  margin: 1.25em 0.25em;
  text-decoration: none;
  .icon {
    @include breakpoint($landscape-up) {
      height: 12px;
    }
  }

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.gnav-util--country_selector {
  position: relative;
  top: 0;
  margin-top: 5px;
  @include breakpoint($medium-up) {
    margin-top: 15px;
  }
  @include breakpoint($landscape-up) {
    position: absolute;
    #{$ldirection}: auto;
    margin-top: 0.39em;
    body.content-block-offer-banner--enabled & {
      top: $offer-banner-height--mobile;
      @include breakpoint($landscape-up) {
        top: $offer-banner-height;
      }
    }
  }
}
.gnav-util__icon--country_selector {
  position: relative;
  margin-#{$ldirection}: 1em;
  line-height: 1.25em;
  .icon--box {
    fill: $color-white;
    height: 25px;
    #{$ldirection}: 0;
    position: relative;
    top: 4px;
    width: 29px;
    text {
      font-size: 12px;
      fill: $color-white;
      font-family: sans-serif;
    }
    @include breakpoint($landscape-up) {
      &:before {
        font-size: 25px;
      }
    }
  }
  @include breakpoint($iphone5-only) {
    margin-#{$ldirection}: 0.55em;
  }
  @include breakpoint($medium-up) {
    margin-#{$ldirection}: 0.25em;
  }
  @include breakpoint($landscape-up) {
    margin-#{$ldirection}: 0.25em;
  }
}

.gnav-util__icon__country_selector-name {
  font-family: sans-serif;
  position: absolute;
  text-align: center;
  font-size: 12px;
  bottom: 3px;
  #{$ldirection}: 0px;
  width: 100%;
  @include breakpoint($medium-up) {
    font-size: 16px;
  }
  @include breakpoint($tablet-only) {
    margin-#{$ldirection}: 0;
    bottom: 3px;
    margin-bottom: 0;
  }
  @include breakpoint($medium-landscape-only) {
    margin-#{$ldirection}: 0;
  }
  @include breakpoint($landscape-up) {
    font-size: 12px;
    bottom: 3px;
    width: 25px;
  }
}

.gnav-util__icon--cart {
  position: relative;
  .icon--bag {
    @include breakpoint($small-down) {
      position: relative;
    }
  }
}

.gnav-util__icon__cart-count {
  font-family: sans-serif;
  position: absolute;
  text-align: center;
  font-size: 14px;
  bottom: 0;
  #{$ldirection}: 0%;
  width: 100%;
  fill: $color-white;
  @include breakpoint($small-down) {
    top: 8px;
  }
  @include breakpoint($medium-up) {
    bottom: 3px;
    font-size: 16px;
    #{$rdirection}: 0;
    width: 25px;
  }
  @include breakpoint($tablet-only) {
    margin-#{$ldirection}: 10%;
    margin-bottom: 5%;
  }
  @include breakpoint($medium-landscape-only) {
    margin-#{$ldirection}: 0;
  }
  @include breakpoint($landscape-up) {
    bottom: 3px;
    font-size: 14px;
  }
}

_:-ms-fullscreen,
:root .gnav-util__icon__cart-count {
  bottom: 2px;
  @include breakpoint($medium-up) {
    bottom: 6px;
  }
  @include breakpoint($landscape-up) {
    bottom: 8px;
  }
}

.gnav-util__content {
  position: absolute;
  //top: auto;
  top: $header-height;
  font-size: 0.4em;
  width: 100%;
  border: 0;
  #{$rdirection}: 0;
  background: $color-white;
  z-index: 10;
  @if $text-direction == 'rtl' {
    #{$ldirection}: auto;
  }
  @include breakpoint($medium-up) {
    top: $medium-up-header-height;
  }
  .content-block-offer-banner--enabled & {
    top: $header-height + $offer-banner-height--mobile;
    @include breakpoint($medium-up) {
      top: $medium-up-header-height + $offer-banner-height--mobile;
    }
    @include breakpoint($landscape-up) {
      top: $landscape-up-header-height + $offer-banner-height;
    }
  }
}

.gnav-util__content-close {
  background: transparent;
  position: absolute;
  z-index: 2;
  #{$rdirection}: 0.95em;
  top: 0.6em;
  text-decoration: none;
  font-size: 1.953125em;
  width: 0.6em;
  height: 0.6em;
  line-height: 0.6em;
  @if $text-direction == 'rtl' {
    #{$ldirection}: auto;
  }
  &:hover {
    color: $color-black;
    text-decoration: none;
  }
  .icon {
    fill: $color-black;
    height: 15px;
    width: 15px;
    font-weight: bold;
  }
  .gnav-util--cart & {
    top: 20px;
    #{$rdirection}: 20px;
    @if $text-direction == 'rtl' {
      #{$ldirection}: auto;
    }
    @include breakpoint($medium-up) {
      top: 23px;
    }
  }
}

.gnav-util__content-inner {
  padding: 1em;
}

img.gnav-util__avatar {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.gnav-util__content--search {
  border-bottom: 1px solid $color-light-gray;
  .gnav-util__content-wrapper {
    position: relative;
    @include responsive-container;
  }
  .gnav-util__content-close {
    top: 1.5em;
  }
  .search-form--results {
    min-height: 0;
  }

  .esearch-nav__form-field {
    border: 0;
    padding: 0 0.5em;
    width: 100%;
  }

  input[type='submit'].esearch-nav__form-submit {
    display: none;
    border: 0;
    position: absolute;
    top: 0px;
    #{$rdirection}: 3em;
    text-indent: 100%;
    background: $color-white
      url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAMAAADX9CSSAAAAbFBMVEX///+BgYH5+fmkpKT7+/vy8vKXl5eGhoaqqqr39/e5ubnT09PAwMC+vr6Ojo60tLSvr6+np6egoKCKiorz8/Pv7+/p6enk5OTa2trLy8vHx8fGxsa2traZmZn09PTq6urf39/e3t6srKyLi4smuvLlAAAAuUlEQVQoz5WPVw7DMAxDzVjxyk7aZnfe/45Ns6wCQYHyi34UZUjMusSlUkV/Fl8KCwBaAxgChm8pVGSnuJ3MyU+nSGixlGOkjRvEvpsjWd0FijwPKm0XFyPymD0lLOc13GKUFlwnmEMe4rntCTm/b3t6tJw7NIs5Iw3YGl1trwH5jqlEt5+iYOw6XaIQtHdHvJL6Gj6chuTHBInGrKojEWXsOxs5Y1zzIRmkD3hZ/gjor4AkjDhsmOwNvJUGTdn9FXwAAAAASUVORK5CYII=')
      center no-repeat;
    background-size: 11.5px;
    overflow: hidden;
    padding: 0;
    width: 2em;
    height: 100%;
  }
}
@media screen and (min-width: 0\0) {
  _:-ms-fullscreen,
  :root .gnav-util__icon__cart-count,
  :root .gnav-util__icon__country_selector-name {
    @include breakpoint($landscape-up) {
      bottom: 9px;
    }
  }
}
