#loyalty__panel__offers {
  @include loyalty-panel;
  clear: both;

  .loyalty__panel__offers__title {
    @include loyalty-panel-title;
  }

  .loyalty__panel__offers__offer {
    position: relative;
    border: 5px solid #eee;
    padding: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      border: 2px dashed #ddd;
    }
  }

  .loyalty__panel__offers__offer-expires,
  .loyalty__panel__offers__offer-terms {
    font-size: 0.9em;
  }

  .loyalty__panel__offers__offer-expires {
    font-style: italic;
  }
}
