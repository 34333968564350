.locate-interstitial-overlay {
  #cboxLoadedContent {
    background: $color-white;
  }
}
.block-template-country-picker-v1 {
  display: block;
}
#colorbox.locate-interstitial-overlay {
  z-index: 10001;
}

#localeOverlay {
  display: none;
}

#localeInterstitial {
  background: $color-white;
  margin: auto;
  text-align: center;
  .welcome-label {
    h1 {
      display: inline-block;
      margin: 0 0 30px 0;
      padding: 0 5% 1%;
    }
  }
  li {
    display: block;
    float: none;
    text-align: center;
    padding: 7px 0;
    a {
      @include font-inter-regular;
      font-size: 12px;
      @include breakpoint($medium-up) {
        font-size: 16px;
      }
    }
  }
  ul {
    padding: 0;
    text-align: #{$ldirection};
    &.country-list,
    &.country-list-offsite,
    &.language-list {
      padding: 0;
      list-style-type: none;
    }
    &.country-list {
      @include breakpoint($medium-up) {
        text-align: #{$ldirection};
      }
    }
  }
  .local-one-pager {
    margin-top: 10px;
    .one-pager-text {
      border-top: 1px solid $color-gray;
      padding-top: 20px;
      margin: 0 auto;
      @include breakpoint($medium-up) {
        width: 80%;
      }
    }
    ul {
      width: 80%;
      margin: auto;
      &.country-list {
        @include breakpoint($medium-up) {
          text-align: center;
        }
      }
      &.rows_3 {
        li {
          @include breakpoint($medium-up) {
            width: 25%;
          }
        }
      }
      &.rows_2 {
        li {
          @include breakpoint($medium-up) {
            width: 40%;
          }
        }
      }
      &.rows_1 {
        li {
          @include breakpoint($medium-up) {
            width: 100%;
          }
        }
      }
    }
    li {
      width: 48%;
      display: inline-block;
      @include breakpoint($medium-up) {
        width: 20%;
      }
      a {
        font-size: 12px;
      }
    }
  }
  .country-link {
    border: none;
    text-decoration: none;
    &.selected {
      font-weight: bold;
    }
  }
  .inner-border {
    padding: 0;
  }
  #chooseLocaleButton {
    margin: 20px 0 0 0;
    width: 100%;
    @include breakpoint($medium-up) {
      width: auto;
    }
  }
  .choose-country {
    display: none;
  }
}
