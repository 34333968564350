$sl-hero-bg-color: #ebebeb;
$sl-highlight-color: pink;

.store-locator.store-locator--mobile {
  #map-canvas {
    height: 100%;
    margin: 0;
    padding: 0;
    min-height: 331px;

    .store-locator__tooltip {
      width: 180px;
      height: 75px;

      .store-locator__tooltip-name {
        text-transform: uppercase;
        color: #000;
      }
    }
  }

  .search-submit__text {
    display: none;
  }

  .search-submits {
    background: #ff4661;
    position: absolute;
    height: 32px;
    top: 0;
    #{$rdirection}: 0;
  }

  .store-locator__tooltip {
    .column--hours,
    .store-locator__event {
      display: none;
    }
  }

  .doors_results {
    .store-locator__tooltip {
      .store-locator__tooltip-name {
        font-size: 14px;
        letter-spacing: 3px;
        margin-bottom: 5px;
      }

      .store-locator__tooltip-phone {
        line-height: 2em;
      }
    }
  }

  .store-locator-section {
    .store-locator__event {
      display: none;
    }
  }

  .directions.button {
    color: #fff;
  }

  fieldset {
    display: inherit;
  }

  .hide-mobile {
    display: none !important;
  }

  .store-locator-section--list_item {
    .store-locator__button-container {
      display: none;
    }

    &.selected .store-locator__button-container {
      display: block;
    }
  }

  .store-locator__button.mobile-list {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAIAAADwLNHcAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUhJREFUeNpi+PHjZ2NDnZamekNd/e9fv/6TCFimTZmydOmynz9/zJ8/38DI0N/f/wYYMOAFjIyMQM1sbGwsW7dt+/njBwsz8/sPn06fOg3Uf+DAgRkzZjAQAQQEBBiWLV2mrqoqxM8vLyt38OBBUt0PcsbJkyePHz9uaWlpbm4OEmJkZCAaMP779w9ZA6n6mZA5QM0MJAImoG0vXjxfu3btyxcvITaTZsrZM2c93N0kJcRcHJ3v3rlDcvgFBQSeOHmcjZX1w4dPpeVlVVVVM8CAyPhjERQUBMbBn9+///79y8vDCxS1tbUVEREhJhSB6Yfh+bNnmRkZRoaG6enp7969I9X9DBDq1evXEMY/MCDB/8jxDw954pMAKP7evXt79uzZ9+/fM4IBaQkAGH+eHm4SQzb+Xjx/XpCfb21llZebBwwCUt0PEGAAa+YGHojS1ScAAAAASUVORK5CYII=')
      10px 7px no-repeat;
    color: #000;
  }

  .cart-caret {
    /*@include arrow(top, null, $color-white, $color-gray, 20px); - borders just don't look right...*/
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAMCAYAAABm+U3GAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIVJREFUeNqszTEOgCAMBVAsbgxegpED4gnweDDDNdhIqjWaGAcp4E+aNqR9TIgoOEkpWepa642zP4cQqktKKZdzttcHyzGvtRvgosYYQUUzvQ3BT1RKeRYXBy56h4tDC9qCQyvKxaEH5eDQi9ZwGEE/8Rij895jKQVHQwZZZIq/0De+CzAAGWgHbeaN2oIAAAAASUVORK5CYII=)
      no-repeat 0 0;
    position: absolute;
    top: -11px;
    #{$ldirection}: 45px;
    width: 22px;
    height: 12px;
  }
}

.store-locator {
  a {
    text-decoration: none;

    &.marker {
      color: #000;
      letter-spacing: 2px;
    }
  }

  h1.doorname {
    margin-bottom: 10px;
  }

  .hidden,
  .inactive,
  .hide {
    display: none !important;
  }

  fieldset {
    display: inline;
  }

  .store-locator__header {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    margin: 20px 0;
  }

  .store-locator__info {
    display: none;
    margin: 10px 0 10px 0;
    padding: 0 5px;
    font-size: 12px;
  }

  .store-locator__info-strong {
    font-weight: bold;
  }

  .store-locator__sections {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .store-locator__button {
    text-align: center;
    display: block;
    float: #{$ldirection};
    width: 49%;
    line-height: 32px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    height: 35px;
    color: #000;
    border: 1px solid #000;
    margin-#{$rdirection}: 1%;

    &:last-child {
      margin-#{$ldirection}: 1%;
      margin-#{$rdirection}: 0;
    }

    &.active {
      background: #000;
      color: #fff;
    }

    .icon {
      margin-top: 2px;
      font-size: 28px;
      margin-#{$ldirection}: 14px;
      float: #{$ldirection};
    }
  }

  .store-locator__duty-free-tab-link {
    color: #000;
  }

  .store-locator-section--list_item {
    padding: 15px;

    &:nth-child(even) {
      background: #ebebeb;
    }
  }

  .store-locator-section--list_item .store-locator__button {
    margin-top: 13px;

    &.active {
      border: none;
      background: transparent;
      color: #000;
    }
  }

  .store-locator-section--list_item-section {
    display: table;
    margin-top: 14px;
    font-size: 14px;
    width: 100%;
    padding: 15px 0;
    background: #fff;
    border: 1px solid #000;

    h4 {
      text-transform: uppercase;
    }
  }

  .store-locator-list__hours {
    border-#{$rdirection}: 1px solid #000;
  }

  .store-locator-list__hours,
  .store-locator-list__event {
    display: table-cell;
    padding: 0 15px;
    width: 50%;
  }

  .store-locator__tooltip {
    width: 280px;

    .store-locator__tooltip-name {
      font-size: 12px;
      text-transform: uppercase;

      &.popup {
        color: $sl-highlight-color;
        text-transform: capitalize;
      }
    }

    .store-locator__tooltip-phone {
      color: $sl-highlight-color;
    }
  }

  &__map-canvas-container,
  &__results-container {
    @include breakpoint($medium-up) {
      float: #{$ldirection};
      margin: 0 2%;
    }
  }
  &__map-canvas-container {
    @include breakpoint($medium-up) {
      width: 60%;
    }
  }
  &__results-container {
    @include breakpoint($medium-up) {
      width: 30%;
    }
  }

  &__map-canvas-container,
  &__map-canvas {
    min-height: 500px;
    @include breakpoint($small-down) {
      min-height: 300px;
    }
  }

  &__sections {
    @include breakpoint($medium-up) {
      display: none;
    }
  }

  #map-canvas {
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

@include breakpoint(max-width 768px) {
  .responsive-tabs--store-locator {
    ul.resp-tabs-list {
      display: block;
      margin-bottom: 15px;
    }

    .resp-tabs-list li {
      font-size: 13px;
      text-transform: none;
      text-align: center;
      display: inline-block;
      width: 50%;
      padding: 5px 0;
      margin: 0;
      list-style: none;
      cursor: pointer;
      float: #{$ldirection};
    }

    .resp-tabs-container {
      padding: 0px;
      background-color: #fff;
      clear: #{$ldirection};
    }

    h2.resp-accordion {
      cursor: pointer;
      padding: 5px;
      display: none;
    }

    .resp-content-active,
    .resp-accordion-active {
      display: block;
    }

    .resp-tab-content {
      border: none;
      padding: 0;
      background: $color-white;
    }

    .search-form {
      padding: 0;
      position: relative;

      input[type='search'] {
        width: 100%;
        padding-#{$rdirection}: 50px;
        border-color: $color-dark-gray;
        @include box-sizing(border-box);
      }

      .search-submit {
        position: absolute;
        #{$rdirection}: 0;
        top: 0;

        &.active {
          background: $sl-highlight-color;
        }
      }
    }
  }
}

.hannah {
  text-align: center;
  padding: 40px 0 60px;
  img {
    margin: 0;
  }
  .container {
    width: 440px;
    margin: 45px auto 0;
  }
  .link {
    border-bottom: 0;
    &.active {
      color: red;

      .icon {
        @include rotate(180deg);
      }
    }
  }
  .meet_bobbi {
    h2.section-header {
      margin-bottom: 23px;
    }
    .info {
      margin-bottom: 39px;
    }
  }
}

.div-read-more {
  display: none;
  div {
    padding: 20px 0;
  }
}

.wooster {
  max-width: 776px;
  text-align: center;
  margin: 0 auto;
}

.chips {
  background: image-url('temp/wr.jpg') no-repeat 0 0;
  height: 227px;
  max-width: 776px;
  text-align: center;
  color: #fff;
  margin: 0 auto 80px auto;
  position: relative;
  h3,
  h3 a {
    color: #fff;
    position: absolute;
    bottom: 35px;
    #{$ldirection}: 0;
    width: 100%;
    text-align: center;
    font-size: 16px;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
}

.store-locator--desktop {
  .store-locator__locate,
  .store-locator__dutyfree {
    color: $sl-highlight-color;

    .icon {
      font-size: 24px;
      line-height: 24px;
      vertical-align: middle;
    }

    span.text {
      font-size: 14px;
      margin-bottom: 8px;
      letter-spacing: 3px;
      border-bottom: 2px solid $sl-highlight-color;
    }
  }

  .store-locator__dutyfree {
    display: inline-block;
    float: #{$rdirection};
    span.text {
      border-bottom-color: $sl-highlight-color;
    }
  }

  .store-locator__form-container {
    display: inline-block;
    width: auto;
  }
  .store-locator__links-container {
    @include clearfix;
    margin: 20px auto 5px auto;
    width: 100%;
  }

  .store-locator__link-events {
    border-bottom: 2px solid $sl-highlight-color;
    display: inline-block;
    letter-spacing: 3px;
    float: #{$ldirection};
  }

  ul.resp-tabs-list {
    display: block;

    .resp-tab-item {
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 0;
      width: 50%;
      text-align: center;
    }
  }

  .store-locator__hero {
    padding: 45px;
    background: $sl-hero-bg-color;
    text-align: center;
    margin: 0 0 60px 0;

    .locator_error_messages {
      li {
        color: $color-red;
      }
    }

    select.country {
      display: inline;
    }

    img {
      margin: 0;
      max-width: none;
      height: auto;
      vertical-align: bottom;
    }

    a.selectBox {
      @include swap-direction(margin, 0 10px 0 5px);
      text-align: #{$ldirection};
      display: inline-block !important;
      width: 220px;
      float: #{$ldirection};
      height: 32px;
      line-height: 32px;

      .selectBox-arrow {
        line-height: 2em;
      }
    }

    input.search[type='text'] {
      width: 205px;
    }
    /* placeholder text needs to be black... */
    ::-webkit-input-placeholder {
      /* WebKit browsers */
      color: $color-black;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: $color-black;
      opacity: 1;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: $color-black;
      opacity: 1;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: $color-black;
    }

    #store-search-controls .required_field {
      border: 1px solid $color-red;
    }
  }

  .search-submit {
    @include swap-direction(margin, -3px 0 0 5px);
    background: $sl-highlight-color;
    height: 32px;
    line-height: 32px;

    .icon-search {
      display: none;
    }

    .search-submit__text {
      display: inline-block;
    }
  }

  .store-locator__search {
    display: inline-block;

    form {
      padding: 0;
      width: auto;
    }
  }

  .search-form {
    background: transparent;
  }

  &__map-canvas-container,
  &__results-container {
    @include breakpoint($medium-up) {
      float: #{$ldirection};
      margin: 0 2%;
    }
  }
  &__map-canvas-container {
    width: 60%;
  }
  &__results-container {
    width: 30%;
  }

  #map-canvas {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .store-locator-section--list_item {
    padding: 0;

    .store-locator__button-container,
    .store-locator-section,
    .column--hours,
    .store-locator-section--list_item-location {
      display: none;
    }

    .store-locator__tooltip {
      padding: 15px;
    }
  }

  .store-locator__tooltip {
    .column {
      width: 50%;
      float: #{$ldirection};
    }

    &.info_window,
    &.info_window:last-child {
      width: 100%;
      margin: 0;
    }
  }

  .store-locator__stores {
    height: 412px;
    overflow-y: scroll;
  }

  .store-locator__event {
    display: none;
    background: $color-black;
    color: $color-white;
    padding: 10px 0 0;

    .icon-close {
      display: none;
      fill: $color-white;
    }
  }

  .store-locator__event-list {
    display: none;
    color: $color-black;
    background: $color-white;
    border: 1px solid $color-black;

    h5 {
      color: $color-black;
    }
  }

  .responsive-tabs--store-locator {
    .resp-tab-content {
      padding: 0;
    }
  }

  .responsive-tabs--store-locator {
    .resp-tab-item {
      text-transform: none;
      margin-bottom: 0;
    }
  }

  .store-locator-section--list_item {
    &:nth-child(odd) {
      background: transparent;
    }

    &.active {
      background: $color-light-brown;
    }

    &:hover {
      cursor: pointer;
      background: $color-light-brown;
    }
  }

  .store-locator-section--list .store-locator__tooltip .store-locator__event {
    display: none;
  }

  .store-locator__directions {
    font-size: 12px;
    background-color: $color-white;
  }

  .store-locator__directions-location {
    padding: 15px;
    line-height: 20px;
    background: $color-white;
    border-color: $color-black;
    border-width: 1px 0;

    .store-locator__directions-destination {
      line-height: 40px;
      vertical-align: middle;
      float: #{$ldirection};
      height: 40px;
      width: 40px;
      text-align: center;
      background: $sl-highlight-color;
      color: $color-white;
      margin-#{$rdirection}: 10px;
    }

    .store-locator__directions-eta {
      color: $sl-highlight-color;
    }

    .directions-form__errors {
      margin-bottom: 1em;
      color: $sl-highlight-color;
    }

    .directions-form__input-wrapper--a {
      margin-bottom: 11px;
    }

    .directions-form__input-wrapper--b {
      margin-bottom: 22px;
    }

    .directions-form__submit {
      margin: 0 0 22px 55px;
    }
    .directions-form__input-wrapper {
      .store-locator__directions-destination {
        height: 32px;
        width: 32px;
        line-height: 32px;
        background: $color-black;
      }
      .directions-form__input {
        width: 215px;
      }
    }

    .directions-form__transportation-modes {
      width: 100%;
      text-align: justify;

      .directions-form__transportation-mode-link {
        vertical-align: top;
        display: inline-block;
        zoom: 1;
        color: $color-black;

        &.active {
          color: $sl-highlight-color;
        }
      }

      .stretch {
        width: 100%;
        display: inline-block;
        font-size: 0;
        line-height: 0;
      }
    }

    .directions-form__results {
      background-color: $color-white;
    }

    .store-locator__directions-start,
    .store-locator__directions-end {
      background-color: $color-light-brown;
      border-color: $color-black;
      border-style: solid;
      border-width: 1px 0;
    }

    table.adp-placemark {
      display: none;
    }
  }

  .store-locator-section--list_item .store-locator__tooltip {
    font-size: 14px;
  }

  .store-locator-section--list_item {
    .column {
      width: 100%;
      margin: 0;
    }
  }

  .store-locator-section--list {
    .store-locator__event {
      font-size: 14px;

      .store-locator__event-label {
        padding: 10px;
      }

      .store-locator__event-list__item {
        padding: 10px;

        h5 {
          text-transform: uppercase;
          font-size: 14px;
          margin-bottom: 8px;
        }
      }

      .icon-close {
        float: #{$rdirection};
      }
    }
  }

  @include breakpoint(max-width 1024px) {
    .store-locator__locate {
      display: inline-block;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .adp-directions tr {
    .adp-substep:nth-child(1),
    .adp-substep:nth-child(2) {
      width: 10%;
    }

    .adp-substep:nth-child(3) {
      width: 65%;
    }
  }
}

.store-locator__default {
  background: $color-white;
  margin: 0;
}

.prue {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 10px 0;
  p {
    font-weight: bold;
    margin: 0;
    font-size: 13px;
  }
}

/* Close Button Fix */
#map-canvas img {
  max-width: none;
}

// styles for Google Places Autocomplete API
.pac {
  &-container {
    // code for removing 'powered by Google' from autocomplete container/tray
    // please note: not needed to show 'powered by Google' if also a Google Map is shown
    &:after {
      background-image: none !important;
      height: 0;
      padding: 0;
      margin: 0;
    }
  }
  &-icon {
    display: none;
  }
}
