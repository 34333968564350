/* personalization: spp button */
.product-full {
  .product-full__details {
    .product-full__personalization {
      margin: 0;
      padding-bottom: 10px;
      width: 100%;
      clear: both;
      @include breakpoint($medium-up) {
        padding-bottom: 20px;
      }
    }
  }
}

/* personalization: overlay content */
.personalization-overlay {
  margin: 10px 10px 15px;
  text-align: center;

  @include breakpoint($medium-up) {
    margin: 20px 20px 30px;
  }

  .personalization-overlay__header {
    @include heading--3;
    margin-bottom: 8px;
    clear: both;
    @include breakpoint($medium-up) {
      font-size: 28px;
    }
  }

  .personalization-form__intro {
    @include copy--intro;
    line-height: 22px;
    margin: 0 auto 20px;
    @include breakpoint($medium-up) {
      margin-bottom: 44px;
      width: 600px;
    }
  }

  .personalization-form__image {
    display: inline-block;
    width: 100%;
    border: 1px solid $color-dark-gray;

    @include breakpoint($medium-up) {
      width: 300px;
      margin-#{$rdirection}: 40px;
    }
    @include breakpoint($large-up) {
      width: 398px;
    }
  }

  .personalization-form__wrapper,
  .personalization-form__success {
    text-align: #{$ldirection};
    display: inline-block;
    vertical-align: top;
    margin-top: 18px;
    width: 100%;

    @include breakpoint($medium-up) {
      width: 270px;
      margin-top: 20px;
    }
    @include breakpoint($large-up) {
      margin-top: 70px;
    }
  }

  .personalization-form {
    .form-item {
      display: block;
      margin-bottom: 18px;

      input.personalization-form__characters,
      .selectBox,
      input.button,
      div.button {
        display: block;
        width: 100%;
        max-width: 400px;
      }

      input.personalization-form__characters {
        height: 41px;
        padding-top: 4px;
        text-transform: uppercase;

        @include breakpoint($medium-up) {
          height: 30px;
        }
      }

      label {
        font-size: 13px;
        &:before {
          margin-#{$ldirection}: 10px;
          color: $color-black;
        }
        @include breakpoint($medium-down) {
          padding-top: 4px;
          height: 41px;
          line-height: 41px;
          margin-top: calc(-3.35em - 1px);
        }
        @include breakpoint($medium-up) {
          font-size: 14px;
          line-height: 2.9em;
        }
      }

      .selectBox {
        text-transform: none;
        @include breakpoint($medium-up) {
          font-size: 14px;
        }

        .selectBox-label,
        .selectBox-options li a {
          padding: 0 10px;
        }
      }
      select.selectBox {
        font-size: 13px;
        padding: 0 10px;
      }

      input.button {
        @include breakpoint($medium-up) {
          font-size: 14px;
          padding: 8px 30px 4px 30px;
        }
      }
    }

    .personalization-form__errors {
      padding-bottom: 1em;
      li.personalization-form__message {
        display: none;
      }
    }
  }

  .personalization-form__ordering-info {
    @include breakpoint($medium-up) {
      font-size: 14px;
      line-height: 18px;
    }
  }
} //.personalization-overlay

#cboxLoadedContent {
  .personalization-form__submit {
    color: $color-white;
  }
}
