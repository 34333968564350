#loyalty__panel__leave {
  @include loyalty-panel;

  display: none;
  clear: both;

  &.is-member {
    display: block;
  }

  .loyalty__panel__leave__title {
    @include loyalty-panel-title;
  }

  .loyalty__panel__leave__are-you-sure,
  .loyalty__panel__leave__confirmation {
    font-weight: bold;
  }
  .loyalty__panel__leave__confirmation {
    display: none;
  }

  .loyalty__panel__leave__contact {
    margin: 1em 0;
  }

  .loyalty__panel__leave__form-leave {
    margin: 1em 0;
  }

  .loyalty__panel_leave__form-leave__reason {
    margin-top: 1em;

    label {
      display: block;
    }
  }

  &.loyalty__panel__leave--confirmation {
    .loyalty__panel__leave__are-you-sure,
    .loyalty__panel__leave__warning,
    .loyalty__panel__leave__contact,
    #loyalty_quit {
      display: none;
    }

    .loyalty__panel__leave__confirmation {
      display: block;
    }

    .loyalty__panel__leave__member {
      font-weight: bold;
    }
  }

  @include breakpoint($medium-up) {
  }
}
