#loyalty__panel__how-to-earn {
  @include loyalty-panel;

  clear: both;

  &.is-member {
    display: none;

    #loyalty__page__about & {
      display: block;
    }
  }

  .loyalty__panel__how-to-earn__title {
    @include loyalty-panel-title;
  }
}
