///
/// @file template_api/endeca/_search_results.scss
///
/// \brief Search Results
///

.search-wrapper {
  @include responsive-container;
  @include breakpoint($medium-up) {
    padding: 10px;
  }
  .search-wrapper__loading {
    text-align: center;
    padding-bottom: 20px;
  }
  .search-wrapper__header {
    @include heading--1;
    @include heading--1__underline;
    text-align: center;
    margin-bottom: 15px;
    @include breakpoint($medium-up) {
      margin-bottom: 30px;
    }
  }
  .search-wrapper__results {
    @include clearfix;
    margin: 0 auto;
    max-width: $max-width-large;
    .product-grid {
      padding: 0 10px;
      @include breakpoint($medium-up) {
        padding: 0;
      }
      .product-grid__item {
        float: #{$ldirection};
        @include breakpoint($small-up) {
          min-height: 260px;
        }
        @include breakpoint($medium-up) {
          min-height: 370px;
        }
        @include breakpoint($large-up) {
          min-height: 400px;
        }
      }
      &.quickshop-style-overlay {
        .product-quickshop-wrapper {
          display: none;
        }
      }
    }
  }
  .summary {
    padding: 20px 0;
    text-align: center;
    text-transform: uppercase;
    span {
      font-weight: bold;
      .not-bold {
        font-weight: normal;
      }
    }
  }
  .results {
    .results__products {
      max-width: 100%;
      .products {
        .products__header {
          // temp hide sort
          display: none;
        }
      }
    }
    .results__content {
      .results-summary {
        // temp hide sort
        display: none;
      }
    }
  }
  .search-wrapper__bestsellers {
    // This is the main wrapper for the "no results" content
    .bestseller-results {
      padding-top: 40px;
    }
  }
}

// Search results page:
.search-results {
  padding: 0;
  @include breakpoint($medium-up) {
    padding: 0 20px;
  }
}
@include breakpoint($medium-up) {
  body.toolbar.device-pc {
    .page-wrapper {
      .search-results__header {
        padding-top: 2em;
      }
    }
  }
}
.search-results__header {
  padding: 15px;
  @include responsive-container;
  @include breakpoint($medium-up) {
    padding: 30px 10px 40px 10px;
    display: block;
  }
  &--results {
    min-height: auto;
  }
  .search-form--results {
    min-height: auto;
  }
}

.summary-results__result,
.summary-results__did-you-mean,
.summary-results__auto-correct {
  @include font-inter-light;
  font-size: 16px;
  strong {
    @include font-inter-light;
    font-weight: 500;
  }
}

.search_results__summary {
  padding: 0 10px;
  text-align: center;
}

// smashbox_base/template_api/endeca/esearch_results_pc_v1.mustache
.results__header--column {
  @include font-inter-light;
  padding-#{$ldirection}: 21px;
  border-top: $color-light-gray solid 1px;
  border-bottom: $color-light-gray solid 1px;
  margin: 0 10px;
  a {
    text-decoration: none;
  }
  dd {
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      margin-bottom: 20px;
    }
  }
  @include breakpoint($medium-up) {
    @include swap-direction(padding, 40px 10px 40px 0);
    border: none;
    width: 25%;
    float: #{$ldirection};
    height: 100%;
    display: block;
    margin: 0;
  }
}

.results__products--column {
  margin: 0;
  border-bottom: $color-black solid 1px;
  @include breakpoint($medium-up) {
    border: none;
    float: #{$ldirection};
    width: 75%;
    padding-#{$rdirection}: 1em;
    padding-bottom: 60px;
    .product-grid {
      top: 0px;
      margin-top: 32px;
    }
  }
  .results__header--dimensions {
    text-align: center;
    margin-bottom: 2em;
  }
}
.esearch-product {
  width: 100%;
}
.results__header-filter-content--mobile-collapsed {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
}

.breadcrumbs__link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.breadcrumbs__link-close {
  font-size: 12px;
  height: 12px;
  line-height: 9px;
  width: 12px;
}
// plain styles for responsive result on search page
.endeca-product-result {
  padding: 1em 0;
}

// dimensions / filters
.results-filter__header {
  &:after {
    @include swap-direction(padding, 0 0 6px 10px);
    font-size: 12px;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
}
.results__header {
  text-transform: uppercase;

  .results-header {
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: 5px;

    &.dimensions__header {
      margin: 10px 0;
      font-size: 18px;
      @include breakpoint($medium-up) {
        margin: 0;
        font-size: 24px;
      }
    }
  }
}

.esearch-nav {
  text-align: $ldirection;
  @include breakpoint($medium-up) {
    text-align: center;
  }
  &__popular {
    padding-top: 13px;
    .elc__popular-header {
      font-family: $font-inter-regular, $font--sans;
      font-size: 18px;
      color: $color-black;
      padding-top: 17px;
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        display: inline-block;
      }
    }
    &__item {
      @include breakpoint($medium-up) {
        display: inline-block;
      }
      a {
        text-decoration: none;
        color: $color-white;
        margin-top: 10px;
        padding: 5px 15px;
        text-transform: none;
        width: auto;
        background: $color-orange;
        border: 1px solid $color-orange;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        border-radius: 25px;
        display: inline-block;
        @include breakpoint($medium-up) {
          text-align: center;
          margin-#{$ldirection}: 10px;
        }
      }
    }
  }
  &__popular-list {
    display: inline-block;
  }
}
